import { useState, useEffect } from 'react';
import 'react-native-gesture-handler';
import React, { useContext } from 'react';

import ModeContext from './src/components/ModeContext.js';
import ColorProvider from './src/components/ColorContext.js';
import { CalendarProvider } from './src/components/calendar/CalendarContext.js';
import AuthUserProvider from './src/nav/AuthUserProvider.js';
import Routes from './src/nav/Routes.js';
import NavBarProvider from './src/components/NavBarContext.js';
import {CourseProvider} from './src/components/CourseContext.js';
import { SnackbarProvider } from './src/components/SnackbarContext.js'
import { CounterProvider } from './src/components/CounterContext.js'
import SnackbarAlert from './src/components/SnackbarAlert.js';

export default function App() {
  global.aprovFiles;
  global.reviewRec = 0;
  global.reviewRecRst = 0;
  global.reviewRst = 0;
  global.recRst = 0;
  global.review1380 = 0;

  const [mode, setMode] = useState('light'); // Initialize global theme state

  const toggleMode = () => {
    setMode(mode === 'light' ? 'dark' : 'light'); // function to toggle the theme
  };

  return (
    <ModeContext.Provider value={{ mode, toggleMode }}>
        <NavBarProvider>
            <ColorProvider>
                <SnackbarProvider>
                  <CounterProvider>
                    <CalendarProvider>
                        <AuthUserProvider>
                          <CourseProvider>
                            <Routes />
                          </CourseProvider>
                        </AuthUserProvider>
                    </CalendarProvider>
                  </CounterProvider>
                </SnackbarProvider>
            </ColorProvider>
        </NavBarProvider>
    </ModeContext.Provider>
  );
};