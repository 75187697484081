import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "../../components/SnackbarContext";
import * as Progress from "react-native-progress";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { CheckBox } from "react-native-web";
import { TextInput } from "react-native-paper";

import { en, registerTranslation } from "react-native-paper-dates";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { useScale } from "../../components/scale";
import { useColorContext } from "../../components/ColorContext";
import { pdfIn } from "../generatePDF.js";
import { PDFDocument } from "pdf-lib";
import Scrollbars from "react-custom-scrollbars";
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import { create_new_user_signature } from "../../api_handler/users.js";
import { get_review_files, put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from "../../api_handler/asyncstorage.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { create_notification } from "../../api_handler/notifications";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import {
  get_single_file,
  review_file,
  get_image_s3,
} from "../../api_handler/files";
import * as form_lik from "../../PDFs/LIK_form_raw";
import { get_single_user } from "../../api_handler/users.js";
import { decodeBase64 } from "../generatePDF.js";
registerTranslation("en", en);

function ReviewLIK({ route, navigation }) {
  const { colors } = useColorContext();
  const scale = useScale();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const viewRef = useRef();

  const styles = {
    container: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      backgroundColor: colors.screen_background,
    },
    scrollView: {
      height: "100%",
      width: "100%",
      marginTop: "2%",
    },
    mainWrapper: {
      flexDirection: "column",
      alignSelf: "center",
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: "Trebuchet MS",
      flex: 1,
      color: colors.text,
      paddingLeft: "5%",
      paddingTop: 25,
    },
    commentInput: {
      minHeight: "fit-content",
      borderWidth: 1,
      borderRadius: 5,
      borderColor: colors.border_color,
      backgroundColor: colors.textfield_background,
      width: 500,
    },
  };

  const loadingStyles = {
    animation: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  const requestTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [hasSignature, setHasSignature] = useState(false);
  const [comment, setComment] = useState("");
  const [approve, setApprove] = useState(false);
  const [soilderName, setSoldierName] = useState("");
  const [dateSubmitted, setDateSubmitted] = useState("");
  const [dateLodging, setDateLodging] = useState("");
  const [residentAddress, setResidentAddress] = useState("");
  const [unitName, setUnitName] = useState("");
  const [LIKData, setLIKData] = useState({});
  const [creator, setCreator] = useState({});

  useEffect(() => {
    // Get LIK form
    getLIKForm(route.params.fileID);
    getCreator(route.params.fileID);

    // Check if user has a signature
    setHasSignature(global.signature_uri != null && global.signature_uri != "");
  }, []);

  if (isLoading) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={requestTheme}>
      <View style={[styles.container, { cursor: isLoading ? "wait" : "auto" }]}>
        <Scrollbars style={styles.scrollView}>
          <View style={styles.mainWrapper}>
            <Text style={styles.pageTitle}>Review LIK</Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap", // Allows the content to wrap when there is overflow
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "2%",
                paddingBottom: "7%",
                gap: "5%",
                marginRight: "2%",
              }}
            >
              {/* Table */}
              <View
                style={{
                  flexDirection: "column",
                  maxWidth: 800,
                  minWidth: 800,
                  height: "fit-content",
                  borderColor: colors.border_color,
                  borderWidth: 1,
                  borderRadius: 5,
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 4,
                  backgroundColor: colors.modal_background,
                  overflow: "hidden",
                  marginBottom: "3%",
                }}
              >
                <Table>
                  <TableBody>
                    <FormRow label={"Soilder"} value={soilderName} />
                    <FormRow label={"Unit"} value={unitName} />
                    <FormRow label={"Date Submitted"} value={dateSubmitted} />
                    <FormRow
                      label={"Date Requested for Lodging"}
                      value={dateLodging}
                    />
                    <FormRow
                      label={"Resident Address"}
                      value={residentAddress}
                    />
                  </TableBody>
                </Table>
              </View>

              <View
                style={{
                  minWidth: 300,
                  flexDirection: "column",
                }}
              >
                {/* Approval */}
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: "2%",
                    color: colors.bow,
                  }}
                >
                  Reviewer Actions
                </Text>
                <View
                  style={{ marginBottom: "2%", gap: 30, flexDirection: "row" }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CheckBox
                      onValueChange={() => setApprove(!approve)}
                      style={{ margin: 10, marginRight: 15 }}
                      value={approve}
                    />
                    <Text style={{ color: colors.bow }}>Approve</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CheckBox
                      onValueChange={() => setApprove(!approve)}
                      style={{ margin: 10, marginRight: 15 }}
                      value={!approve}
                    />
                    <Text style={{ color: colors.bow }}>Disapprove</Text>
                  </View>
                </View>
                {/* Comments */}
                <TextInput
                  style={{
                    ...styles.commentInput,
                    marginBottom: "4%",
                    marginTop: "2%",
                  }}
                  theme={{
                    colors: {
                      text: colors.inputted_text,
                      accent: colors.textfield_background,
                      primary: colors.inputted_text,
                      placeholder: colors.placeholder_text,
                    },
                  }}
                  selectionColor="black"
                  label="Comments"
                  activeOutlineColor="black"
                  value={comment}
                  onChangeText={(data) => setComment(data)}
                />
                {/* Signature */}
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: "2%",
                    color: colors.bow,
                  }}
                >
                  Signature
                </Text>
                <View style={{ gap: 30 }}>
                  {hasSignature ? (
                    hasSigned ? (
                      <Image
                        resizeMode={"contain"}
                        style={{
                          width: 200,
                          height: 100,
                        }}
                        source={{ uri: global.signature_uri }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    // Add Signature
                    <View
                      style={{
                        flexDirection: "column",
                        gap: 30,
                      }}
                    >
                      {signatureName == "" ? (
                        <Text
                          style={{
                            opacity: 0.7,
                            width: 500,
                            fontStyle: "italic",
                            color: "red",
                          }}
                        >
                          No signature found. Please add one below.
                        </Text>
                      ) : (
                        <ViewShot
                          ref={viewRef}
                          style={{
                            padding: 10,
                            marginVertical: 10,
                            backgroundColor: "white",
                          }}
                          options={{ format: "png", quality: 1 }}
                        >
                          <Text style={{ color: "black", fontSize: scale(30) }}>
                            {signatureName}
                          </Text>
                        </ViewShot>
                      )}

                      <TextInput
                        style={styles.commentInput}
                        theme={{
                          colors: {
                            text: colors.inputted_text,
                            accent: colors.textfield_background,
                            primary: colors.inputted_text,
                            placeholder: colors.placeholder_text,
                          },
                        }}
                        selectionColor={"black"}
                        placeholder="e.g. John Doe"
                        label={`First and Last Name`}
                        activeOutlineColor={"black"}
                        value={signatureName}
                        onChangeText={(data) => setSignatureName(data)}
                      />

                      <View style={{ width: 128 }}>
                        <Button label="Add Signature" onClick={addSignature} />
                      </View>
                    </View>
                  )}

                  {/* Buttons */}
                  <View style={{ width: 128 }}>
                    <Button
                      label="Sign Document"
                      disabled={!hasSignature}
                      onClick={sign}
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                  </View>
                  <Text
                    style={{
                      opacity: 0.7,
                      width: 500,
                      fontStyle: "italic",
                      color: colors.bow,
                    }}
                  >
                    By clicking on the "Submit" button below, you consent to
                    your digital signature being affixed to the document. Your
                    signature can be viewed below after clicking the "Sign
                    Document" button. If you don't have a signature, please add
                    one below.
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      gap: "2%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        createLIKForm(LIKData);
                      }}
                      label="View Filled"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                    <Button
                      label="Submit"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                      onClick={submit}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );

  function FormRow({ label, value, value_color = colors.bow }) {
    return (
      <TableRow
        sx={{
          boxShadow: "none",
          backgroundColor: colors.textfield_background,
          paddingTop: "5px",
          paddingTop: "5px",
          "&:hover": {
            backgroundColor: colors.textfield_background_hover,
          },
        }}
      >
        <TableCell>
          <Text style={{ color: colors.bow }}>{label}</Text>
        </TableCell>
        <TableCell>
          <Text style={{ color: value_color }}>{value}</Text>
        </TableCell>
      </TableRow>
    );
  }

  function Button({
    label,
    bgColor = "#fdebc5",
    txtColor = "#000000",
    disabled = false,
    onClick = () => {},
  }) {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: bgColor,
          borderRadius: 5,
          borderColor: colors.border_color,
          borderWidth: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: 10,
          paddingVertical: 5,
          opacity: disabled ? 0.35 : 1,
        }}
        onPress={disabled ? () => {} : onClick}
      >
        <Text
          style={{
            color: txtColor,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  }

  async function captureTextAsImage() {
    try {
      const fullname =
        global.user_data.first_name.toLowerCase() +
        " " +
        global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: "png",
          quality: 0.8,
        });

        let ret = await create_new_user_signature(
          { user_id: global.user_data.user_id },
          global.token
        );
        if (ret.status === 200) {
          ret = ret.data;

          await put_image_s3(
            "signatures/" + ret.body.signature_id,
            uri,
            "image/png",
            global.token
          );

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem("signature", global.signature_uri);
          storeJsonData("user_data", global.user_data);

          setHasSignature(true);
        } else {
          showSnackbar(
            "error",
            "Application Error: Failed to create your signature"
          );
        }
      } else {
        showSnackbar(
          "error",
          "Input Error: The name inputted does not match your name we have on record!"
        );
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  }

  function addSignature() {
    // Add signature to s3
    captureTextAsImage();
  }

  function sign() {
    setHasSigned(true);
  }

  async function getLIKForm(formId) {
    // Get the form form from the database via form id
    setIsLoading(true);
    const res = await get_single_file(
      { form_id: formId, form_type: "LIK" },
      global.token
    );
    setIsLoading(false);

    if (res.body) {
      setLIKData(res.body);
      setResidentAddress(
        `${res.body.address}, ${res.body.city}, ${res.body.state}, ${res.body.zip}`
      );
      setUnitName(res.body.unit_name);
      setDateSubmitted(res.body.date);
      setSoldierName(res.body.name_and_rank);
      setDateLodging(res.body.lik_ba_date);
      return;
    } else {
      showSnackbar("error", "Failed to retrieve LIK");
    }
  }

  async function createLIKForm(data) {
    const pdf = await PDFDocument.load(form_lik.pdf());
    const page = pdf.getPages()[0];
    const page2 = pdf.getPages()[1];
    const form = pdf.getForm();
    const fields = form.getFields();

    const initials = creator.first_name[0] + creator.last_name[0];

    const formData = {
      "1 DATE RRQUESTED FOR LODGING": data.lik_ba_date,
      "2 UNIT": global.user_data.unit_name,
      "3 NAME": creator.first_name + " " + creator.last_name,
      "4 RANK": creator.rank,
      "5 ADDRESS": data.address,
      "6 CITYSTATEZIP CODE":
        data.city + ", " + data.state + ", " + data.zipcode,
      "7 HOMEPHONE": creator.phone_number,
      "8 WORKPHONE": creator.phone_number,
      "Signature of Soldier": "",
      Date: data.date,
      "Commanders Signature": "",
      Date_2: hasSigned ? getTodaysDate() : "",
      "normal commuting distance of 50 miles to the unit": initials,
      "miles from my unit This is outside the LIK programs": data.distance,
      "any type of active duty orders": initials,
      "lodging if I fail to honor my reservation without proper notification to my unit It is my responsibility to ensure":
        initials,
      "reimbursable under this program": initials,
      "I understand that the exercise of double occupancy is enforceable for each room":
        initials,
      "consequential cost that I may accrue": initials,
      "and increase the cost of the room I will assume the entire room charge without any financial reimbursement":
        initials,
      "quarters This includes but is not limited to local and long distance telephone calls refreshments movies and":
        initials,
      "Commanders Signature I Date": "",
      "Printed Name I Grade of Soldier":
        creator.first_name + " " + creator.last_name,
      "Printed Name I Grade of Commander":
        global.user_data.first_name + " " + global.user_data.last_name,
      Name: creator.first_name + " " + creator.last_name,
    };

    fields.forEach((field) => {
      const name = field.getName();
      if (formData[name]) field.setText(formData[name].toString());
    });

    // Applicant's signature and date
    const img = await pdf.embedPng(global.signature_uri);
    const soldier_signature_uri = decodeBase64(
      await get_image_s3(
        "signatures/" + data.soldier_signature_id,
        "image/png",
        global.token
      )
    )
      .split(",")
      .pop();
    const img2 = await pdf.embedPng(soldier_signature_uri);

    page.drawImage(img2, {
      x: 200,
      y: 300,
      width: 100,
      height: 25,
    });

    page2.drawImage(img2, {
      x: 50,
      y: 160,
      width: 100,
      height: 25,
    });

    if (hasSigned) {
      page.drawImage(img, {
        x: 200,
        y: 245,
        width: 100,
        height: 25,
      });

      page2.drawImage(img, {
        x: 300,
        y: 160,
        width: 100,
        height: 25,
      });
    }

    form.flatten();
    const bytes = await pdf.saveAsBase64();

    pdfIn(bytes);
  }

  function submit() {
    if (!hasSigned && approve) {
      showSnackbar("error", "Please sign in order to approve");
      return;
    }

    setIsLoading(true);
    review_file(
      {
        user_id: global.user_data.user_id,
        form_id: route.params.fileID,
        form_type: "LIK",
        reviewer_comment: comment,
        status: approve ? 4 : 5,
        request_is_approved: approve,
        reviewer_signature_id: global.user_data.signature_id,
        permissions: global.user_data.permissions,
      },
      global.token
    ).then((res) => {
      setIsLoading(false);
      showSnackbar("success", "LIK review submitted successfully");
      sendNotifToSoldier(creator.user_id, approve ? "approved" : "disapproved");
      resetState();
      navigation.navigate("Home");
    });
  }

  async function getCreator(formId) {
    get_review_files(
      {
        role: global.user_data.role,
        user_id: global.user_data.user_id,
      },
      global.token
    ).then((res) => {
      if (res.data.body) {
        const creator = res.data.body.filter((f) => f.form_id == formId)[0];

        get_single_user({ user_id: creator.creator_id }, global.token).then(
          (res) => {
            setCreator(res.data.body);
          }
        );
      }
    });
  }

  async function sendNotifToSoldier(soldier_id, status) {
    const data = {
      title: route.params.form_type + " " + status,
      body: comment,
      receiver_id: soldier_id,
      sender_id: global.user_data.user_id,
      notification_type: "file",
      object_type: route.params.form_type + status,
      form_events_id: route.params.fileID,
    };
    let res = await create_notification(data, global.token);

    if (res.status !== 200) {
      showSnackbar(
        "success",
        "Your Soldier has been notified",
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar(
            "error",
            "Application Error: Failed to send notification"
          );
        }
      );
    }
  }

  function resetState() {
    setIsLoading(false);
    setHasSigned(false);
    setSignatureName(null);
    setHasSignature(false);
    setComment(null);
    setApprove(false);
    setCreator(null);
    setSoldierName("");
    setDateSubmitted("");
    setUnitName("");
    setLIKData({});
    setDateLodging("");
    setResidentAddress("");
  }
}

ReviewLIK.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default ReviewLIK;

function getTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}
