export const budgetSummary = (
  data,
  setNumSubs,
  setaftpData,
  setmdrpData,
  setrmaData,
  setebdlData,
  setaftpLineData,
  setmdrpLineData,
  setrmaLineData,
  setebdlLineData,
  setFilteredData,
  selectedFy,
) => {
  // Initialize lists of dictionaries with month, "95% Dollar Usage Target," and "Dollars Spent"
  const aftpLineData = [
    { month: "Oct", "95% Dollar Usage Target": 5500, "Dollars Spent": 0 },
    { month: "Nov", "95% Dollar Usage Target": 6250, "Dollars Spent": 0 },
    { month: "Dec", "95% Dollar Usage Target": 7000, "Dollars Spent": 0 },
    { month: "Jan", "95% Dollar Usage Target": 7750, "Dollars Spent": 0 },
    { month: "Feb", "95% Dollar Usage Target": 8500, "Dollars Spent": 0 },
    { month: "Mar", "95% Dollar Usage Target": 9250, "Dollars Spent": 0 },
    { month: "Apr", "95% Dollar Usage Target": 10000, "Dollars Spent": 0 },
    { month: "May", "95% Dollar Usage Target": 10750, "Dollars Spent": 0 },
    { month: "Jun", "95% Dollar Usage Target": 11500, "Dollars Spent": 0 },
    { month: "Jul", "95% Dollar Usage Target": 12250, "Dollars Spent": 0 },
    { month: "Aug", "95% Dollar Usage Target": 13000, "Dollars Spent": 0 },
    { month: "Sep", "95% Dollar Usage Target": 13750, "Dollars Spent": 0 },
  ];

  const mdrpLineData = [
    { month: "Oct", "95% Dollar Usage Target": 5500, "Dollars Spent": 0 },
    { month: "Nov", "95% Dollar Usage Target": 6250, "Dollars Spent": 0 },
    { month: "Dec", "95% Dollar Usage Target": 7000, "Dollars Spent": 0 },
    { month: "Jan", "95% Dollar Usage Target": 7750, "Dollars Spent": 0 },
    { month: "Feb", "95% Dollar Usage Target": 8500, "Dollars Spent": 0 },
    { month: "Mar", "95% Dollar Usage Target": 9250, "Dollars Spent": 0 },
    { month: "Apr", "95% Dollar Usage Target": 10000, "Dollars Spent": 0 },
    { month: "May", "95% Dollar Usage Target": 10750, "Dollars Spent": 0 },
    { month: "Jun", "95% Dollar Usage Target": 11500, "Dollars Spent": 0 },
    { month: "Jul", "95% Dollar Usage Target": 12250, "Dollars Spent": 0 },
    { month: "Aug", "95% Dollar Usage Target": 13000, "Dollars Spent": 0 },
    { month: "Sep", "95% Dollar Usage Target": 13750, "Dollars Spent": 0 },
  ];

  const ebdlLineData = [
    { month: "Oct", "95% Dollar Usage Target": 5500, "Dollars Spent": 0 },
    { month: "Nov", "95% Dollar Usage Target": 6250, "Dollars Spent": 0 },
    { month: "Dec", "95% Dollar Usage Target": 7000, "Dollars Spent": 0 },
    { month: "Jan", "95% Dollar Usage Target": 7750, "Dollars Spent": 0 },
    { month: "Feb", "95% Dollar Usage Target": 8500, "Dollars Spent": 0 },
    { month: "Mar", "95% Dollar Usage Target": 9250, "Dollars Spent": 0 },
    { month: "Apr", "95% Dollar Usage Target": 10000, "Dollars Spent": 0 },
    { month: "May", "95% Dollar Usage Target": 10750, "Dollars Spent": 0 },
    { month: "Jun", "95% Dollar Usage Target": 11500, "Dollars Spent": 0 },
    { month: "Jul", "95% Dollar Usage Target": 12250, "Dollars Spent": 0 },
    { month: "Aug", "95% Dollar Usage Target": 13000, "Dollars Spent": 0 },
    { month: "Sep", "95% Dollar Usage Target": 13750, "Dollars Spent": 0 },
  ];

  const rmaLineData = [
    { month: "Oct", "95% Dollar Usage Target": 5500, "Dollars Spent": 0 },
    { month: "Nov", "95% Dollar Usage Target": 6250, "Dollars Spent": 0 },
    { month: "Dec", "95% Dollar Usage Target": 7000, "Dollars Spent": 0 },
    { month: "Jan", "95% Dollar Usage Target": 7750, "Dollars Spent": 0 },
    { month: "Feb", "95% Dollar Usage Target": 8500, "Dollars Spent": 0 },
    { month: "Mar", "95% Dollar Usage Target": 9250, "Dollars Spent": 0 },
    { month: "Apr", "95% Dollar Usage Target": 10000, "Dollars Spent": 0 },
    { month: "May", "95% Dollar Usage Target": 10750, "Dollars Spent": 0 },
    { month: "Jun", "95% Dollar Usage Target": 11500, "Dollars Spent": 0 },
    { month: "Jul", "95% Dollar Usage Target": 12250, "Dollars Spent": 0 },
    { month: "Aug", "95% Dollar Usage Target": 13000, "Dollars Spent": 0 },
    { month: "Sep", "95% Dollar Usage Target": 13750, "Dollars Spent": 0 },
  ];

  // START HERE
  const num_subs = data.num_subs;
  const sub_info = data.sub_info;
  let results = data.results;

  results = results.filter((item) => {
    return item[12] === selectedFy;
  })

  // END NEW ADDITION AUGUST 2, 2023
  let budget_data = [];
  let aftp_sum = 0;
  let mdrp_sum = 0;
  let rma_sum = 0;
  let ebdl_sum = 0;
  let count = 1;

  for (let row of sub_info) {
    let item_data = {
      id: count,
      user_id: row[0],
      first_name: row[1],
      last_name: row[2],
      grade: row[3],
      rank: row[4],
      branch: row[5],
      unit: row[6],
      aftp: 0,
      mdr: 0,
      rma: 0,
      ata: 0,
      ebdl: 0,
      aftp_dollars_spent: 0,
      mdr_dollars_spent: 0,
      rma_dollars_spent: 0,
      ebdl_dollars_spent: 0,
    };
    count++;
    budget_data.push(item_data);
  }

  for (let row of results) {
    let duty_performed = row[9].toLowerCase();
    if (["aftp", "mdr", "rma", "ebdl"].includes(duty_performed)) {
      let mutas = row[7].length > 0 ? parseInt(row[7][0]) : 0;
      let date_obj = row[10];

      let month = date_obj.toLocaleString("default", { month: "short" });
      let dollars_spent =
        row.length > 11 && row[11] !== null ? parseFloat(row[11]) : 0.0;

      // Update the total dollars spent for the specific duty performed and month
      if (duty_performed === "aftp") {
        let data_entry = aftpLineData.find((data) => data.month === month);
        if (data_entry !== undefined) {
          data_entry["Dollars Spent"] += dollars_spent;
        }
      } else if (duty_performed === "mdr") {
        let data_entry = mdrpLineData.find((data) => data.month === month);
        if (data_entry !== undefined) {
          data_entry["Dollars Spent"] += dollars_spent;
        }
      } else if (duty_performed === "ebdl") {
        let data_entry = ebdlLineData.find((data) => data.month === month);
        if (data_entry !== undefined) {
          data_entry["Dollars Spent"] += dollars_spent;
        }
      } else if (duty_performed === "rma") {
        let data_entry = rmaLineData.find((data) => data.month === month);
        if (data_entry !== undefined) {
          data_entry["Dollars Spent"] += dollars_spent;
        }
      }

      // Update the respective duty performed hours
      const item_data = budget_data.find(
        (item) => item.user_id === row[0]
      );
      if (duty_performed === "aftp") {
        item_data.aftp += mutas;
        item_data.aftp_dollars_spent += dollars_spent;
        aftp_sum += mutas;
      } else if (duty_performed === "mdr") {
        item_data.mdr += mutas;
        item_data.mdr_dollars_spent += dollars_spent;
        mdrp_sum += mutas;
      } else if (duty_performed === "rma") {
        item_data.rma += mutas;
        item_data.rma_dollars_spent += dollars_spent;
        rma_sum += mutas;
      } else if (duty_performed === "ebdl") {
        item_data.ebdl += mutas;
        item_data.ebdl_dollars_spent += dollars_spent;
        ebdl_sum += mutas;
      }
    }
  }

  for (let user of budget_data) {
    user.aftp_dollars_spent = `$${user.aftp_dollars_spent.toFixed(2)}`;
    user.mdr_dollars_spent = `$${user.mdr_dollars_spent.toFixed(2)}`;
    user.ebdl_dollars_spent = `$${user.ebdl_dollars_spent.toFixed(2)}`;
    user.rma_dollars_spent = `$${user.rma_dollars_spent.toFixed(2)}`;
  }

  // AFTP DATA ORGANIZER
  const aftp_value = 72 * num_subs;
  const aftp_data = [
    { name: "AFTPs logged", value: aftp_sum },
    { name: "Total AFTPs Permissable", value: aftp_value },
  ];

  // MDR DATA ORGANIZER
  const mdrp_value = 4 * num_subs;
  const mdrp_data = [
    { name: "MDRs logged", value: mdrp_sum },
    { name: "Total MDRs Permissable", value: mdrp_value },
  ];

  // RMA DATA ORGANIZER
  const rma_value = 24 * num_subs;
  const rma_data = [
    { name: "RMAs logged", value: rma_sum },
    { name: "Total RMAs Permissable", value: rma_value },
  ];

  // EBDL DATA ORGANIZER
  const ebdl_value = 1 * num_subs;
  const ebdl_data = [
    { name: "EBDLs logged", value: ebdl_sum },
    { name: "Total EBDLs Permissable", value: ebdl_value },
  ];

  // Initialize a set to store unique unit_names
  const unit_set = new Set();

  // Add "All Units" and "N/A" to the set
  unit_set.add("All Units");

  // Iterate through each row in the sub_info result and add unit_names to the set
  for (const row of sub_info) {
    const unit_name = row[6]; // Assuming unit_name is the 7th column (index 6)
    if (unit_name) {
      unit_set.add(unit_name);
    }
  }

  // Convert the set into a list of dictionaries in the desired format
  const sub_units = [...unit_set].map((unit_name) => ({
    label: unit_name,
    value: unit_name,
  }));

  sub_units.sort(
    (a, b) =>
      a.label !== "All Units" ||
      !b.label.includes("Unassigned Soldiers") ||
      (parseInt(a.label.split(" ").pop().replace("th", "").split("-")[0]) ||
        Infinity) -
        (parseInt(b.label.split(" ").pop().replace("th", "").split("-")[0]) ||
          Infinity) ||
      (b.label.includes("HHC") ? 1 : 0) - (a.label.includes("HHC") ? 1 : 0) ||
      (a.label > b.label ? 1 : -1)
  );

  // Final updates
  setaftpData(aftp_data);
  setmdrpData(mdrp_data);
  setrmaData(rma_data);
  setebdlData(ebdl_data);
  setNumSubs(num_subs);
  setFilteredData(budget_data);
  setaftpLineData(aftpLineData);
  setmdrpLineData(mdrpLineData);
  setrmaLineData(rmaLineData);
  setebdlLineData(ebdlLineData);

  return [
    aftp_data,
    aftpLineData,
    mdrp_data,
    mdrpLineData,
    rma_data,
    rmaLineData,
    ebdl_data,
    ebdlLineData,
    sub_units,
    budget_data,
  ];
};
