import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
  useMemo,
} from "react";
import { FlatList, View, Text, Button, TouchableOpacity } from "react-native";
import {
  MaterialReactTable,
  MRT_FullScreenToggleButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { Tooltip as MUITooltip } from "@mui/material";
import { useScale } from "../../components/scale";

import { Box, IconButton } from "@mui/material";
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
  FilterList as FilterListIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { Card } from "react-native-paper";
import { get_budget_summary, get_budget_files } from "../../api_handler/files";
import colors from "../../colors";
import { useColorContext } from "../../components/ColorContext";
import ModeContext from "../../components/ModeContext";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import LeaderBudgetRST from "./LeaderBudgetRST";
import NavSideBar from "../../components/NavSideBar";
import Scrollbars from "react-custom-scrollbars";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import CustomRadioTabs from "../../components/CustomRadioTabs";
import EditTargetLinePopup from "../../components/Budget/EditTargetLinePopup";
import BudgetChart from "../../components/Budget/BudgetChart";
import { useIsFocused } from "@react-navigation/native";
import { useSnackbar } from "../../components/SnackbarContext";
import * as Progress from "react-native-progress";
import { budgetSummary } from "../../helpers/budgetSummary";
import { getFiscalYear } from "../../utils/monthInfo";

export default function LeaderBudgetOv({ navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const { mode, toggleMode } = useContext(ModeContext);

  const scale = useScale();
  const styles = createStyles(scale);
  const isFocused = useIsFocused();
  // const [selectedId, setSelectedId] = React.useState(null);
  // const [loadingPDFs, setLoadingPDFs] = React.useState(false);
  // const [checked, setChecked] = React.useState('first');

  const [soldierData, setSoldierData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [numSubs, setNumSubs] = useState();

  const [budgetGroupButton, setBudgetGroupButton] = useState("ADA");
  const [docData, setDocData] = useState([]);
  const [rstData, setRstData] = useState([]);
  const [rstLineData, setRstLineData] = useState([]);
  const [aftpData, setaftpData] = useState([]);
  const [ebdlData, setebdlData] = useState([]);
  const [mdrpData, setmdrpData] = useState([]);
  const [rmaData, setrmaData] = useState([]);
  const [ataData, setataData] = useState([]);

  const [aftpLineData, setaftpLineData] = useState([]);
  const [ebdlLineData, setebdlLineData] = useState([]);
  const [mdrpLineData, setmdrpLineData] = useState([]);
  const [rmaLineData, setrmaLineData] = useState([]);

  // Search Bar
  const [searchText, setSearchText] = useState("");
  const [refresh, setRefresh] = React.useState(false);

  // Legend
  const aftpColors = ["#003087", "#8A8D8F"];
  const mdrpColors = ["#97a949", "#96547f"];
  const ebdlColors = ["#303bb0", "#5cd299"];
  const rmaColors = ["#ab581a", "#184697"];
  const aftpColorsDark = ["#FF5733", "#EC7BFF"];
  const mdrpColorsDark = ["#FFC300", "#FF6F00"];
  const ebdlColorsDark = ["#64DD17", "#00E5FF"];
  const rmaColorsDark = ["#00B0FF", "#FF4081"];
  const ataColors = ["#1e90ff", "#4c061b"];
  const [pieSelection, setpieSelection] = useState(aftpData);
  const [pieColorSelection, setpieColors] = useState(
    mode === "light" ? aftpColors : aftpColorsDark
  );
  const [lineSelection, setlineSelection] = useState(aftpLineData);
  const [activeChartButton, setActiveChartButton] = useState("ALL");
  const [activeDateButton, setActiveDateButton] = useState("YTD");
  const [selectedFy, setSelectedFy] = useState(getFiscalYear());
  const currentFy = getFiscalYear();

  // unit drop down menu
  const [open, setOpen] = useState(false);
  const [unitValue, setUnitValue] = useState(["All Units"]);
  const [unitSelection, setUnitSelection] = useState([]);
  const tableInstanceRef = useRef(null);
  // filter modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalFilters, setModalFilters] = useState([]);
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [columnVisibility, setColumnVisibility] = useState({});
  const [density, setDensity] = useState("compact");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [editTargetLine, setEditTargetLine] = useState(false);

  const enterFullScreen = () => {
    setIsFullScreen(true);
    StatusBar.setHidden(true);
  };

  const exitFullScreen = () => {
    setIsFullScreen(false);
    StatusBar.setHidden(false);
  };

  // Create theme styling for table
  const globalTheme = useTheme();
  const generalTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colors.mode,
          primary: globalTheme.palette.primary,
        },
      }),
    [globalTheme, isFocused]
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
      }),
    [globalTheme, isFocused]
  );

  const budgetDataButtons = [
    {
      label: "ADA",
      index: 0,
    },
    // {
    //     label: "RST",
    //     index: 1,
    // },
  ];
  // consts for right side of page
  const chartButtons = [
    {
      label: "ALL",
      data: aftpData,
      colors: mode === "light" ? aftpColors : aftpColorsDark,
      lineData: aftpLineData,
      index: 0,
      pieSelector: pieSelector,
    },
    {
      label: "AFTP",
      data: aftpData,
      colors: mode === "light" ? aftpColors : aftpColorsDark,
      lineData: aftpLineData,
      index: 1,
      pieSelector: pieSelector,
    },
    {
      label: "MDR",
      data: mdrpData,
      colors: mode === "light" ? mdrpColors : mdrpColorsDark,
      lineData: mdrpLineData,
      index: 2,
      pieSelector: pieSelector,
    },
    {
      label: "RMA",
      data: rmaData,
      colors: mode === "light" ? rmaColors : rmaColorsDark,
      lineData: rmaLineData,
      index: 3,
      pieSelector: pieSelector,
    },
    // Uncomment and add more buttons as needed
    // {
    //   label: 'ATA',
    //   data: ataData,
    //   colors: ataColors,
    //   lineData: ataLineData,
    //   index: 3,
    //   pieSelector: pieSelector,
    // },
    {
      label: "EBDL",
      data: ebdlData,
      colors: mode === "light" ? ebdlColors : ebdlColorsDark,
      lineData: ebdlLineData,
      index: 4,
      pieSelector: pieSelector,
    },
  ];

  const Headers = () => {
    const headerLabels = [
      "UNIT",
      "RANK",
      "FIRST",
      "LAST",
      "AFTP",
      "MDR",
      "RMA",
      "EBDL",
    ];
    return (
      <View
        style={
          mode === "light"
            ? styles.searchBarContainerLight
            : styles.searchBarContainerDark
        }
      >
        {headerLabels.map((label, index) => (
          <Text
            key={index}
            style={
              mode === "light"
                ? styles.headerLabelsLight
                : styles.headerLabelsDark
            }
          >
            {label}
          </Text>
        ))}
      </View>
    );
  };

  // Table
  // file items
  //   const Item = ({ unit, rank, name, aftp, mdr, rma, last, ata, ebdl }) => {
  //     const textStyle = mode === 'light' ? styles.itemDetailLight : styles.itemDetailDark;

  //     return (
  //       <TouchableOpacity
  //         style={mode === 'light' ? styles.itemLight : styles.itemDark}
  //       >
  //         {[
  //           unit, rank, name, last, aftp, mdr, rma, ebdl // Add or remove elements as needed
  //         ].map((text, index) => (
  //           <Text key={index} style={textStyle}>
  //             {index >= 4 ? `$${text}` : text}
  //           </Text>
  //         ))}
  //       </TouchableOpacity>
  //     );
  //   };

  useEffect(() => {
    // The call to get_budget_summary and get_budget_summary will need to 
    // be removed once rst data is actually being displayed.
    // get_budget_files completes the work that calling get_budget_summary does other than
    // acquiring RST data as it is not functional yet. Adding RST data functionality to the page should invoke
    // changes to get_budget_files and then the removal of get_budget_summary.
    async function getFiles() {
      console.log(global.user_data.user_id, global.user_data.admin_unit_ids);
      let res = await get_budget_summary(
        {
          user_id: global.user_data.user_id,
          admin_unit_ids: global.user_data.admin_unit_ids,
        },
        global.token
      );
      if (res.status === 200) {
        res = res.data.body;
        //uncommenting the below lines 302-319 for user testing
        setSoldierData(res.soldier_data);
        setUnitSelection(res.unit_info);
        setFilteredData(res.soldier_data);
        setNumSubs(res.num_subs);

        setaftpData(res.aftp_data);
        setmdrpData(res.mdr_data);
        setrmaData(res.rma_data);
        setebdlData(res.ebdl_data);

        setpieSelection(res.aftp_data);

        setaftpLineData(res.aftpLineData);
        setmdrpLineData(res.mdrpLineData);
        setrmaLineData(res.rmaLineData);
        setebdlLineData(res.ebdlLineData);

        setlineSelection(res.aftpLineData);

        setRstData(res.rst_uta_data);
        setRstLineData(res.rst_Line_Data);
      } else {
        showSnackbar(
          "error",
          "Application Error: Failed to retrieve required information"
        );
        navigation.navigate("Home");
      }
      setLoaded(true);
    }

    async function getBudgetFiles() {
      let res = await get_budget_files(
        {
          user_id: global.user_data.user_id,
          admin_unit_ids: global.user_data.admin_unit_ids,
        },
        global.token
      );
      if (res.status === 200) {
        const data = res.data.body;
        const onLoad = budgetSummary(
          data,
          setNumSubs,
          setaftpData,
          setmdrpData,
          setrmaData,
          setebdlData,
          setaftpLineData,
          setmdrpLineData,
          setrmaLineData,
          setebdlLineData,
          setFilteredData,
          selectedFy,
        );
        setDocData(data);
        setpieSelection(onLoad[0]);
        setlineSelection(onLoad[1]);
        setUnitSelection(onLoad[8]);
        setSoldierData(onLoad[9]);
      } else {
        showSnackbar(
          "error",
          "Application Error: Failed to retrieve required information"
        );
        navigation.navigate("Home");
      }
      setLoaded(true);
    }

    getBudgetFiles();
    getFiles();
  }, []);

  const columns = [
    {
      accessorKey: "unit",
      header: "Unit",
      size: 1,
    },
    {
      accessorKey: "rank",
      header: "Rank",
      size: 1,
    },
    {
      accessorKey: "first_name",
      header: "First Name",
      size: 1,
    },
    {
      accessorKey: "last_name",
      header: "Last Name",
      size: 1,
    },
    {
      accessorKey: "aftp_dollars_spent",
      header: "AFTP Spent",
      size: 1,
    },
    {
      accessorKey: "mdr_dollars_spent",
      header: "MDR Spent",
      size: 1,
    },
    {
      accessorKey: "rma_dollars_spent",
      header: "RMA Spent",
      size: 1,
    },
    {
      accessorKey: "ebdl_dollars_spent",
      header: "EBDL Spent",
      size: 1,
    },
    {
      accessorKey: "ata",
      header: "ATA",
      size: 1,
    },
  ];

  const renderModal = (modalFilters) => {
    return (
      <View style={styles.modal}>
        <Button onPress={toggleModal} title="Close"></Button>
        <FlatList
          numColumns={"2"}
          data={modalFilters}
          renderItem={({ item }) => (
            <Card style={{ margin: 5 }}>
              <View
                style={{
                  padding: 10,
                  margin: 5,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  <Text style={{ alignSelf: "center" }}>{item.label}</Text>
                  <IconButton onClick={() => filters(item.id)}>
                    {item.isChecked ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </IconButton>
                </View>
              </View>
            </Card>
          )}
        />
        <Button title="Apply Filter" style={{ padding: "25" }}></Button>
      </View>
    );
  };

  function pieSelector(data, color, linedata, label) {
    setpieSelection(data);
    setpieColors(color);
    setActiveChartButton(label);
    setlineSelection(linedata);
  }

  function dateSelector(index) {
    setActiveDateButton(index);
  }

  async function refreshList() {
    // global.revFiles = await
    // setRefresh(true);
    // setRefresh(false);
  }

  function toggleModal() {
    setModalVisible(!modalVisible);
  }

  const filters = (id) => {
    let temp = modalFilters.map((item) => {
      if (item.id === id) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setModalFilters(temp);
  };

  //let selected = modalFilters.filter((modalFilters) => modalFilters.isChecked);

  const handleSearch = (selectedValues, fy) => {
    fy = fy !== undefined ? fy : selectedFy;
    const allUnitsIndex = selectedValues.indexOf("All Units");
    const previousAllUnitsIndex = unitValue.indexOf("All Units");

    let updatedValues = [...selectedValues];

    if (
      allUnitsIndex !== -1 &&
      previousAllUnitsIndex === -1 &&
      selectedValues.length > 1
    ) {
      updatedValues = ["All Units"];
    } else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter((value) => value !== "All Units");
    }

    setUnitValue(updatedValues);

    let dataSubset = docData;
    if (!updatedValues.includes("All Units")) {
      dataSubset = {
        ...dataSubset,
        sub_info: dataSubset.sub_info.filter((item) => {
          const unit = item[6];
          return updatedValues.some((selectedUnit) =>
            unit.toLowerCase().includes(selectedUnit.toLowerCase())
          );
        }),
        results: dataSubset.results.filter((item) => {
          const unit = item[6];
          return updatedValues.some((selectedUnit) =>
            unit.toLowerCase().includes(selectedUnit.toLowerCase())
          );
        }),
      };
    }
    console.log(dataSubset);
    const onLoad = budgetSummary(
      dataSubset,
      setNumSubs,
      setaftpData,
      setmdrpData,
      setrmaData,
      setebdlData,
      setaftpLineData,
      setmdrpLineData,
      setrmaLineData,
      setebdlLineData,
      setFilteredData,
      fy,
    );
    if (activeChartButton != "ALL") applyFilterPieSelector(onLoad);
  };

  const applyFilterPieSelector = (onLoad) => {
    if (activeChartButton == "AFTP") {
      pieSelector(onLoad[0], aftpColors, onLoad[1], "AFTP");
    } else if (activeChartButton == "MDR") {
      pieSelector(onLoad[2], mdrpColors, onLoad[3], "MDR");
    } else if (activeChartButton == "RMA") {
      pieSelector(onLoad[4], rmaColors, onLoad[5], "RMA");
    } else if (activeChartButton == "EBDL") {
      pieSelector(onLoad[6], ebdlColors, onLoad[7], "EBDL");
    }
  };

  const loadingStyles = {
    animation: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={generalTheme}>
      <View style={{ flexDirection: "row", height: "100%" }}>
        {/* <NavSideBar /> */}
        <Scrollbars
          showsverticalscrollindicator="true"
          style={
            mode === "light" ? styles.containerLight : styles.containerDark
          }
        >
          <View>
            {loaded ? (
              <View style={styles.potSelectionWrapper}>
                <View style={styles.potSelectors}>
                  <View style={styles.chartSelector}>
                    {budgetDataButtons.map((button) => (
                      <TouchableOpacity
                        key={button.index}
                        style={[
                          styles.pieButton,
                          budgetGroupButton === button.label &&
                            styles.pieButtonPressed,
                        ]}
                        onPress={() => {
                          setBudgetGroupButton(button.label);
                        }}
                      >
                        <Text
                          style={{
                            color:
                              mode === "light" ? colors.black : colors.white,
                            height: "100%",
                            fontSize: 20,
                          }}
                        >
                          {button.label}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                </View>
              </View>
            ) : null}

            {budgetGroupButton === "RST" && loaded ? (
              <LeaderBudgetRST
                rstData={rstData}
                rstLineData={rstLineData}
                num_subs={numSubs}
                unit_info={unitSelection}
              />
            ) : null}

            {budgetGroupButton === "ADA" && loaded ? (
              <View style={styles.mainContainer}>
                <View
                  style={{
                    alignText: "center",
                    flex: 3,
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={
                      mode === "light"
                        ? styles.pageTitleLight
                        : styles.pageTitleDark
                    }
                  >
                    Budget
                  </Text>
                  <Text
                    style={{
                      ...styles.pageDescription,
                      color: mode === "light" ? colors.black : colors.white,
                      marginTop: "2%",
                    }}
                  >
                    Outlines information regarding budgeting and funding types
                    throughout the fiscal year (FY). Visualize expenditure,
                    colors of money, and pay-code types, including Additional
                    Flying Training Periods (AFTP), Readiness Management
                    Assemblies (RMA), MDR, and Additional Training Assemblies
                    (ATA).
                  </Text>
                  <View style={styles.unitsFilter}>
                    <FormControl
                      style={{
                        width: "66%",
                        backgroundColor: colors.textfield_background,
                      }}
                    >
                      <InputLabel id="tables-units-label">
                        Units Filter
                      </InputLabel>
                      <Select
                        multiple
                        value={unitValue}
                        input={
                          <OutlinedInput
                            label="Units Filter"
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: colors.border_color,
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: colors.border_color,
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: colors.border_color,
                                },
                              "& .MuiSelect-icon": {
                                color: colors.inputted_text,
                              },
                              "& .MuiInputBase-input": {
                                color: colors.inputted_text,
                              },
                              "& .MuiFormLabel-root": {
                                color: colors.inputted_text,
                                height: 56,
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color: colors.inputted_text,
                              },
                              "& .MuiFormHelperText-root": {
                                backgroundColor: colors.screen_background,
                                margin: 0,
                                paddingLeft: 1,
                              },
                            }}
                          />
                        }
                        onChange={(event) => {
                          handleSearch(event.target.value);
                        }}
                        MenuProps={{ style: { zIndex: 1500 } }}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {unitSelection.map((option) => (
                          <MenuItem
                            style={{ whiteSpace: "pre" }}
                            key={option.label}
                            value={option.label}
                          >
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </View>
                  <Text
                    style={{
                      ...styles.dropDownNote,
                      color: mode === "light" ? colors.black : colors.white,
                    }}
                  >
                    Statuses are displayed for above selected Unit.
                  </Text>
                  <View
                    style={
                      isFullScreen
                        ? styles.fullScreenContainer
                        : styles.tableContainer
                    }
                  >
                    <ThemeProvider theme={tableTheme}>
                      <MaterialReactTable
                        columns={columns}
                        data={filteredData}
                        tableInstanceRef={tableInstanceRef}
                        enableFullScreenToggle={true}
                        renderToolbarInternalActions={({ table }) => (
                          <>
                            <Box>
                              <MRT_ToggleGlobalFilterButton
                                table={tableInstanceRef.current}
                              />
                              <MRT_ToggleFiltersButton
                                table={tableInstanceRef.current}
                              />
                              <MRT_ShowHideColumnsButton
                                table={tableInstanceRef.current}
                              />
                              <MRT_FullScreenToggleButton
                                table={tableInstanceRef.current}
                                onClickCapture={() => {
                                  setIsFullScreen(!isFullScreen);
                                }}
                              />
                            </Box>
                          </>
                        )}
                      />
                    </ThemeProvider>
                  </View>
                </View>

                <View style={{ flexDirection: "row", flex: 0.25 }} />
    
                {!isFullScreen && (
                  <View style={{flex: 3}}>
                    <CustomRadioTabs 
                      fy={currentFy}
                      selectedFy={selectedFy}
                      setSelectedFy={setSelectedFy}
                      setSelectedFyForms={null}
                      currentFyForms={null}
                      prevFyForms={null}
                      upcomingFyForms={null}
                      applyFilters={null}
                      unitType={unitValue}
                      dateType={null}
                      formType={activeChartButton}
                      statusType={null}
                      colors={colors}
                      handleSearch={handleSearch}
                    />
                    <View
                      style={[
                        mode === "light"
                          ? styles.legendContainerLight
                          : styles.legendContainerDark,
                        { shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 4, }
                      ]}
                    >
                      <View
                        style={{
                          width: "99%",
                          alignContent: "center",
                        }}
                      >
                        <View style={styles.chartSelector}>
                          {chartButtons.map((button) => (
                            <TouchableOpacity
                              key={button.index}
                              style={[
                                styles.pieButton,
                                activeChartButton === button.label &&
                                  styles.pieButtonPressed,
                              ]}
                              onPress={() => {
                                button?.pieSelector(
                                  button?.data,
                                  button?.colors,
                                  button?.lineData,
                                  button?.label
                                );
                              }}
                            >
                              <Text
                                style={{
                                  color:
                                    mode === "light"
                                      ? colors.black
                                      : colors.white,
                                }}
                              >
                                {button.label}
                              </Text>
                            </TouchableOpacity>
                          ))}
                        </View>
                      </View>
                      {editTargetLine ? (
                        <EditTargetLinePopup
                          closePopup={() => setEditTargetLine(false)}
                          subtype={activeChartButton}
                          pot={budgetGroupButton}
                        />
                      ) : (
                        <BudgetChart
                          activeChartButton={activeChartButton}
                          budgetGroupButton={budgetGroupButton}
                          pieSelection={pieSelection}
                          pieColorSelection={pieColorSelection}
                          setEditTargetLine={setEditTargetLine}
                          unitId={global.user_data.unit_id}
                          token={global.token}
                          currentFy={currentFy}
                          selectedFy={selectedFy}
                        />
                      )}
                    </View>
                  </View>
                )}
              </View>
            ) : null}
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );
}

const createStyles = (scale) => ({
  scrollViewLight: {
    backgroundColor: colors.light_mode_screen_background,
  },
  scrollViewDark: {
    backgroundColor: colors.dark_mode_screen_background,
  },
  scrollView: {
    backgroundColor: colors.screen_background,
  },
  containerLight: {
    backgroundColor: colors.light_mode_screen_background,
    flex: 1,
    height: "100%",
  },
  containerDark: {
    backgroundColor: colors.dark_mode_screen_background,
    flex: 1,
    height: "100%",
  },
  potSelectionWrapper: {
    margin: "1%",
    flexDirection: "row",
    width: "98%",
    alignSelf: "center",
  },
  potSelectors: {
    width: "100%",
    height: "100%",
  },
  mainContainer: {
    flexDirection: "row",
    width: "90%",
    alignSelf: "center",
    justifyContent: "flex-start",
  },
  pdf: {
    width: 500,
    height: 1000,
  },
  lightList: {
    backgroundColor: colors.leader_dash_light_gray,
    borderRadius: 20,
    marginTop: 15,
    marginBottom: 15,
  },
  darkList: {
    backgroundColor: colors.leader_dash_very_dark_gray,
    borderRadius: 20,
    height: "60%",
    marginTop: 15,
    marginBottom: 15,
  },
  title: {
    fontSize: scale(50),
  },
  pageTitleLight: {
    fontSize: 30,
    fontWeight: "bold",
    marginHorizontal: 5,
    textAlign: "Left",
    paddingTop: 25,
    color: "#000",
  },
  pageTitleDark: {
    fontSize: 30,
    fontWeight: "bold",
    marginHorizontal: 5,
    textAlign: "Left",
    paddingTop: 25,
    color: "#FFF",
  },
  pageDescription: {
    fontSize: 18,
    marginHorizontal: 5,
    textAlign: "Left",
    marginTop: "10%",
  },
  dropDown: {
    border: false,
    borderRadius: 10,
    fontSize: 18,
    shadowColor: "#000",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    flex: 0.3,
  },
  dropDownNote: {
    fontSize: 12,
    marginHorizontal: 5,
    textAlign: "Left",
    paddingTop: 5,
  },
  button: {
    marginTop: 2,
  },
  header: {
    height: "15%",
    // marginTop: "0%",
    // height: 110,
  },
  main: {
    marginTop: "1%",
    height: "85%",
    alignItems: "center",
  },
  tableHeader: {
    flexDirection: "column",
    justifyContent: "content",
    position: "sticky",
    top: 0,
    zIndex: 999,
    width: "99%",
  },
  searchBarContainerLight: {
    backgroundColor: colors.light_mode_textfield_background,
    flexDirection: "row",
    flex: 1,
    height: 40,
    paddingVertical: 10,
    margin: 5,
    borderRadius: 20,
    borderBottomWidth: 1,
    borderColor: "#ECECEC",
    alignItems: "center",
    shadowColor: "#ECECEC",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
  tableContainer: {
    flex: 1,
  },
  fullScreenContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%", // Set width to 100% for full-screen effect
    height: "100%", // Set height to 100% for full-screen effect
    zIndex: 100,
  },
  searchBarContainerDark: {
    backgroundColor: colors.dark_mode_textfield_background,
    flexDirection: "row",
    flex: 1,
    height: 40,
    paddingVertical: 10,
    margin: 5,
    borderRadius: 20,
    borderBottomWidth: 1,
    borderColor: "#ECECEC",
    alignItems: "center",
    shadowColor: "#ECECEC",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
  iconsLight: {
    padding: 10,
    color: colors.black,
  },
  iconsDark: {
    padding: 10,
    color: colors.white,
  },
  modal: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  searchBarLight: {
    height: 40,
    borderRadius: 20,
    textAlign: "left",
    flex: 1,
    padding: 10,
    outlineStyle: "none",
    color: colors.black,
  },
  searchBarDark: {
    height: 40,
    borderRadius: 20,
    textAlign: "left",
    flex: 1,
    padding: 10,
    outlineStyle: "none",
    color: colors.white,
  },
  headerLabelsTime: {
    flex: 1.5,
    justifyContent: "content",
    fontSize: 18,
    textAlign: "center",
  },
  headerLabelsLight: {
    flex: 1,
    justifyContent: "content",
    fontSize: 15,
    textAlign: "center",
    color: colors.black,
  },
  headerLabelsDark: {
    flex: 1,
    justifyContent: "content",
    fontSize: 15,
    textAlign: "center",
    color: colors.white,
  },
  itemLight: {
    backgroundColor: colors.light_mode_textfield_background,
    flex: 1,
    flexDirection: "row",
    backgroundColor: "white",
    paddingVertical: 20,
    marginVertical: 8,
    marginHorizontal: 5,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    alignItems: "center",
    textAlign: "center",
  },
  itemDark: {
    flexDirection: "row",
    backgroundColor: colors.dark_mode_textfield_background,
    paddingVertical: 20,
    marginVertical: 8,
    marginHorizontal: 5,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    alignItems: "center",
    textAlign: "center",
  },
  itemDetailLight: {
    color: colors.black,
    flex: 1,
  },
  itemDetailDark: {
    color: colors.white,
    flex: 1,
  },
  doc: {
    margin: 15,
    width: 40,
    height: 40,
  },
  chartSelector: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-evenly",
    textAlign: "center",
    borderColor: "#D9D9D9",
    borderBottomWidth: 2,
    margin: 25,
  },
  dateSelector: {
    alignSelf: "center",
    width: "80%",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-evenly",
    textAlign: "center",
    margin: 25,
    marginTop: 0,
  },
  dateButtonPressed: {
    borderRadius: 20,
    backgroundColor: "#A98C42",
  },
  pieChart: {
    flex: 1,
    flexDirection: "column",
    alignContent: "center",
    fontFamily: "Trebuchet MS",
    fontWeight: "bold",
    fontSize: 14,
  },
  pieButton: {
    flex: 0.25,
  },
  pieButtonPressed: {
    borderBottomWidth: 4,
    borderColor: "#A98C42",
  },
  legendContainerLight: {
    backgroundColor: colors.leader_dash_light_gray,
    borderRadius: 20,
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
  },
  legendContainerDark: {
    backgroundColor: colors.leader_dash_very_dark_gray,
    borderRadius: 20,
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
  },
  legend: {
    color: "black",
    alignItems: "left",
  },
  allButton: {
    flexDirection: "row",
    height: 30,
    width: "30%",
    marginLeft: "25%",
  },
  appButton: {
    flexDirection: "row",
    height: 30,
    width: "30%",
    marginLeft: "25%",
  },
  filterAllText: {
    fontSize: 15,
    marginTop: 10,
  },
  filterAppText: {
    fontSize: 15,
    marginTop: 10,
  },

  // container: {
  //   width: '87%',
  //   backgroundColor: colors.screen_background,
  // },
  // pageTopSection: {
  //   flexDirection: 'row',
  //   width: '90%',
  //   alignSelf: 'center',
  //   marginLeft: '5%',
  //   marginTop: '2.5%'
  // },
  // pageInfoText: {
  //   fontSize: 18,
  //   marginHorizontal: 5,
  //   textAlign: 'left',
  //   paddingTop: 25,
  //   color: colors.text
  // },
  // tableContainer: {
  //   justifyContent: 'center',
  //   marginVertical: '2%',
  //   alignSelf: 'center',
  //   marginHorizontal: '5%'
  // },
  // titleDiv: {
  //   flexDirection: 'row',
  //   width: '100%',
  //   alignItems: 'center',
  // },
  // title: {
  //   fontSize: scale(30),
  //   fontFamily: 'Trebuchet MS',
  //   flex: 1,
  //   paddingTop: 25,
  //   fontWeight: 'bold',
  //   marginHorizontal: 5,
  //   textAlign: 'Left',
  //   color: colors.screen_title,
  // },
  textInputProps: {
    "& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input": {
      color: colors.inputted_text,
      // WebkitTextFillColor:  colors.inputted_text ,
      fontFamily: "Trebuchet MS",
    },
    ".MuiSvgIcon-root": {
      fill: colors.inputted_text,
      fontFamily: "Trebuchet MS",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: colors.form_border,
        fontFamily: "Trebuchet MS",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1876d2",
        fontFamily: "Trebuchet MS",
      },
      // '& fieldset': {
      //     borderColor:  '#c4c4c4' : 'transparent',
      // },
    },
    "&  .MuiFormHelperText-root": {
      backgroundColor: colors.screen_background,
      fontFamily: "Trebuchet MS",
      margin: 0,
      paddingLeft: 1,
    },
    // .MuiFormHelperText-root.Mui-error
  },
  textbox: {
    width: "66%",
    backgroundColor: colors.textfield_background,
  },
  unitsFilter: {
    marginTop: "5%",
  },
});
