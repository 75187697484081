import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const CustomRadioTabs = ({
    fy,
    selectedFy,
    setSelectedFy,
    setSelectedFyForms,
    currentFyForms,
    prevFyForms,
    upcomingFyForms,
    applyFilters,
    unitType,
    dateType,
    formType,
    statusType,
    colors,
    handleSearch,
}) => {
  const styles = StyleSheet.create({
      container: {
        flex: 0,
        padding: 20,
      },
      tabContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
      tab: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
        fontFamily: 'Trebuchet MS',
        borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
        borderColor: colors.textfield_background,
        borderRadius: 10,
        backgroundColor: colors.textfield_background,
        height: 50,
        shadowOpacity: 0.25,
        shadowRadius: 4,
      },
      activeTab: {
        backgroundColor: colors.leader_dash_legend_container,
        borderColor: colors.border_color,
        zIndex:5
      },
      tabText: {
        color: colors.text,
      },
  });

  const tabs = [`FY ${fy-1}`, `FY ${fy}`, `FY ${fy+1}`];
  return (
    <View style={styles.container}>
      <View style={styles.tabContainer}>
        {tabs.map((tab) => (
          <TouchableOpacity
            key={tab}
            style={[
              styles.tab,
              (selectedFy === tab || `FY ${selectedFy}` === tab) && styles.activeTab,
            ]}
            onPress={() => {
                let prevSelectedFy = selectedFy;
                if (tab === `FY ${fy-1}` && tab !== prevSelectedFy) {
                    if (setSelectedFyForms) {
                      setSelectedFy(tab);
                      setSelectedFyForms(prevFyForms);
                      applyFilters(prevFyForms, dateType, formType, unitType, statusType, false, true);
                    } else {
                      setSelectedFy(fy-1);
                      handleSearch(unitType, fy-1);
                    }
                } else if (tab === `FY ${fy}` && tab !== prevSelectedFy) {
                    if (setSelectedFyForms) {
                      setSelectedFy(tab);
                      setSelectedFyForms(currentFyForms);
                      applyFilters(currentFyForms, dateType, formType, unitType, statusType, false, true);
                    } else {
                      setSelectedFy(fy);
                      handleSearch(unitType, fy);
                    }
                } else if (`FY ${fy+1}` && tab !== prevSelectedFy) {
                    if (setSelectedFyForms) {
                      setSelectedFy(tab);
                      setSelectedFyForms(upcomingFyForms);
                      applyFilters(upcomingFyForms, dateType, formType, unitType, statusType, false, true);
                    } else {
                      setSelectedFy(fy+1);
                      handleSearch(unitType, fy+1);
                    }
                }
            }}
          >
            <Text style={[
              styles.tabText,
            ]}>
              {tab}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

export default CustomRadioTabs;
