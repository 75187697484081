import { budgetAPI } from './api_settings';
import axios from 'axios';

// Function to create a target line
// Test JSON
// {
//   "unit_id": "1",
//   "subtype": "AFTP",
//   "pot": "ADA"
//   "month_targets": [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200]
//   "permissions": [3005],
// }
export const create_target_line = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/create_target_line';

    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}

// Function to get target lines
// Test JSON
// {
//   "unit_id": "1",
//   "pot": "ADA",
// }
export const get_target_lines = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/get_target_lines';

    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}

export const get_budget_charts = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/get_budget_charts';
    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}

export const get_budget = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/read_budget';

    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}

export const get_budget_stats = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/get_budget_stats';

    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}

// Function to create budget
// Test JSON
// {
//  "pot": "ADA",
//  "unit_id": "1",
//  "budgets": [
//    {
//      "subtype": "ALL",
//      "budget_amount": 5000
//    },
//    {
//      "subtype": "RMA",
//      "budget_amount": 3000
//    },
//    {
//      "subtype": "AFTP",
//      "budget_amount": 2000
//    },
//    {
//      "subtype": "MDR",
//      "budget_amount": 4000
//    },
//    {
//      "subtype": "EBDL",
//      "budget_amount": 3500
//    }
//  ]
// }

export const delete_budget = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/delete_budget';

    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}

export const edit_budget = async (data, token) => {
    const url_endpoint = budgetAPI + 'budget/edit_budget';

    const header = {
        'Content-Type': 'application/json',
        'Authorization': token
    };

    let res = await axios
        .post(url_endpoint, data, { headers: header })
        .then((response) => response)
        .catch((error) => console.error(error.message));

    return res;
}