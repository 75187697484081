import React, { useEffect, useState, useContext, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import { CourseContext } from '../../../components/CourseContext.js';
import ModeContext from '../../../components/ModeContext';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SearchIcon from '@mui/icons-material/Search';
import { Block, FullscreenExit } from '@mui/icons-material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DownloadIcon from '@mui/icons-material/Download';




function EBDLadmin() {
  const {courses, setCourses} = useContext(CourseContext);
  // const [courses, setCourses] = useState([
  //   {
  //     REQ: 'AR 190-13',
  //     courseNumber: 'PY011.16',
  //     COURSE_TITLE: 'INTRODUCTION TO PHYSICAL SECURITY',
  //     PH: '',
  //     ATRRS_OR_SOURCE: 'CDSE',
  //     COURSE_HOURS: '1',
  //     EFFECTIVE_DATE: '20190627',
  //     courseId: 0,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 190-13',
  //     courseNumber: 'PY104.16',
  //     COURSE_TITLE: 'LOCK AND KEY SYSTEMS',
  //     PH: '',
  //     ATRRS_OR_SOURCE: 'CDSE',
  //     COURSE_HOURS: '1.5',
  //     EFFECTIVE_DATE: '20190627',
  //     courseId: 1,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 190-13',
  //     courseNumber: 'PY103.16',
  //     COURSE_TITLE: 'PHYSICAL SECURITY MEASURES',
  //     PH: '',
  //     ATRRS_OR_SOURCE: 'CDSE',
  //     COURSE_HOURS: '2',
  //     EFFECTIVE_DATE: '20190627',
  //     courseId: 2,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 190-13',
  //     courseNumber: 'PY106.16',
  //     COURSE_TITLE: 'PHYSICAL SECURITY PLANNING AND IMPLEMENTATION',
  //     PH: '2',
  //     ATRRS_OR_SOURCE: 'CDSE',
  //     COURSE_HOURS: '2',
  //     EFFECTIVE_DATE: '20190627',
  //     courseId: 3,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'JS-US009',
  //     COURSE_TITLE: 'JOINT STAFF OPERATIONS SECURITY (OPSEC) - 1H',
  //     PH: '1',
  //     ATRRS_OR_SOURCE: 'ATTRS',
  //     COURSE_HOURS: '1',
  //     EFFECTIVE_DATE: '20121119',
  //     courseId: 4,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'DHA-US001',
  //     COURSE_TITLE: 'HIPAA AND PRIVACY ACT TRAINING (1.5HRS)',
  //     PH: '1.5',
  //     ATRRS_OR_SOURCE: 'JKO',
  //     COURSE_HOURS: '1.5',
  //     EFFECTIVE_DATE: '20150430',
  //     courseId: 5,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'J3OP-US018',
  //     COURSE_TITLE: 'FUNDAMENTALS OF PERSONNEL RECOVERY (PR 102) COURSE',
  //     PH: '3',
  //     ATRRS_OR_SOURCE: 'ATTRS/JKO',
  //     COURSE_HOURS: '3',
  //     EFFECTIVE_DATE: '20150430',
  //     courseId: 6,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'JS-US007',
  //     COURSE_TITLE: 'LEVEL 1 ANTITERRORISM AWARENESS TRAINING',
  //     PH: '2',
  //     ATRRS_OR_SOURCE: 'JKO',
  //     COURSE_HOURS: '2',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 7,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'AR',
  //     COURSE_TITLE:
  //       'U.S. ARMY THREAT AWARENESS AND REPORTING PROGRAM (TARP) COURSE',
  //     PH: '1',
  //     ATRRS_OR_SOURCE: 'ALMS',
  //     COURSE_HOURS: '1',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 8,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'AR',
  //     COURSE_TITLE:
  //       'SECURITY TRAINING - ANNUAL AWARENESS; MANAGING PERSONNEL WITH',
  //     PH: '2',
  //     ATRRS_OR_SOURCE: 'ALMS',
  //     COURSE_HOURS: '2',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 9,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'AR',
  //     COURSE_TITLE: 'RESILIENCE TRAINING GAT 2.0',
  //     PH: '2',
  //     ATRRS_OR_SOURCE: 'ARMYFIT',
  //     COURSE_HOURS: '2',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 10,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'AR',
  //     COURSE_TITLE: 'INFORMATION ASSURANCE/DOD CYBER AWARENESS',
  //     PH: '2',
  //     ATRRS_OR_SOURCE: 'IA',
  //     COURSE_HOURS: '2',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 11,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'AR',
  //     COURSE_TITLE:
  //       'COMBATING TRAFFICKING IN PERSONS (CTIP) GENERAL AWARENESS COURSE',
  //     PH: '1',
  //     ATRRS_OR_SOURCE: 'JKO',
  //     COURSE_HOURS: '1',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 12,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'JS-US014',
  //     COURSE_TITLE: 'JOINT STAFF LAW OF WAR',
  //     PH: '2.5',
  //     ATRRS_OR_SOURCE: 'JKO',
  //     COURSE_HOURS: '2.5',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 13,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'PR101',
  //     COURSE_TITLE:
  //       'SHARP SEXUAL HARASSMENT/ASSAULT RESPONSE PREVENTION TRAINING COURSE',
  //     PH: '3',
  //     ATRRS_OR_SOURCE: 'ALMS',
  //     COURSE_HOURS: '3',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 14,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'PR101',
  //     COURSE_TITLE: 'CODE OF CONDUCT (ARMY PERSONNEL RECOVERY) (ARPR) 101',
  //     PH: '3',
  //     ATRRS_OR_SOURCE: 'ALMS',
  //     COURSE_HOURS: '3',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 15,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 350-1',
  //     courseNumber: 'AR',
  //     COURSE_TITLE: 'FRATERNIZATION',
  //     PH: '1',
  //     ATRRS_OR_SOURCE: 'JKO',
  //     COURSE_HOURS: '1',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 16,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'AR 600-55',
  //     courseNumber: '920-F92 (DL)',
  //     COURSE_TITLE: 'LICENSE INSTRUCTOR/EXAMINER',
  //     PH: '8',
  //     ATRRS_OR_SOURCE: 'ATTRS',
  //     COURSE_HOURS: '8',
  //     EFFECTIVE_DATE: '20210117',
  //     courseId: 17,
  //     multiDelete: false,
  //   },
  //   {
  //     REQ: 'ASA',
  //     courseNumber: 'AR',
  //     COURSE_TITLE:
  //       'THE ARMY MICP – INTERNAL CONTROL ADMINISTRATOR (ICA) COURSE',
  //     PH: '40',
  //     ATRRS_OR_SOURCE: 'ALMS',
  //     COURSE_HOURS: '40',
  //     EFFECTIVE_DATE: '20141030',
  //     courseId: 18,
  //     multiDelete: false,
  //   },
  // ]);

  const { mode } = React.useContext(ModeContext);
  const [multiDelete, setMultiDelete] = useState('off');
  // Colors provided
  const mainPageBackgroundColor = mode === 'dark' ?'#1F1F1F' : '#FFFFFF';
  const iconsColor = mode === 'dark' ? '#FFFFFF' : '#757575';
  const headerBackgroundColor = mode === 'dark' ?'#323232' : '#FFFFFF';
  const oddRowColor = mode === 'dark' ? '#363636' : '#FFFFFF';
  // mode === 'dark' ? '#262626' : '#EBEBEB'
  const evenRowColor = mode ==='dark' ? '#262626' : '#ebebeb'; 
  const textColor = mode === 'dark' ? '#D3D3D3' : '#282828';
  const rowGapColor = mode === 'dark' ? '#474747' : '#E0E0E0'; // For the gap between rows
  const multiDeleteColor = multiDelete === 'on' ? 'red' : iconsColor;
  const [isEdit, setIsEdit] = useState(null);

  const [isSearching, setIsSearching] = useState(0);

  const [newCourseNumber, setNewCourseNumber] = useState('');
  const [newREQ, setNewREQ] = useState('');
  const [newCOURSE_TITLE, setNewCOURSE_TITLE] = useState('');
  const [newPH, setNewPH] = useState('');
  const [newATRRS_OR_SOURCE, setNewATRRS_OR_SOURCE] = useState('');
  const [newCOURSE_HOURS, setNewCOURSE_HOURS] = useState('');
  const [newEFFECTIVE_DATE, setNewEFFECTIVE_DATE] = useState('');

  const [courseInputArray, setCourseInputArray] = useState([
    { courseNumber: '', courseId: 20, multiDelete: false },
  ]);

  const [validationErrorCourseTitle, setValidationErrorCourseTitle] = useState(false);
  const [validationErrorREQ, setValidationErrorREQ] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(0);

  let nextId = useRef(21);

  //pagination states
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  //storing searching string
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const element = document.getElementById('someElement');

    if (!element || !element.parentElement) {
      // console.warn('Element or parentElement is missing, skipping...');
      return;
    }

    // Safely interact with the element here
    // console.log('Element found and parentElement exists:', element);

    return () => {
      // Cleanup any side effects here
      // console.log('Cleanup on unmount');
    };
  }, []); // Empty dependency array to ensure this only runs once on mount
  




  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const insertionToTable = () => {
    if (isEdit !== null) {
      cancelHandler();
    }

    if (!courses[0].COURSE_TITLE.trim()) {
      const deleting = courses.filter((e, index) => index !== 0);
      setCourses(deleting);
      window.confirm(
        "Please fill out the last added course before inserting a new one.')"
      );
      return;
    }

    const maxId = Math.max(...courses.map((course) => course.courseId));

    const newRow = {
      REQ: '',
      courseNumber: '',
      COURSE_TITLE: '',
      PH: '',
      ATRRS_OR_SOURCE: '',
      COURSE_HOURS: '',
      EFFECTIVE_DATE: '',
      courseId: maxId + 1,
      multiDelete: false,
    };
    setIsEdit(newRow.courseId);

    setCourses([newRow, ...courses]);
  };

  const addHandler = () => {
    setCourseInputArray([
      ...courseInputArray,
      { courseNumber: '', courseId: courseID.current++, multiDelete: false },
    ]);
  };

  const updateThecourseInputArrayWithInput = (e, index) => {
    const updatedCourseInputArray = [...courseInputArray];
    updatedCourseInputArray[index].courseNumber = e.target.value;
    setCourseInputArray(updatedCourseInputArray);
  };

  const editHandler = (courseID) => {
    // Ensure courses[0] exists and has a valid COURSE_TITLE
    if (isEdit !== null) {
        if (!courses[0] || !courses[0].COURSE_TITLE.trim()) {
            const deleting = courses.filter((e, index) => index !== 0);
            setCourses(deleting);
        }
    }
    setIsEdit(courseID);
    
    // Find the course by its ID and ensure it exists
    const rowOfThatCourse = courses.find((course) => course.courseId === courseID);
    
    // If the course exists, set the input values
    if (rowOfThatCourse) {
        setNewREQ(rowOfThatCourse.REQ || '');
        setNewCourseNumber(rowOfThatCourse.courseNumber || '');
        setNewCOURSE_TITLE(rowOfThatCourse.COURSE_TITLE || '');
        setNewPH(rowOfThatCourse.PH || '');
        setNewATRRS_OR_SOURCE(rowOfThatCourse.ATRRS_OR_SOURCE || '');
        setNewCOURSE_HOURS(rowOfThatCourse.COURSE_HOURS || '');
        setNewEFFECTIVE_DATE(rowOfThatCourse.EFFECTIVE_DATE || '');
    }
};


  const cancelHandler = () => {
    if (!courses[0].COURSE_TITLE.trim()) {
      const deleting = courses.filter((e, index) => index !== 0);
      setCourses(deleting);
    }
    setIsEdit(null);

    setNewREQ('');
    setNewCourseNumber('');
    setNewCOURSE_TITLE('');
    setNewPH('');
    setNewATRRS_OR_SOURCE('');
    setNewCOURSE_HOURS('');
    setNewEFFECTIVE_DATE('');
  };

  const save = (courseID) => {
    const validationErrorCourseTitle = !!newCOURSE_TITLE.trim(); //     false     true
    //     true      false

    if (!newCOURSE_TITLE.trim()) {
      // if this is empty
      setValidationErrorCourseTitle(true); // we have an error
      return;
    }

    if(!newREQ.trim()){
      setValidationErrorREQ(true);
      return;
    }

    // Proceed only if the validation passes
    const updatedArray = courses.map((course) => {
      if (course.courseId === courseID) {
        return {
          ...course,
          REQ: newREQ,
          courseNumber: newCourseNumber,
          // COURSE_TITLE: newCOURSE_TITLE !== '' ? newCOURSE_TITLE : course.COURSE_TITLE,
          COURSE_TITLE: newCOURSE_TITLE,
          PH: newPH, // PH is not required, so no validation here
          ATRRS_OR_SOURCE: newATRRS_OR_SOURCE,
          COURSE_HOURS: newCOURSE_HOURS,
          EFFECTIVE_DATE: newEFFECTIVE_DATE,
        };
      }
      return course;
    });

    setCourses(updatedArray);

    // Clear the editing state and reset error state after saving
    setIsEdit(null);
    setNewREQ('');
    setNewCourseNumber('');
    setNewCOURSE_TITLE('');
    setNewPH('');
    setNewATRRS_OR_SOURCE('');
    setNewCOURSE_HOURS('');
    setNewEFFECTIVE_DATE('');
    setValidationErrorCourseTitle(false); // Reset validation errors
    setValidationErrorREQ(false); // Reset validation errors
  };

  const deleteInputBoxHandler = (input) => {
    const updatedArray = courseInputArray.filter(
      (course) => course.courseId !== input.courseId
    );
    setCourseInputArray(updatedArray);
  };

  const deleteHandler = (courseID) => {
    const courserow = courses.find((course) => course.courseId === courseID);
    if (
      window.confirm('Are you sure to delete ' + courserow.COURSE_TITLE + '?')
    ) {
      const updatedArray = courses.filter(
        (course) => course.courseId !== courseID
      );
      setCourses(updatedArray);
    }
  };

  const insertionHandler = () => {
    const trimInputs = courseInputArray.filter((input) =>
      input.courseNumber.trim()
    );
    const updatedCourse = [...trimInputs, ...courses];
    setCourses(updatedCourse);
    setCourseInputArray([
      { courseNumber: '', courseId: courseID.current++, multiDelete: false },
    ]);
  };

  const checkBoxHandler = (course) => {
    
    const updatingCourses = [...courses];
    updatingCourses.map((e) => {
      
      if (course.courseId === e.courseId) {
        e.multiDelete = !e.multiDelete; // Toggle the value
      }
    });

    const anychecked = updatingCourses.some((e) => e.multiDelete === true);
    if (anychecked) {
      setMultiDelete('on');
    } else {
      setMultiDelete('off');
    }
    setCourses(updatingCourses);
  };

  const multiDeleteHandler = () => {
    const update = courses.filter((course) => !course.multiDelete);
    setCourses(update);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // get the first file
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' }); // Add the type here to avoid errors

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Correct the method to sheet_to_json to convert the worksheet
      const newCourseData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // header: 1 to treat the first row as data

      const maxId = Math.max(...courses.map((course) => course.courseId));

      // Map over the parsed rows and create the course objects
      const addingArray = newCourseData
        .filter((row) => row[2] && row[2].trim() && row[2] !== 'COURSE TITLE')
        .map((row, index) => ({
          REQ: row[0] || '',
          courseNumber: row[1] || '',
          COURSE_TITLE: row[2],
          PH: row[3] || '',
          ATRRS_OR_SOURCE: row[4] || '',
          COURSE_HOURS: row[5] || '',
          EFFECTIVE_DATE: row[6] || '',
          courseId: maxId + index + 1,
          multiDelete: false,
        }));

      if (addingArray.length > 0) {
        setCourses((prevCourses) => [...addingArray, ...prevCourses]);
      }
    };

    reader.readAsArrayBuffer(file); // Read the file as array buffer
  };

  const downloadAsExcel = () => {
    // Prepare the data to be exported
    const tableData = courses.map((course) => ({
      REQ: course.REQ,
      CourseNumber: course.courseNumber,
      CourseTitle: course.COURSE_TITLE,
      PH: course.PH,
      ATRRS_OR_SOURCE: course.ATRRS_OR_SOURCE,
      CourseHours: course.COURSE_HOURS,
      EffectiveDate: course.EFFECTIVE_DATE,
    }));

    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(tableData);

    // Add custom headers
    const headers = [
      [
        'REQ',
        'COURSE NUMBER',
        'COURSE TITLE',
        'PH',
        'ATRRS OR SOURCE',
        'COURSE HOURS',
        'EFFECTIVE DATE',
      ],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, headers, { origin: 'A1' });

    // Set custom column widths
    const wscols = [
      { wch: 16.4 }, //REQ
      { wch: 29.2 }, //CourseNumber
      { wch: 109.6 }, //CourseTitle
      { wch: 4.8 }, //PH
      { wch: 27 }, //ATRRS_OR_SOURCE
      { wch: 22.4 }, // CourseHours
      { wch: 24.6 }, //EffectiveDate
    ];
    worksheet['!cols'] = wscols;

    // Apply styles to the header (first row)
    const headerStyle = {
      font: { bold: true, sz: 14 },
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
      fill: { fgColor: { rgb: 'FFFF00' } }, // Optional: Yellow fill for headers
    };

    const dataStyle = {
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
    };

    // Apply styles to each cell in the first row (header row)
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      worksheet[headerCellAddress].s = headerStyle;
    }

    // Apply borders to all cells in the data rows
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      // Start from the second row (R=1)
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue; // Skip empty cells
        worksheet[cellAddress].s = dataStyle;
      }
    }

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Courses');

    // Generate a binary Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save the file using FileSaver
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(data, 'CoursesData.xlsx');
  };

  const searchHandlee = (searchString) => {
    setSearchTerm(searchString);
    
  };

  //filter the course base on search value
  const filterCourses = courses.filter((course) =>
    course.COURSE_TITLE.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const visibility = filterCourses.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div
    style={{
      position: isFullScreen === 1 ? 'fixed' : 'relative',
      backgroundColor: mainPageBackgroundColor,
      overflowY: 'auto',
      overflowX: 'auto',
      padding: isFullScreen === 1 ? '0px' : '20px',
      top: isFullScreen === 1 ? '0' : null,
      left: isFullScreen === 1 ? '0' : null,
      right: isFullScreen === 1 ? '0' : null,
      bottom: isFullScreen === 1 ? '0' : null,
      width: isFullScreen === 1 ? '100vw' : '95%',
      height: isFullScreen === 1 ? '100vh' : 'auto',
      margin: 0,
      minHeight: '100vh', 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: isFullScreen === 1 ? 9999 : 'auto',
    }}
    >
      <TableContainer
        sx={{
          margin: isFullScreen === 0 ? '15px 0' : '0 px',
          width: isFullScreen === 0 ? '95%' : '100%',

          backgroundColor: headerBackgroundColor,
          borderRadius: '6px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
          // paddingRight: '20px', // Add space to the right side
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={10} align="right">
                <span style={{ display: 'flex-end', alignItems: 'center' }}>
                  {/* search Icon */}
                  {isSearching === 0 ? (
                    <Tooltip title="Search Course" arrow>
                      <IconButton onClick={() => {
                        setIsSearching(1);
                        if (isEdit !== null ) {
                          if (!courses[0].COURSE_TITLE.trim() || !courses[0].COURSE_TITLE.trim()) {
                            const deleting = courses.filter((e, index) => index !== 0);
                            setCourses(deleting);
                          }
                        }
                        if(isEdit !== null){
                          setIsEdit(null);
                        }
                        
                        
                        }}>
                        <SearchIcon sx={{color: iconsColor}}/>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip title="Turn off search" arrow>
                        <IconButton
                          onClick={() => {
                            setIsSearching(0);
                            setSearchTerm('');
                          }}
                        >
                          <SearchOffIcon sx={{color: iconsColor}}/>
                        </IconButton>
                      </Tooltip>

                      <TextField
                        id=""
                        label="search course"
                        type="text"
                        variant="standard"
                        value={searchTerm}
                        onChange={(e) => searchHandlee(e.target.value)}
                        InputProps={{
                          style: {color: textColor},
                          endAdornment: searchTerm && (
                            <Tooltip title="Clear Search" arrow>
                              <IconButton
                                onClick={() => {
                                  setSearchTerm('');
                                }}
                              >
                                <ClearIcon sx={{color: iconsColor}}/>
                              </IconButton>
                            </Tooltip>
                          ),
                        }}
                        
                       
                      />
                    </>
                  )}

                  <Tooltip title="Download as Excel">
                    <IconButton onClick={downloadAsExcel}>
                      <DownloadIcon sx={{color: iconsColor}}/>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Upload Excel File" arrow>
                    <IconButton component="label">
                      <CloudUploadIcon sx={{color: iconsColor}}/>
                      {/* Hidden input to trigger file upload */}
                      <input
                        type="file"
                        accept=".xls, .xlsx"
                        onChange={(e) => handleFileUpload(e)} // Your file handler here
                        style={{
                          display: 'none',
                        }} // Input is hidden but still functional
                      />
                    </IconButton>
                  </Tooltip>

                  {/* add icon */}
                  <Tooltip title="Add Course" arrow>
                    <IconButton onClick={() => insertionToTable()}>
                      <AddIcon sx={{color: iconsColor}}/>
                    </IconButton>
                  </Tooltip>

                  {isFullScreen === 0 ? (
                    <Tooltip title="Full Screen" arrow>
                      <IconButton
                        onClick={() => {
                          setIsFullScreen(1);
                        }}
                      >
                        <FullscreenIcon sx={{color: iconsColor}}/>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Exit Full screen" arrow>
                      <IconButton
                        onClick={() => {
                          setIsFullScreen(0);
                        }}
                      >
                        <FullscreenExitIcon sx={{color: iconsColor}}/>
                      </IconButton>
                    </Tooltip>
                  )}
                </span>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableHead
            sx={{
              backgroundColor: headerBackgroundColor,
            }}
          >
            <TableRow>
              <TableCell sx={{ color: textColor }}>
                <IconButton onClick={() => multiDeleteHandler()}>
                  <DeleteIcon
                  sx={{color: multiDeleteColor}}
                  />
                </IconButton>
              </TableCell>
              <TableCell sx={{ color: textColor }}>Req</TableCell>
              <TableCell sx={{ color: textColor }}>Course Number</TableCell>
              <TableCell sx={{ color: textColor }}>Course Title</TableCell>
              <TableCell sx={{ color: textColor }}>Ph</TableCell>
              
              <TableCell sx={{ color: textColor}}>Atrrs/Source</TableCell>
              <TableCell sx={{ color: textColor }}>Course Hours</TableCell>
              <TableCell sx={{ color: textColor }}>Effective Date</TableCell>

              <TableCell sx={{ color: textColor }}>Edit</TableCell>
              <TableCell sx={{ color: textColor }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibility.map((course, index) => {
              const isFirstRow = index === 0;
              const isSecondRow = index === 1;
              const isLastRow = index === courses.length - 1;
              const isBeforeLastRow = index === courses.length - 2;

              return (
                <TableRow
                  key={course.courseId}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? evenRowColor : oddRowColor,
                    '&:hover': {
                      backgroundColor: mode === 'dark' ? '#4D6578' : '#E0EDFC', 
                    },
                    borderBottom: `3px solid ${rowGapColor}`,
                    borderTop:
                      isFirstRow || isSecondRow || isLastRow || isBeforeLastRow
                        ? `3px solid ${rowGapColor}`
                        : undefined,
                  }}
                >
                  <TableCell>
                    <Checkbox
                      sx={{color: iconsColor}}
                      onClick={() => checkBoxHandler(course)}
                    />
                  </TableCell>
                  <TableCell sx={{ color: textColor, whiteSpace: 'nowrap' }}>
                    {isEdit === course.courseId ? (
                      <>
                        <input
                          type="text"
                          value={newREQ}
                          onChange={(e) => setNewREQ(e.target.value)}
                          style={{
                            backgroundColor: '#bec0be',
                            color: '#282828',
                            borderRadius: '6px',
                            padding: '4px',
                            width: '100%',
                            minWidth: '60px',
                            border: '2px solid #ccc',
                            border: validationErrorREQ ? '2px solid red' : '2px solid #ccc',
                          }}
                        />
                        {validationErrorREQ ? (
                          <span
                            style={{
                              display: 'block',
                              color: 'red',
                              fontSize: '12px',
                              margin: '5px',
                            }}
                          >
                            required
                          </span>
                        ) : null}
                      </>
                    ) : (
                      course.REQ
                    )}
                  </TableCell>

                  <TableCell sx={{ color: textColor, whiteSpace: 'nowrap' }}>
                    {isEdit === course.courseId ? (
                      <input
                        type="text"
                        value={newCourseNumber}
                        onChange={(e) => setNewCourseNumber(e.target.value)}
                        style={{
                          backgroundColor: '#bec0be',
                          color: '#282828',
                          borderRadius: '6px',
                          padding: '4px',
                          width: '100%',
                          minWidth: '60px',
                          border: '2px solid #ccc',
                        }}
                      />
                    ) : (
                      course.courseNumber
                    )}
                  </TableCell>
                  <TableCell sx={{ color: textColor }}>
                    {isEdit === course.courseId ? (
                      <>
                        <input
                          type="text"
                          value={newCOURSE_TITLE}
                          onChange={(e) => setNewCOURSE_TITLE(e.target.value)}
                          style={{
                            backgroundColor: '#bec0be',
                            color: '#282828',
                            borderRadius: '6px',
                            padding: '4px',
                            border: validationErrorCourseTitle ? '2px solid red' : '2px solid #ccc',
                          }}
                        />
                        {validationErrorCourseTitle ? (
                          <span
                            style={{
                              display: 'block',
                              color: 'red',
                              fontSize: '12px',
                              margin: '5px',
                            }}
                          >
                            required
                          </span>
                        ) : null}
                      </>
                    ) : (
                      course.COURSE_TITLE
                    )}
                  </TableCell>
                  <TableCell sx={{ color: textColor }}>
                    {isEdit === course.courseId ? (
                      <input
                        type="text"
                        value={newPH}
                        onChange={(e) => setNewPH(e.target.value)}
                        style={{
                          backgroundColor: '#bec0be',
                          color: '#282828',
                          borderRadius: '6px',
                          padding: '4px',
                          width: '100%',
                          minWidth: '60px',
                          border: '2px solid #ccc',
                        }}
                      />
                    ) : (
                      course.PH
                    )}
                  </TableCell>
                  <TableCell sx={{ color: textColor }}>
                    {isEdit === course.courseId ? (
                      <input
                        type="text"
                        value={newATRRS_OR_SOURCE}
                        onChange={(e) => setNewATRRS_OR_SOURCE(e.target.value)}
                        style={{
                          backgroundColor: '#bec0be',
                          color: '#282828',
                          borderRadius: '6px',
                          padding: '4px',
                          width: '100%',
                          minWidth: '60px',
                          border: '2px solid #ccc',
                        }}
                      />
                    ) : (
                      course.ATRRS_OR_SOURCE
                    )}
                  </TableCell>
                  <TableCell sx={{ color: textColor }}>
                    {isEdit === course.courseId ? (
                      <input
                        type="text"
                        value={newCOURSE_HOURS}
                        onChange={(e) => setNewCOURSE_HOURS(e.target.value)}
                        style={{
                          backgroundColor: '#bec0be',
                          color: '#282828',
                          borderRadius: '6px',
                          padding: '4px',
                          width: '100%',
                          minWidth: '60px',
                          border: '2px solid #ccc',
                        }}
                      />
                    ) : (
                      course.COURSE_HOURS
                    )}
                  </TableCell>
                  <TableCell sx={{ color: textColor }}>
                    {isEdit === course.courseId ? (
                      <input
                        type="text"
                        value={newEFFECTIVE_DATE}
                        onChange={(e) => setNewEFFECTIVE_DATE(e.target.value)}
                        style={{
                          backgroundColor: '#bec0be',
                          color: '#282828',
                          borderRadius: '6px',
                          padding: '4px',
                          width: '100%',
                          minWidth: '60px',
                          border: '2px solid #ccc',
                        }}
                      />
                    ) : (
                      course.EFFECTIVE_DATE
                    )}
                  </TableCell>

                  <TableCell>
                    {isEdit === course.courseId ? (
                      <>
                        <IconButton onClick={() => save(course.courseId)}>
                          <SaveIcon sx={{ color: textColor }} />
                        </IconButton>
                        <IconButton onClick={cancelHandler}>
                          <CancelIcon sx={{ color: textColor }} />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton onClick={() => editHandler(course.courseId)}>
                        <EditIcon
                          sx={{
                            color: textColor,
                            '&:hover': {
                              color: 'green',
                            },
                          }}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => deleteHandler(course.courseId)}>
                      <DeleteIcon
                        sx={{
                          color: textColor,
                          '&:hover': {
                            color: 'red',
                          },
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
          {/* Add Pagination Here */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterCourses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePage}
            onRowsPerPageChange={handleRowsPerPage}
            sx={{
              color: textColor,
            }}
          />
      </TableContainer>
    </div>
  );
}

export default EBDLadmin;
