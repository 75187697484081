/* eslint-disable require-jsdoc */
import React, { useEffect, useReducer, useRef, useState, useMemo } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import Modal from "react-native-modal";

import { useIsFocused } from '@react-navigation/native';
import { useScale } from '../../../components/scale';
import CustomHeader from '../../../components/adminHeader';
import { delete_ba_dates } from '../../../api_handler/events';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import BA_Upload_Template from '../../../CSV_Templates/BA_Upload_Template.csv'
import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, createTheme, styled, alpha, darken, ThemeProvider } from '@mui/material/styles';

import ModeContext from '../../../components/ModeContext';
import { useColorContext } from '../../../components/ColorContext';

import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';

import DownloadIcon from '@mui/icons-material/Download';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { put_s3, upload_BA_dates_CSV } from '../../../api_handler/files';

import { storeJsonData } from '../../../api_handler/asyncstorage.js';

import { get_all_ba_dates } from '../../../api_handler/events';
import NavSideBar from '../../../components/NavSideBar';
import { useNavBarContext } from '../../../components/NavBarContext.js';
import { useSnackbar } from '../../../components/SnackbarContext.js';
import * as Progress from 'react-native-progress'


export default function BA_Dates({ navigation }) {
  const { navState } = useNavBarContext();
  const { showSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [density, setDensity] = useState('compact');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const [rowSelection, setRowSelection] = useState({});
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [showAddRow, setShowAddRow] = useState(true);
  const isFocused = useIsFocused();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loaded, setLoaded] = useState(false);
  const scale = useScale();
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [unitName, setUnitName] = React.useState('');
  const { mode } = React.useContext(ModeContext);
  const tableInstanceRef = useRef(null);
  const { colors } = useColorContext();
  const [hasEditPermission, setHasEditPermission] = React.useState(true);
  const [deleteModalVis, setDeleteModalVis] = React.useState(false);
  const [BADatesToDelete, setBADatesToDelete] = React.useState([]);
  const [rowsToDelete, setRowsToDelete] = React.useState([]);

  useEffect(() => {
    setUnitName(global.user_data.unit_name);
  }, []);

  useEffect(() => {
    if (!navState.canUploadBaDates) {
      setHasEditPermission(false);
    }

    setShowAddRow(true);
    fetchUnitBAdates();
  }, [isFocused]);

  const styles = ({
    tableContainer: {
      width: '95%',
      justifyContent: 'center',
      marginLeft: '2.5vw',
      marginTop: '7vh',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    scrollView: {
      backgroundColor: colors.screen_background,
    },
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
    },
    title: {
      fontSize: scale(25),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color: colors.text,
    },
    header: {
      backgroundColor: 'pink',
      height: 145,
    },
    main: {
      height: '85%',
    },
    titleAndDescription: {
      title: {
        fontSize: scale(30),
        fontFamily: 'Trebuchet MS',
        flex: 1,
        color: colors.text,
        paddingLeft: '5%',
        paddingTop: 25
      },
      description: {
        flex: 1,
        fontFamily: 'Trebuchet MS',
        fontSize: scale(18),
        color: colors.text,
        paddingLeft: '5%',
        paddingRight: '5%',
      }
    },
    innerContainer: {
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    titleDiv: {
      marginTop: '0%',
      height: 50,
      alignItems: 'center',
    },
    buttonDiv: {
      flexDirection: 'row',
      // backgroundColor: 'red',
      height: 100,
      // width: '50%',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    dropdownInput: {
      'm': 1,
      'width': 300,
      'backgroundColor': colors.textfield_background,
      '& .MuiFormLabel-root, & .MuiSelect-select': {
        color: colors.text,
      },
      // .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    },
    textInput: {
      width: '25%',
      margin: 20,
      marginLeft: 30,
      backgroundColor: colors.textfield_background,
      // "& .MuiOutlinedInput-input": {
      //     color:  colors.text : colors.dark_mode_text,
      // }
      // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
    },
    tableView: {
      width: '100%',
      alignItems: 'center',
      height: '80%',
      marginBottom: 50,
    },
    tableDiv: {
      marginTop: '0%',
      backgroundColor: 'white',
      width: '90%',
      alignItems: 'center',
    },
    buttons: {
      backgroundColor: colors.navSideBar_Background,
      borderRadius: '40',
      alignItems: 'center',
      color: colors.bow,
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
    },
    helpBtn: {
      width: '15%',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
      backgroundColor: colors.button,
    },
    payBtn: {
      width: '90%',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      // marginTop: 30,
      // marginBottom: 30,
      backgroundColor: colors.table_background_button,
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      // fontSize: 12,
      padding: 10,
      whiteSpace: 'nowrap',
    },
    deleteBtn: {
      backgroundColor: 'red',
      height: '15px',
    },
    textbox: {
      width: '25%',
      margin: 20,
      marginLeft: 30,
    },
    modal: {
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30,
    },
    modalText: {
      width: '60%',
      fontSize: scale(25),
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '5%',
      marginBottom: '5%',
    },
    image: {
      flex: 1,
      width: '15%',
      resizeMode: 'contain',
    },
    modalButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '60%',
    },
    modalButton: {
      flex: 1,
      padding: '7px 30px 7px 30px',
      width: '20%',
    },
    buttonStyle: {
      width: '30%',
      backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      padding: 5,
      marginBottom: '5%',
    },
    buttonText: {
      color: 'white',
      fontSize: scale(25),
    },
    deleteText: {
      fontSize: 25,
      fontFamily: 'Trebuchet MS',
      textAlign: 'center',
      color: colors.text,
    },
    confirmButtons:
    {
      width: '15%',
      borderRadius: 40,
      alignItems: 'center',
      backgroundColor: colors.button,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      margin: '2%'
    },
    deleteModal:
    {
      width: '40%',
      height: '100%',
      marginLeft: '37%',
      marginTop: '5%',
      zIndex: 1000,
    },
  });

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
      }),
    [globalTheme, isFocused],
  );

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      'borderRadius': 6,
      'marginTop': theme.spacing(1),
      'minWidth': 180,
      'color': colors.text,
      'boxShadow':
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
        background: colors.textfield_background,
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: colors.text,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        'background': colors.textfield_background,
      },
    },
  }));

  const columns = useMemo(() => {
    let columns = [];
    if (navState.canUploadBaDates) {
      columns.push(
        {
          accessorKey: 'edit',
          header: 'Edit',
          size: 1,
          enableSorting: false,
          enableColumnFilter: false,
          enableGrouping: false,
          Cell: ({ row }) => {
            const [isHovered, setIsHovered] = React.useState(false);
            const handleMouseEnter = () => {
              setIsHovered(true);
            };
            const handleMouseLeave = () => {
              setIsHovered(false);
            };
            return (
              <div
                onClick={() => {
                  global.editDate = {
                    start: row.original.start_timestamp,
                    end: row.original.end_timestamp,
                    training_events: row.original.training_events,
                  };
                  storeJsonData('editBADate', global.editDate);
                  navigation.navigate('units_dashboard', { screen: 'edit_BA_dates' });
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <EditIcon style={{ color: isHovered ? 'green' : mode == 'dark' ? colors.white : colors.gray }} />
              </div>
            );
          },
        },
      )
    }

    columns.push(
      {
        accessorFn: (row) => formatTableDate(row.start_timestamp),
        accessorKey: 'start_timestamp',
        header: 'Start Date',
        size: 1,
        sortingFn: (rowA, rowB) => {
          const startDateA = getDate(rowA.original.start_timestamp);
          const startDateB = getDate(rowB.original.start_timestamp);
          const endDateA = getDate(rowA.original.end_timestamp);
          const endDateB = getDate(rowB.original.end_timestamp);

          if (startDateA !== startDateB) {
            return startDateA.localeCompare(startDateB);
          }

          if (endDateA !== endDateB) {
            return endDateA.localeCompare(endDateB);
          }

          return 0;
        },
      },
      {
        accessorFn: (row) => formatTableDate(row.end_timestamp),
        accessorKey: 'end_timestamp',
        header: 'End Date',
        size: 1,
        sortingFn: (rowA, rowB) => {
          const endDateA = getDate(rowA.original.end_timestamp);
          const endDateB = getDate(rowB.original.end_timestamp);
          return endDateA.localeCompare(endDateB);
        },
      },
      {
        accessorKey: 'is_mandatory',
        header: 'Mandatory',
        size: 1,
      },
      {
        accessorKey: 'muta',
        header: 'MUTAs',
        size: 1,
      },
      {
        accessorKey: 'rst_authorized',
        header: 'RST Authorized',
        size: 1,
      },
      {
        accessorKey: 'training_events',
        header: 'Training Events',
        size: 1,
      },
      {
        accessorKey: 'created_by',
        header: 'Created By',
        size: 1,
      },
    )

    return columns;
  }, [rows]);

  function formatTableDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    let formattedDate = inputDate.toLocaleString(undefined, options);

    return formattedDate;
  }

  function getDate(timestampString) {
    return timestampString.slice(0, 10);
  }

  function uploadBACSVFile(closeMenu) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';

    input.onchange = (e) => {
      const file = e.target.files[0];

      if (file) {
        if (file.type === 'text/csv' || file.name.toLowerCase().endsWith('.csv')) {
          getBACSV(file);
        } else {
          showSnackbar('error', 'Please select a CSV file.');
        }
      }
    };
    closeMenu();
    input.click();
  }

  function getBACSV(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async function () {
      const binary = reader.result;
      uploadBACSV(binary);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const fetchUnitBAdates = async () => {
    try {
      const get_all_ba_dates_JSON = {
        unit_id: global.user_data.unit_id,
        is_rst: false
      };
      const ba_dates = await get_all_ba_dates(get_all_ba_dates_JSON, global.token);
      if (ba_dates.status === 200) {
        setRows(ba_dates.data.body);
      } else {
        showSnackbar("error", "Application Error: Failed to retrieve unit BA Dates");
      }
      setLoaded(true);
    } catch (error) {
      console.error('Error fetching unit BA dates:', error);
    }
  };

  async function uploadBACSV(binary) {
    const data = {
      user_id: global.user_data.user_id,
      unit_id: global.user_data.unit_id,
      user_full_name: global.user_data.first_name + " " + global.user_data.middle_name + " " + global.user_data.last_name,
    };
    var csv_filepath = 'add_BA_dates_csvs/' + global.user_data.user_id + '.csv';

    const rev = await put_s3(csv_filepath, binary, 'text/csv', global.token);

    const response = await upload_BA_dates_CSV(data, global.token);

    if (response.status === 200) {
      console.log(response.data);

      if (response.data.body === '') {
        showSnackbar("success", "Successfully added all BA Dates");
        fetchUnitBAdates();
      } else {
        showSnackbar(
          "error",
          "Application Error: File Uploaded But Failed to Add Following Dates:\n" + response.data.body,
          'top',
          'right',
          60000,
          800,
          () => {
            fetchUnitBAdates();
          }
        );
      }
    } else {
      console.log(response);
      showSnackbar("error", "Application Error: Failed to upload the file");
    }
  };

  const fileOpenMenu = (event) => {
    setAnchorEl(event.target);
  };
  const fileCloseMenu = () => {
    setAnchorEl(null);
  };

  function csvFileName() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}-${minutes}`;
  }

  const csvOptions = {
    filename: csvFileName(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ['Start Date', 'End Date', 'muta', 'Mandatory', 'RST Authorized', 'Training Events', 'Created By'],
  };

  const csvExporter = new ExportToCsv(csvOptions);

  // Retrieve excel columns in correct order
  const handleExportData = () => {
    const desiredOrder = ['start_timestamp', 'end_timestamp', 'muta', 'is_mandatory', 'rst_authorized', 'training_events', 'created_by'];

    const columnsInDesiredOrder = rows.map((item) => {
      const obj = {};
      desiredOrder.forEach((header) => {
        obj[header] = item[header];
      });
      return obj;
    });

    csvExporter.generateCsv(columnsInDesiredOrder);
    fileCloseMenu();
  };

  function uploadUserCSVFile() {
    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      const file = e.target.files[0];
      getUserCSVBase64(file);
    };

    input.click();
  }

  function getUserCSVBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let csv64 = reader.result;
      csv64 = csv64.substring(21);
      // uploadUsersCSV(csv64);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function cancelDelete() {
    setRowsToDelete([]);
    setBADatesToDelete([]);
    setDeleteModalVis(false);
  }

  const confirmDelete = async () => {
    setDeleteModalVis(false);

    // Get all Ids to delete
    const startDates = [];
    const endDates = [];
    const trainingEvents = [];
    rowsToDelete.forEach((row) => {
      startDates.push(row.start_timestamp);
      endDates.push(row.end_timestamp);
      trainingEvents.push(row.training_events);
    });

    try {
      const get_all_BA_dates_JSON = {
        start_dates: startDates,
        end_dates: endDates,
        training_events: trainingEvents,
        unit_id: global.user_data.unit_id,
      };

      let res = await delete_ba_dates(get_all_BA_dates_JSON, global.token);
      if (res.status === 200) {
        // Update table data with deleted rows
        setRows(rows.filter((row) => !rowsToDelete.includes(row)));

        showSnackbar("success", "Successfully Deleted the chosen BA Dates");
      } else {
        showSnackbar("error", "Application Error: Failed to delete chosen BA Date(s)")
      }
    } catch (error) {
      console.error('Error deleting BA dates:', error);
    }
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (

    <View style={styles.container}>
      <View style={styles.innerContainer}>
        {/* <NavSideBar /> */}
        <ScrollView showsverticalscrollindicator="true" style={styles.scrollView}>
          <View style={styles.main}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexDirection: "column", flex: 1 }}>
                <Text style={styles.titleAndDescription.title}>
                  {
                    hasEditPermission ? (
                      <>
                        <Text style={{ fontWeight: 'bold' }}>Admin Console {'>'} </Text>
                        <Text>Manage BA Dates</Text>
                      </>
                    ) : (
                      <>
                        <Text>View BA Dates</Text>
                      </>
                    )
                  }
                </Text>
                <View style={{ marginTop: '1%', alignItems: 'flex-start', width: '100%', flex: 1 }}>
                  {
                    hasEditPermission ? (
                      <>
                        <Text style={styles.titleAndDescription.description}>
                          This page allows admins to view, add, edit, and delete upcoming Battle Assembly dates.
                          Using the "Upload BA Dates" button using the given Excel sheet, admins can also bulk add dates.
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text style={styles.titleAndDescription.description}>
                          This page allows soliders to view upcoming Battle Assembly dates. {'\n'}
                          Using the "Export BA Dates" button allows soldiers to bulk export dates.
                        </Text>
                      </>
                    )
                  }
                  
                </View>
              </View>
              {/* <View style={{ flexDirection: 'column', marginTop: '2%', marginRight: '5%' }}>
                <a
                  style={styles.buttons}
                  href={BA_Upload_Template}
                  download="BA_Upload_Template"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TouchableOpacity
                    // style={{ alignItems: 'center' }}
                    onPress={() => {
                      download_template;
                    }}>
                    <Text style={styles.innerText}>Download BA Schedule Template</Text>
                  </TouchableOpacity>
                </a>
              </View> */}
            </View>
            {/* Delete document modal */}
            <Modal
              isVisible={deleteModalVis}
              style={styles.deleteModal}
            >
              <View style={{
                backgroundColor: colors.screen_background,
                alignItems: 'center',
                padding: '5%',
                borderRadius: 10,
                flexDirection: 'column',
              }}>
                <View style={{ height: '50%', alignItems: 'center', marginTop: 25 }}>
                  <Text style={styles.deleteText}>{BADatesToDelete.length === 1 ?
                    `Are you sure you want to delete ${BADatesToDelete}?` :
                    `Are you sure you want to delete ${BADatesToDelete.length} dates: ${BADatesToDelete.join(', ')}?`}</Text>
                </View>

                <View style={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-around',
                }}>
                  <TouchableOpacity
                    style={styles.confirmButtons}
                    onPress={() => cancelDelete()}>
                    <Text style={styles.innerText}>Cancel</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.confirmButtons}
                    onPress={() => confirmDelete()}>
                    <Text style={styles.innerText}>Submit</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>
            {/* End of Delete Modal */}

            <View style={styles.tableContainer}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={rows}
                  enableRowSelection={navState.canUploadBaDates}
                  positionToolbarAlertBanner={'bottom'}
                  tableInstanceRef={tableInstanceRef}
                  enableGrouping
                  enableColumnDragging={false}
                  enableColumnFilterModes
                  enableRowActions
                  enableStickyHeader
                  isMultiSortEvent={(e) => {
                    if (e.ctrlKey || e.metaKey) {
                      return true;
                    }
                    return false;
                  }}
                  initialState={{
                    sorting: [{ id: 'start_timestamp', asec: true }],
                    showGlobalFilter: false,
                    columnOrder: navState.canUploadBaDates
                      ? ['mrt-row-select', 'edit', 'start_timestamp', 'end_timestamp', 'muta', 'is_mandatory', 'rst_authorized', 'training_events', 'created_by']
                      : ['start_timestamp', 'end_timestamp', 'muta', 'is_mandatory', 'rst_authorized', 'training_events', 'created_by']
                  }}
                  muiTableBodyProps={{
                    sx: (theme) => ({
                      '& tr:nth-of-type(n)': {
                        backgroundColor: colors.table_background_color,
                      },
                      '& tr:nth-of-type(2n-1)': {
                        backgroundColor: darken(theme.palette.background.default, 0.075),
                      },
                      '& tr.Mui-selected': {
                        backgroundColor: colors.table_selected,
                      },
                      '& tr:hover:not(.Mui-selected) > td': {
                        backgroundColor: colors.table_hover,
                      },
                    }),
                  }}

                  // Table State Management for updater function
                  onColumnVisibilityChange={(updater) => {
                    setColumnVisibility((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}


                  onRowSelectionChange={(updater) => {
                    setRowSelection((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onPaginationChange={(updater) => {
                    setPagination((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onShowColumnFiltersChange={(updater) => {
                    setShowColumnFilters((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onShowGlobalFilterChange={(updater) => {
                    setShowGlobalFilter((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  state={{
                    columnVisibility,
                    density,
                    rowSelection,
                    pagination,
                    showColumnFilters,
                    showGlobalFilter,
                  }}
                  renderToolbarInternalActions={({ table }) => (
                    <>
                      <Box>
                        <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />

                        {hasEditPermission ? (
                          <>
                            <Tooltip arrow title="Import/Export Data">
                              <IconButton onClick={fileOpenMenu}>
                                <ImportExportIcon />
                              </IconButton>
                            </Tooltip>
                            <StyledMenu anchorEl={anchorEl} open={open} onClose={fileCloseMenu}>
                              <a
                                id="myAnchor"
                                style={{ textDecoration: "inherit", color: "inherit" }}
                                href={BA_Upload_Template}
                                download="BA_Upload_Template"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <MenuItem disableRipple>
                                  <ArchiveIcon /> BA Dates Template
                                </MenuItem>
                              </a>
                              <MenuItem onClick={() => handleExportData()} disableRipple>
                                <DownloadIcon /> Export BA Dates Table
                              </MenuItem>
                              <MenuItem onClick={() => uploadBACSVFile(fileCloseMenu)} disableRipple>
                                <UploadIcon /> Upload BA Dates Schedule
                              </MenuItem>
                            </StyledMenu>
                          </>
                        ) : (
                          <Tooltip arrow title="Export Data">
                            <IconButton onClick={handleExportData}>
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                        <MRT_FullScreenToggleButton table={tableInstanceRef.current} />

                        {showAddRow && hasEditPermission ? (
                          <Tooltip arrow title="Add BA Date">
                            <IconButton onClick={() => {
                              setShowAddRow(false);
                              navigation.navigate('units_dashboard', { screen: 'add_BA_dates' });
                            }
                            }
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}

                      </Box>
                    </>
                  )}
                  renderTopToolbarCustomActions={hasEditPermission ? ({ table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                      <Button
                        disabled={table.getSelectedRowModel().rows.length === 0}
                        onClick={() => {
                          const RowsToDelete = table.getSelectedRowModel().rows.map((row) => row.original);
                          // Get all names to delete
                          const datestoDelete = [];
                          RowsToDelete.forEach((row) => {
                            datestoDelete.push(formatTableDate(row.start_timestamp) + ' to ' + formatTableDate(row.end_timestamp));
                          });

                          setBADatesToDelete(datestoDelete);
                          setRowsToDelete(RowsToDelete)
                          setDeleteModalVis(true)
                          table.setRowSelection([]);
                        }}
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        style={{
                          color: table.getSelectedRowModel().rows.length === 0 ? colors.new_table_color : colors.new_table_color_selected,
                          backgroundColor: table.getSelectedRowModel().rows.length === 0 ? colors.new_table_bg_color : colors.new_table_bg_color_selected,
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  ) : () => null}
                />
              </ThemeProvider>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>

  );
};
