import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Button, Searchbar } from 'react-native-paper';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Modal,
  FlatList,
  SafeAreaView,
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useScale } from '../components/scale';

import { AntDesign } from '@expo/vector-icons';
import NavSideBar from '../components/NavSideBar';
import moment from 'moment';
import { useColorContext } from '../components/ColorContext';
import { CounterContext } from '../components/CounterContext';

import { get_recommend_files, get_review_files } from '../api_handler/files';
import { generatePDF } from "./generatePDF.js";
import {
  createTheme,
  styled,
  useTheme,
  ThemeProvider,
  autocompleteClasses,
} from '@mui/material/styles';
import { Pagination, Box } from '@mui/material';
import { getFileDate, formatRstDisplayDate, formatDateToMilDate } from '../components/FileDataFunctions';
import Scrollbars from "react-custom-scrollbars";
import { useNavBarContext } from '../components/NavBarContext';
import { IoFilter, IoFilterOutline } from "react-icons/io5";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Dimensions } from 'react-native';
import { useSnackbar } from '../components/SnackbarContext.js';
import * as Progress from 'react-native-progress'

function AwaitingAction({ navigation }) {
  const { colors } = useColorContext();
  const { navState } = useNavBarContext();
  const { actionFiles, updateActionFiles } = useContext(CounterContext);
  const { showSnackbar } = useSnackbar();
  const scale = useScale();
  const { width, height } = Dimensions.get('window');
  const styles = ({
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    header: {
      marginTop: '0%',
      height: 110,
      backgroundColor: colors.screen_background,
    },
    main: {
      flex: 10,
      height: "100%",
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    description: {
      fontSize: 22,
      textAlign: "Left",
      paddingTop: 25,
      color: colors.bow,
      width: "50%",
      fontFamily: "Trebuchet MS",
      marginBottom: "2%",
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: "100%",
      width: "75%",
      marginTop: "2%",
      borderRadius: 10,
      justifyContent: "center",
      paddingHorizontal: "2.5%",
      paddingBottom: "2.5%",
      paddingTop: "1%",
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    button: {
      height: 120,
      borderRadius: 10,
      padding: 30,
      paddingLeft: 40,
      paddingRight: 40,
      backgroundColor: colors.wob,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: "row",
      justifyContent: "center",
      flex: 1,
    },
    textStyle: {
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    backButton: {
      height: 40,
      width: 160,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginTop: '5%',
      backgroundColor: colors.button,
    },
    searchBar: {
      width: width < 1242 ? (width < 885 ? "100%" : "90%") : "75%",
      fontSize: 15,
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginLeft: width < 1242 ? (width < 885 ? "0%" : "10%") : "25%",
      backgroundColor: colors.modal_background,
      color: colors.text,
    },
    selected: {
      backgroundColor: colors.gold,
    },
    doc: {
      width: 50,
      height: 50,
    },
    tab: {
      backgroundColor: colors.content_box,
      paddingVertical: 8,
      paddingHorizontal: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    selectedTab: {
      backgroundColor: colors.screen_background,
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      paddingVertical: 8,
      paddingHorizontal: 20,
      justifyContent: 'center',
      alignItems: 'center',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    tabsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: '0%',
      marginBottom: '2.5%',
      marginLeft: 5,
    },
    checkbox: {
      backgroundColor: colors.modal_background,
      color: colors.gold_button_text,
      fontFamily: "Trebuchet MS",
      borderRadius: 10,
      justifyContent: "center",
      padding: "2.5%",
      width: "95%",
      borderColor: colors.border_color,
      borderRadius: "5px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"
    },
    formControlLabel: {
      marginLeft: width < 1373 ? (width < 840 ? "0%" : "5%") : "10%",
      fontFamily: "Trebuchet MS",
      color: colors.gold_button_text,
    },
    formControLabelText: {
      fontFamily: "Trebuchet MS",
      fontSize: width < 1587 ? (width < 840 ? "x-small" : "small") : "medium",
    },
    filterButton: {
      width: "60%",
      fontFamily: "Trebuchet MS",
    },
    applyButton: {
      backgroundColor: colors.action_button,
      color: colors.gold_button_text,
      marginRight: width < 1440 ? "0%" : "5%",
      fontFamily: "Trebuchet MS",
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    clearButton: {
      backgroundColor: colors.modal_background,
      color: colors.gold_button_text,
      marginLeft: width < 1440 ? "0%" : "5%",
      fontFamily: "Trebuchet MS",
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
  });
  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [allFiles, setAllFiles] = useState({ pending: [], approved: [] });
  const [awaitingActionFiles, setAwaitingActionFiles] = useState([])
  const [actionedFiles, setActionedFiles] = useState([]);
  const [searchedFiles, setSearchedFiles] = useState([]);
  const [search, setSearch] = useState('');
  const [numFiles, setNumFiles] = useState('');
  const [currentTab, setCurrentTab] = useState('pending');
  const [currentPageContent, setCurrentPageContent] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState({});
  const defaultFilters = [
    { id: 'Date Ascending', isChecked: false },
    { id: 'Date Descending', isChecked: false },
    { id: 'Form ID Ascending', isChecked: false },
    { id: 'Form ID Descending', isChecked: false },
    { id: 'Review', isChecked: false },
    { id: 'Recommend', isChecked: false },
    { id: 'Submitted', isChecked: false },
    { id: 'Recommended', isChecked: false },
    { id: 'Approved', isChecked: false },
    { id: 'Denied', isChecked: false },
    { id: 'Paid', isChecked: false },
    { id: 'Awaiting Review', isChecked: false },
    { id: 'Must Resubmit', isChecked: false },
    { id: 'dental', isChecked: false, isForm: true },
    { id: 'RST', isChecked: false, isForm: true },
    { id: '1380', isChecked: false, isForm: true },
    { id: '1380 aftp', isChecked: false, isForm: true },
    { id: 'ATA', isChecked: false, isForm: true },
    { id: 'RMA', isChecked: false, isForm: true },
    { id: 'EBDL', isChecked: false, isForm: true },
    { id: 'MDR', isChecked: false, isForm: true },
    { id: 'SOU', isChecked: false, isForm: true },
    { id: 'LIK', isChecked: false, isForm: true },
  ]

  const [modalFilters, setModalFilters] = useState(defaultFilters);
  const tempAllFiles = []

  const toggleListItem = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index] // Toggle the state of the list item
    }));
  };

  const toggleChecked = (id) => {
    setModalFilters(prevData => {
      return prevData.map(entry => {
        if (entry.id === id) {

          return { ...entry, isChecked: !entry.isChecked };
        }
        return entry;
      });
    });

  };

  const handleExclusiveCheck = (id) => {
    setModalFilters((prevFilters) => {
      const isChecked = prevFilters.find((filter) => filter.id === id)?.isChecked;

      return prevFilters.map((filter) => {
        if (
          filter.id === 'Date Ascending' ||
          filter.id === 'Date Descending' ||
          filter.id === 'Form ID Ascending' ||
          filter.id === 'Form ID Descending'
        ) {
          if (filter.id === id) {
            return {
              ...filter,
              isChecked: !isChecked,
            };
          } else {
            return {
              ...filter,
              isChecked: false,
            };
          }
        }
        return filter;
      });
    });
  };

  const checkItem = (id) => {
    return modalFilters.some(filter => filter.id === id && filter.isChecked)
  }

  const theme = useTheme();
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  // For pagination
  const ITEM_LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // file filter menu 
  const [menuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateSubmenuVisible, setDateSubmenuVisible] = useState(false)
  const [statusSubmenuVisible, setStatusSubmenuVisible] = useState(false)
  const [fileTypeSubmenuVisible, setFileTypeSubmenuVisible] = useState(false)

  async function getFiles() {
    setFiles([]);

    const reviewFiles = await get_review_files({
      role: global.user_data.role,
      user_id: global.user_data.user_id,
      permissions: global.user_data.permissions,
    },
      global.token
    );

    const recFiles = await get_recommend_files({
      user_id: global.user_data.user_id,
      permissions: global.user_data.permissions,
    },
      global.token
    );

    if (reviewFiles.status !== 200 && recFiles.status !== 200) {
      showSnackbar("error", "Application Error: Failed to action files");
      navigation.navigate("Home");
      return;
    }

    let filteredReviewFiles = [];
    let approvedReviewFiles = [];
    if (reviewFiles.status === 200) {
      let accountId = global.user_data.user_id;
      filteredReviewFiles = reviewFiles.data.body.filter(file => {
        if (file.status >= 4) return false;
      
        if (file.form_type === '1380'  && file.form_id > 3593) {
          const isReviewer = file.reviewer_ids?.includes(accountId)
          const isValidator = file.validator_ids?.includes(accountId)

          const needsOfficerReview = file.reviewer_id === null
          const needsValidatorReview = file.validator_id === null

          if (isReviewer && needsOfficerReview) {
            return true;
          }

          if (isValidator && needsValidatorReview) {
            return true;
          }

          return false;
        }  else if (file.form_type === '1380') {
          return true;
        }
      
        return true;
      });

      filteredReviewFiles = filteredReviewFiles.map((file) => {
        if (file.reviewer_ids && file.validator_ids) {
          const isReviewer = file.reviewer_ids.includes(accountId);
          const isValidator = file.validator_ids.includes(accountId);

          if (isReviewer && isValidator) {
            return { ...file, sameReviewer: true };
          }
        }
        return file;
      });

      approvedReviewFiles = reviewFiles.data.body.filter(file => {
        if (file.form_type === 'dental') return false;

        if (file.form_type === '1380') {
          if (file.reviewer_id === accountId && !file.validator_ids?.includes(accountId)) return true;
          if (file.validator_id === accountId && !file.reviewer_ids?.includes(accountId)) return true;
        }
  
        if (file.status >= 4) return true;
        
        return false;
      });
    }

    let filteredRecFiles = [];
    if (recFiles.status === 200) {
      filteredRecFiles = recFiles.data.body.filter((file) => file.status < 4);
    }

    if (filteredReviewFiles.length === 0 && filteredRecFiles.length === 0 && approvedReviewFiles.length === 0) {
      showSnackbar("warning", "You have no files to review");
      goBack();
      return;
    }

    let pending = [
      ...filteredReviewFiles.map((file) => ({ ...file, parsedDate: getFileDate(file), indicator: 'review' })),
      ...filteredRecFiles
        .filter((file) => file.status === 1 && file.recommender_id !== file.reviewer_id)
        .map((file) => ({ ...file, parsedDate: getFileDate(file), indicator: 'recommend' })),
    ].sort((a, b) => b.form_id - a.form_id);

    let approved = [
      ...approvedReviewFiles.map((file) => ({ ...file, parsedDate: getFileDate(file), approved: true })),
      ...filteredRecFiles
        .filter((file) => file.status === 2 || file.status === 3)
        .map((file) => ({ ...file, parsedDate: getFileDate(file) })),
    ].sort((a, b) => b.form_id - a.form_id)

    setAllFiles({ pending, approved });
    setAwaitingActionFiles(pending)
    setActionedFiles(approved.filter((file) => file.reviewer_id === global.user_data.user_id || file.validator_id === global.user_data.user_id))
    updateActionFiles(pending.length);
    setCurrentPageContent(pending.slice(0, ITEM_LIMIT));
    setFiles(pending)
    setSearch('');
    setSelected(-1);
    setLoaded(true);
  }

  const getCommentForFormType = useCallback((form) => {
    switch (form.form_type) {
      case 'RST': return form.rst_approver_comment;
      case 'EBDL': return form.ebdl_approver_comment;
      case '1380': return form.approver_comment_1380;
      case '1380 aftp': return form.approver_comment_1380;
      case 'ATA': return form.ata_approver_comment;
      case 'RMA': return form.rma_approver_comment;
      case 'MDR': return form.mdr_approver_comment;
      default: return '';
    }
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      async function getPageInfo() {
        await getFiles();
      }

      getPageInfo();
    }, []),
  );

  function sendFileID(indicator, form_id, same_reviewer, form_type, attachments, creator_id) {
    const data = {
      fileID: form_id,
      same_reviewer: same_reviewer,
      form_type: form_type,
      attachments: attachments,
      creator_id: creator_id
    };

    // check if file exists and navigate to proper review screen
    if (form_id != -1) {
      if (indicator === 'recommend') {
        navigation.navigate('RecommendRST', data);
      } else {
        if (form_type === 'RST') {
          navigation.navigate('ReviewRST', data);
        }
        else if (form_type === '1380' || form_type === '1380 aftp') {
          navigation.navigate('Review1380', data);
        }
        else if (form_type === 'SOU') {
          navigation.navigate('ReviewSOU', data);
        } 
        else if (form_type === 'LIK') {
          navigation.navigate('ReviewLIK', data);
        }
        else {
          navigation.navigate('ReviewEBDL', data);
        }
      }

    } else {
      showSnackbar('error', 'Please select a file');
    }
  }

  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const updateSearch = (search) => {
    setSearch(search);
    if (search === "") {
      if (isFiltered) {
        setSearchResults(filteredFiles);
      } else {
        setSearchResults(currentTab === 'pending' ? awaitingActionFiles : actionedFiles)
      }
    } else {
      setCurrentPage(1);
      const searchFiles = isFiltered ? filteredFiles : currentTab === 'pending' ? awaitingActionFiles : actionedFiles;
      const searchResultsTab = searchFiles.filter((file) => {
        return (
          file.form_type.toLowerCase().includes(search.toLowerCase()) ||
          file.request_type.toLowerCase().includes(search.toLowerCase()) ||
          file.form_name.toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchResults(searchResultsTab);
    }
  };

  const getCurrentFiles = () => {
    const filesToShow = search !== '' ? searchResults : isFiltered ? filteredFiles : currentTab === 'pending' ? awaitingActionFiles : actionedFiles
    setSearchedFiles(filesToShow);

    const offset = (currentPage - 1) * ITEM_LIMIT;
    const paginatedFiles = filesToShow.slice(offset, offset + ITEM_LIMIT);
    setCurrentPageContent(paginatedFiles);
  };

  const goBack = () => {
    navigation.navigate('Home');
  };

  const handleTabSwitch = (tabName) => {
    setCurrentTab(tabName);
    setSearchedFiles(allFiles[tabName]);
    setCurrentPage(1);
    setSearch('');
    clearFilter();
  };

  function select(id) {
    setSelected(id);
  };

  const handlePage = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getCurrentFiles();
  }, [currentPage, currentTab, search, allFiles, filteredFiles, isFiltered]);

  const updateFilter = () => {
    setSearch('');
    let filteredTab = [...(currentTab === 'pending' ? awaitingActionFiles : actionedFiles)];

    const dateFilter = modalFilters.find(
      (item) =>
        (item.id === 'Date Ascending' || item.id === 'Date Descending') &&
        item.isChecked
    );
    const formIDFilter = modalFilters.find(
      (item) =>
        (item.id === 'Form ID Ascending' || item.id === 'Form ID Descending') &&
        item.isChecked
    );

    if (dateFilter) {
      filteredTab = filteredTab
        .map((file) => ({
          ...file,
          parsedDate: getFileDate(file),
        }))
        .sort((a, b) =>
          dateFilter.id === 'Date Ascending'
            ? a.parsedDate - b.parsedDate
            : b.parsedDate - a.parsedDate
        );
    }

    if (formIDFilter) {
      filteredTab = filteredTab.sort((a, b) =>
        formIDFilter.id === 'Form ID Ascending'
          ? a.form_id - b.form_id
          : b.form_id - a.form_id
      );
    }

    const statusFilters = modalFilters.filter(
      (item) =>
        [
          'Submitted',
          'Recommended',
          'Awaiting Review',
          'Must Resubmit',
          'Approved',
          'Denied',
          'Paid',
        ].includes(item.id) && item.isChecked
    );

    if (statusFilters.length > 0) {
      filteredTab = filteredTab.filter((file) =>
        statusFilters.some((filter) => {
          switch (filter.id) {
            case 'Submitted':
              return file.status === 1;
            case 'Recommended':
              return file.status === 2 || file.status === 3;
            case 'Awaiting Review':
              return file.status === 2;
            case 'Must Resubmit':
              return file.status === 3;
            case 'Approved':
              return file.status === 4;
            case 'Denied':
              return file.status === 5;
            case 'Paid':
              return file.status === 6;
            default:
              return false;
          }
        })
      );
    }

    const actionFilters = modalFilters.filter(
      (item) =>
        [
          'Review',
          'Recommend',
        ].includes(item.id) && item.isChecked
    );

    if (actionFilters.length > 0) {
      filteredTab = filteredTab.filter((file) =>
        actionFilters.some((filter) => {
          switch (filter.id) {
            case 'Review':
              return file.indicator === 'review';
            case 'Recommend':
              return file.indicator === 'recommend';
            default:
              return false;
          }
        })
      );
    }


    const formTypeFilters = modalFilters.filter(
      (item) => item.isForm && item.isChecked
    );

    if (formTypeFilters.length > 0) {
      filteredTab = filteredTab.filter((file) =>
        formTypeFilters.some((filter) => file.form_type === filter.id)
      );
    }

    setCurrentPage(1);
    setFilteredFiles(filteredTab);
    setIsFiltered(true);
    setSearchResults(filteredTab);
    setCurrentPageContent(filteredTab.slice(0, ITEM_LIMIT));
  }

  const clearFilter = () => {
    setModalFilters(defaultFilters);
    setSearchResults(currentTab === 'pending' ? awaitingActionFiles : actionedFiles)
    setFilteredFiles([]);
    setIsFiltered(false);
    setCurrentPageContent(currentTab === 'pending' ? awaitingActionFiles.slice(0, ITEM_LIMIT) : actionedFiles.slice(0, ITEM_LIMIT))
    setCurrentPage(1);
    setSearch('');
  };

  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={reviewTheme}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}
        {!navState.canSeeReview && actionFiles === 0 ? (
          <View style={styles.main}>
            <View style={styles.titleDiv}>
              <Text style={styles.title}>Access Denied: User does not have permission to view this page.</Text>
            </View>
          </View>
        ) : (
          // starts here
          <Scrollbars style={styles.main}>
            <View style={{ flexDirection: 'column', }}>
              <Text style={styles.pageTitle}>Awaiting Your Action</Text>
              <View
                style={{
                  flexDirection: "row",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                }}
              >
                <Text style={{ ...styles.description }}>
                  There are {actionFiles} files awaiting your review
                </Text>

                <View
                  style={{
                    flex: 2,
                    marginRight: "2.5%",
                    paddingTop: "20px"
                  }}
                >
                  <Searchbar
                    style={styles.searchBar}
                    placeholder="Search by Requestor Name"
                    placeholderTextColor={colors.bow}
                    iconColor={colors.bow}
                    inputStyle={{ color: colors.bow }}
                    onChangeText={updateSearch}
                    value={search}
                    onClear={updateSearch}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <View style={styles.contentBox}>
                <View style={styles.tabsContainer}>
                  <TouchableOpacity
                    onPress={() => handleTabSwitch('pending')}
                    style={[styles.tab,
                    currentTab === 'pending' && styles.selectedTab,
                    ]}>
                    <Text style={styles.textStyle}>Action Items</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => handleTabSwitch('approved')}
                    style={[styles.tab,
                    currentTab === 'approved' && styles.selectedTab,
                    ]}>
                    <Text style={styles.textStyle}>Actioned Items</Text>
                  </TouchableOpacity>
                </View>
                {currentPageContent.map((file) => (
                  <View>
                    <FileSelect
                      key={file.form_id}
                      id={file.form_id}
                      comment={getCommentForFormType(file)}
                      name={file.form_name}
                      date={file.timestamp}
                      excusedAbsenceBADate={file.excused_absence_ba_date}
                      rstPerformedOn={file.rst_performed_on}
                      rmaDate={file.rma_date}
                      ataDate={file.ata_date}
                      selected={selected}
                      status={file.status}
                      style={styles.item}
                      filename={file.form_name}
                      filetype={file.form_type}
                      navigation={navigation}
                      requestType={file.request_type}
                      timestamp_msg={file.timestamp_string}
                      attachments={file.attachments}
                      date_9a={file.date_9a}
                      ata_dates={file.ata_dates}
                      rma_dates={file.rma_dates}
                      ebdl_dates={file.ebdl_dates}
                      mdr_dates={file.mdr_dates}
                      creator_id={file.creator_id}
                      indicator={file.indicator}
                      sameReviewer={file?.sameReviewer ?? false}
                      approved={file?.approved ?? false}
                    />
                  </View>
                ))}
              </View>
              <View
                style={{
                  flexDirection: "column",
                  flex: 2,
                  marginLeft: "2.5%",
                  marginRight: "2.5%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <List style={styles.checkbox}>
                  <ListItemButton onClick={() => toggleListItem("Date Submitted")}>
                    <ListItemText
                      primary="Date Submitted"
                      primaryTypographyProps={{
                        fontFamily: "Trebuchet MS",
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen["Date Submitted"] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen["Date Submitted"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Date Ascending")}
                              onChange={() =>
                                handleExclusiveCheck("Date Ascending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Date Ascending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Date Descending")}
                              onChange={() =>
                                handleExclusiveCheck("Date Descending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Date Descending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => toggleListItem("form_id")}>
                    <ListItemText
                      primary="Form ID"
                      primaryTypographyProps={{
                        fontFamily: "Trebuchet MS",
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen["form_id"] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen["form_id"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Form ID Ascending")}
                              onChange={() =>
                                handleExclusiveCheck("Form ID Ascending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Form ID Ascending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Form ID Descending")}
                              onChange={() =>
                                handleExclusiveCheck("Form ID Descending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Form ID Descending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                  {currentTab !== 'pending' ? (
                    <>
                      <ListItemButton onClick={() => toggleListItem("status")}>
                        <ListItemText
                          primary="Status"
                          primaryTypographyProps={{
                            fontFamily: "Trebuchet MS",
                            fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                          }}
                        />
                        {isOpen["status"] ? (<ExpandLess />) : (<ExpandMore />)}
                      </ListItemButton>
                      <Collapse
                        in={isOpen["status"]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkItem("Recommended")}
                                  onChange={() =>
                                    toggleChecked("Recommended")
                                  }
                                />
                              }
                              label={
                                <Typography style={styles.formControLabelText}>
                                  Recommended
                                </Typography>
                              }
                              style={styles.formControlLabel}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkItem("Approved")}
                                  onChange={() =>
                                    toggleChecked("Approved")
                                  }
                                />
                              }
                              label={
                                <Typography style={styles.formControLabelText}>
                                  Approved
                                </Typography>
                              }
                              style={styles.formControlLabel}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkItem("Denied")}
                                  onChange={() =>
                                    toggleChecked("Denied")
                                  }
                                />
                              }
                              label={
                                <Typography style={styles.formControLabelText}>
                                  Denied
                                </Typography>
                              }
                              style={styles.formControlLabel}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkItem("Paid")}
                                  onChange={() =>
                                    toggleChecked("Paid")
                                  }
                                />
                              }
                              label={
                                <Typography style={styles.formControLabelText}>
                                  Paid
                                </Typography>
                              }
                              style={styles.formControlLabel}
                            />
                          </FormGroup>
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <>
                      <ListItemButton onClick={() => toggleListItem("action_item")}>
                        <ListItemText
                          primary="Action Item"
                          primaryTypographyProps={{
                            fontFamily: "Trebuchet MS",
                            fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                          }}
                        />
                        {isOpen["action_item"] ? (<ExpandLess />) : (<ExpandMore />)}
                      </ListItemButton>
                      <Collapse
                        in={isOpen["action_item"]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkItem("Review")}
                                  onChange={() =>
                                    toggleChecked("Review")
                                  }
                                />
                              }
                              label={
                                <Typography style={styles.formControLabelText}>
                                  Review
                                </Typography>
                              }
                              style={styles.formControlLabel}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkItem("Recommend")}
                                  onChange={() =>
                                    toggleChecked("Recommend")
                                  }
                                />
                              }
                              label={
                                <Typography style={styles.formControLabelText}>
                                  Recommend
                                </Typography>
                              }
                              style={styles.formControlLabel}
                            />
                          </FormGroup>
                        </List>
                      </Collapse>
                    </>
                  )}

                  <ListItemButton onClick={() => toggleListItem("form_type")}>
                    <ListItemText
                      primary="Form Type"
                      primaryTypographyProps={{
                        fontFamily: "Trebuchet MS",
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen["form_type"] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen["form_type"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("dental")}
                              onChange={() =>
                                toggleChecked("dental")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Dental
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("RST")}
                              onChange={() =>
                                toggleChecked("RST")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              RST
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("1380")}
                              onChange={() =>
                                toggleChecked("1380")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              1380
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("1380 aftp")}
                              onChange={() =>
                                toggleChecked("1380 aftp")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              1380 AFTP
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("ATA")}
                              onChange={() =>
                                toggleChecked("ATA")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              ATA
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("RMA")}
                              onChange={() =>
                                toggleChecked("RMA")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              RMA
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("EBDL")}
                              onChange={() =>
                                toggleChecked("EBDL")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              EBDL
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("MDR")}
                              onChange={() =>
                                toggleChecked("MDR")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              MDR
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("SOU")}
                              onChange={() =>
                                toggleChecked("SOU")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              SOU
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("LIK")}
                              onChange={() =>
                                toggleChecked("LIK")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              LIK
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                </List>
                <View
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "5%",
                    marginBottom: "5%",
                    justifyContent: "center",
                    flexWrap: width < 1440 ? "wrap" : "nowrap",
                  }}
                >
                  <Button
                    color={colors.gold_button_text}
                    style={[styles.filterButton, styles.applyButton]}
                    onPress={updateFilter}
                    titleStyle
                  >
                    <Text style={{ fontSize: width < 972 ? 9 : 15 }}>
                      Apply
                    </Text>
                  </Button>
                  <Button
                    color={colors.gold_button_text}
                    style={[styles.filterButton, styles.clearButton]}
                    onPress={clearFilter}
                  >
                    <Text style={{ fontSize: width < 972 ? 9 : 15 }}>
                      Clear
                    </Text>
                  </Button>
                </View>
              </View>
            </View>

            <Box
              display="flex"
              justifyContent="center"
              marginTop={2}
              paddingRight="22.5%"
            >
              <Pagination
                count={Math.ceil(
                  search
                    ? searchResults.length / ITEM_LIMIT
                    : isFiltered
                      ? filteredFiles.length / ITEM_LIMIT
                      : currentTab === 'pending'
                        ? allFiles.pending.length / ITEM_LIMIT
                        : allFiles.approved.length / ITEM_LIMIT
                )}
                shape="rounded"
                variant='outlined'
                page={currentPage}
                onChange={handlePage}
                color="primary"
              />
            </Box>
          </Scrollbars>
        )}
      </View>
    </ThemeProvider>
  );

  function FileSelect({
    id,
    filetype,
    filename,
    requestType,
    date,
    comment,
    timestamp_msg,
    status,
    navigation,
    attachements,
    timestamp,
    attachments,
    excusedAbsenceBADate,
    rstPerformedOn,
    rmaDate,
    ataDate,
    date_9a,
    creator_id,
    ata_dates,
    rma_dates,
    ebdl_dates,
    mdr_dates,
    indicator,
    sameReviewer,
    approved,
  }) {
    const doc = require('../assets/doc_yellow.png');

    const docs = {
      0: require("../assets/doc_blue.png"),
      1: require("../assets/doc_yellow.png"),
      2: require("../assets/approved_rec_doc.png"),
      3: require("../assets/disapproved_rec_doc.png"),
      4: require("../assets/doc_green.png"),
      5: require("../assets/doc_red.png"),
      6: require("../assets/doc_paid.png"),
      7: require("../assets/corrupted_doc.png"),
      8: require("../assets/recommend_doc.png"),
    };

    const status_names = {
      0: "\nStatus: Drafted ",
      1: "\nStatus: Submitted ",
      2: "\nStatus: Awaiting Review ",
      3: "\nStatus: Must Resubmit ",
      4: "\nStatus: Approved ",
      5: "\nStatus: Denied ",
      6: "\nStatus: Paid ",
      7: "\nFile Corrupted as of ",
      8: "\nStatus: Awaiting Recommendation "
    };

    const statusDate = formatDateToMilDate(new Date(date[0]).toLocaleDateString());
    // Decide which date to use
    let displayDate;
    if (filetype.includes("RST")) {
      if (excusedAbsenceBADate === '' && rmaDate === '' && ataDate === '') {
        const dates = rstPerformedOn.split(', ').map((dateStr) => {
          // Extract the date part "DDMMMYY"
          const match = dateStr.match(/\d{2}[A-Z]{3}\d{2,4}/);
          if (match) {
            const datePart = match[0];
            if (dateStr.length === 7) {
              dateStr = dateStr.slice(0, 5) + 20 + dateStr.slice(5);
            }
            // Pass the date part to formatRstDisplayDate and convert to moment object
            const formattedDate = moment(formatRstDisplayDate(datePart), 'DDMMMYYYY').format('DDMMMYYYY').toUpperCase();
            // Reconstruct the date string with the formatted date and quantity
            return formattedDate;
          } else {
            // If the date format is not matched, return the original date string
            return dateStr;
          }
        });
        displayDate = dates.join(', ');
      } else {
        displayDate = moment(formatRstDisplayDate(excusedAbsenceBADate || rmaDate || ataDate), 'DDMMMYYYY').format('DDMMMYYYY').toUpperCase();
      }
    } else {
      displayDate = moment(new Date(date[date.length - 1]))
        .format("DDMMMYYYY")
        .toUpperCase();
    }

    if (filetype.includes("1380")) {
      if (date_9a.length > 0) {
			// split the received dates by commas
			const dates = date_9a.map((dateStr) => dateStr.trim().split(","));
			let formattedDates = [];

			for (let i = 0; i < dates.length; i++) {
				// add formatted dates to array
				formattedDates.push(
					moment(new Date(dates[i])).format("DDMMMYYYY").toUpperCase()
				);
			}

			// set displayDates to the joining of formattedDates with commas
			displayDate = formattedDates.join(", ");
      } else {
        displayDate = "";
		  }
    }

    if (filetype.includes("ATA")) {
      displayDate = ata_dates;
    }

    if (filetype.includes("RMA")) {
      displayDate = rma_dates;
    }

    if (filetype.includes("EBDL")) {
      displayDate = ebdl_dates;
    }

    if (filetype.includes("MDR")) {
      displayDate = mdr_dates;
    }

    const getImageStatus = (indicator, displayDate, status) => {
      if (indicator === 'recommend') {
        return 8;
      } else if (displayDate === "Invalid Date") {
        return 7;
      } else {
        return status;
      }
    };

    return (
      <View>
        <TouchableOpacity
          style={[
            styles.button,
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            },
          ]}
          onPress={() => {
            if (status < 4 && !approved) {
              sendFileID(indicator, id, sameReviewer, filetype, attachments, creator_id);
            }
            else {
              setLoaded(false);
              generatePDF(
                id,
                filetype,
                navigation,
                false,
                false,
                attachments,
                creator_id
              );
              setLoaded(true);
            }
          }}
        >
          <Image
            style={styles.doc}
            defaultSource={docs[getImageStatus(indicator, displayDate, status)]}
          />
          <Text style={styles.textStyle}>
            {filename +
              " (" +
              id +
              ")" +
              "\n" +
              requestType +
              "\n Date(s): " +
              displayDate +
              status_names[getImageStatus(indicator, displayDate, status)] +
              statusDate +
              "\n" +
              ((comment && comment !== undefined && comment !== "") ? "Comment: " + comment : "")}
          </Text>
          <AntDesign name="right" size={14} color={colors.bow} />
        </TouchableOpacity>

      </View>
    );
  }
};

export default AwaitingAction;
