import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "../../components/SnackbarContext";
import * as Progress from "react-native-progress";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { CheckBox } from "react-native-web";
import { TextInput } from "react-native-paper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import { en, registerTranslation } from "react-native-paper-dates";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useScale } from "../../components/scale";
import { useColorContext } from "../../components/ColorContext";
import { pdfIn } from "../generatePDF.js";
import { PDFDocument, rgb } from "pdf-lib";
import Scrollbars from "react-custom-scrollbars";
import * as LIKForm from "../../PDFs/LIK_form_raw";
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import { create_new_user_signature } from "../../api_handler/users.js";
import { storeJsonData } from "../../api_handler/asyncstorage.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PropTypes from "prop-types";
import { create_notification } from "../../api_handler/notifications";
import {
  upload_file,
  update_file,
  get_all_user_files,
  get_single_file,
  put_image_s3
} from "../../api_handler/files";
import {
  get_all_users_in_single_unit,
} from "../../api_handler/units";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useRoute } from "@react-navigation/native";
registerTranslation("en", en);

function CreateNewLIK({ navigation }) {
  const { colors } = useColorContext();
  const scale = useScale();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const viewRef = useRef();
  const route = useRoute();
  const [amendFile, setAmendFile] = useState(false);
  const { draftFormID = "NONE", updatingSubmission } = route.params || {};

  const styles = {
    container: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      backgroundColor: colors.screen_background,
    },
    scrollView: {
      height: "100%",
      width: "100%",
      marginTop: "2%",
    },
    mainWrapper: {
      flexDirection: "column",
      alignSelf: "center",
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: "Trebuchet MS",
      flex: 1,
      color: colors.text,
      paddingLeft: "5%",
      paddingTop: 25,
    },
    commentInput: {
      minHeight: "fit-content",
      borderWidth: 1,
      borderRadius: 5,
      borderColor: colors.border_color,
      backgroundColor: colors.textfield_background,
      width: 500,
    },
  };

  const loadingStyles = {
    animation: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  const requestTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [hasSignature, setHasSignature] = useState(false);
  const [boolQs, setBoolQs] = useState(Array(boolQuestions.length).fill(false));
  const [lodgingDate, setLodgingDate] = useState(null);
  const [homeDistance, setHomeDistance] = useState("");
  const [homeChecked, setHomeChecked] = useState(false);
  const [approver, setApprover] = useState(null);
  const [officers, setOfficers] = useState([]);
  
  useEffect(() => {
    // Check if user has a signature
    setHasSignature(global.signature_uri != null && global.signature_uri != "");
    fetchOfficers();
  }, []);

  async function fetchOfficers() {
    const SOUOfficers = await get_all_users_in_single_unit(
      { unit_id: global.user_data.unit_id, permission: 30 },
      global.token
    );

    if (SOUOfficers.status === 200) {
      setOfficers(
        SOUOfficers.data.body.filter(
          (user) => user.user_id != global.user_data.user_id
        )
      );
    } else {
      showSnackbar("error", "Failed to fetch SOU officers");
    }
  }

  useEffect(() => {
    if (checkProcessedVariables()) {
      loadDraftForm();
    }
  }, [draftFormID, officers]);

  const checkProcessedVariables = () => {
    return (
        draftFormID != "NONE" &&
        draftFormID !== undefined
    );
  };

  async function loadDraftForm() {
    setIsLoading(true);
    const formIDandType = { form_id: draftFormID, form_type: "LIK" };
    try {
      // Check all the checkmarks
      setBoolQs(Array(boolQuestions.length).fill(true));
      setHomeChecked(true);
      
      // Set Date of Lodging
      const fileData = await get_single_file(formIDandType, global.token);
      const newLodgingDate = dayjs(new Date(fileData.body.lik_ba_date));
      setLodgingDate(newLodgingDate);

      // Find approver
      const approverId = fileData.body.reviewer_ids[0];
      const newApprover = officers.find((o) => o.user_id == approverId);
      if (newApprover) setApprover(newApprover);

      // Set distance
      setHomeDistance(fileData.body.distance);

      setAmendFile(true);
      console.log(fileData);
    } catch (error) {
        console.error("Error loading draft form:", error);
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={requestTheme}>
      <View style={[styles.container, { cursor: isLoading ? "wait" : "auto" }]}>
        <Scrollbars style={styles.scrollView}>
          <View style={styles.mainWrapper}>
            <Text style={styles.pageTitle}>New LIK</Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap", // Allows the content to wrap when there is overflow
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "2%",
                paddingBottom: "7%",
                gap: "5%",
              }}
            >
              {/* ----------------------- Left Column --------------------------- */}
              <View
                style={{
                  flexDirection: "column",
                  maxWidth: 1000,
                  minWidth: 300,
                  marginBottom: "5%",
                  marginRight: "2%",
                }}
              >
                {/* Lodging Date Picker */}
                <View
                  style={{
                    maxWidth: 400,
                    minWidth: 300,
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: colors.bow, // Label color when unfocused
                        },
                        "& label.Mui-focused": {
                          color: colors.bow, // Label color when focused
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: colors.bow, // Bottom border color when focused
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: colors.bow, // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: colors.bow, // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: colors.bow, // Border color when focused
                          },
                        },
                        "& .MuiPickersDay-daySelected": {
                          backgroundColor: colors.bow, // Selected day background color
                        },
                        "& .MuiIconButton-root": {
                          color: colors.bow, // Icon button color
                        },
                      }}
                      label="Date of Lodging"
                      value={lodgingDate}
                      onChange={(d) => setLodgingDate(d)}
                      inputFormat="MM/DD/YYYY"
                      renderInput={(params) => (
                        <TextField {...params} placeholder="MM/DD/YYYY" />
                      )}
                    />
                  </LocalizationProvider>
                </View>

                {/* Home Distance */}
                <TextInput
                  style={{
                    marginBottom: "4%",
                    marginTop: "2%",
                    maxWidth: 400,
                    minWidth: 300,
                    minHeight: "fit-content",
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: colors.border_color,
                    backgroundColor: colors.textfield_background,
                  }}
                  theme={{
                    colors: {
                      text: colors.inputted_text,
                      accent: colors.textfield_background,
                      primary: colors.inputted_text,
                      placeholder: colors.placeholder_text,
                    },
                  }}
                  selectionColor="black"
                  label="Residence Distance from Unit (miles)"
                  activeOutlineColor="black"
                  value={homeDistance}
                  onChangeText={(data) => {
                    setHomeDistance(data)
                  }}
                />

                {/* Boolean Questions Table */}
                <Text
                  style={{
                    fontWeight: 550,
                    fontSize: 18,
                    marginTop: "2%",
                    marginBottom: "2%",
                    color: colors.bow,
                  }}
                >
                  Statement of Understanding:
                </Text>
                <View
                  style={{
                    flexDirection: "column",
                    maxWidth: 900,
                    minWidth: 800,
                    height: "fit-content",
                    borderColor: colors.border_color,
                    borderWidth: 1,
                    borderRadius: 5,
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.25,
                    shadowRadius: 4,
                    backgroundColor: colors.modal_background,
                    overflow: "hidden",
                  }}
                >
                  <Table>
                    <TableBody>
                      <BoolRow
                        label={`My home or record and / or residence is ${
                          homeDistance == "" ? "0" : homeDistance
                        } miles from my unit. This is outside the LIK program's normal commuting distance of 50 miles to the unit.`}
                        onChange={() => setHomeChecked(!homeChecked)}
                        checked={homeChecked}
                        colors={colors}
                      />
                      {boolQuestions.map((q, i) => (
                        <BoolRow
                          key={i}
                          label={q}
                          onChange={() => {
                            const newBoolQs = [...boolQs];
                            newBoolQs[i] = !newBoolQs[i];
                            setBoolQs(newBoolQs);
                          }}
                          checked={boolQs[i]}
                          colors={colors}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </View>
              </View>

              {/* ----------------------- Right Column --------------------------- */}
              <View
                style={{
                  minWidth: 300,
                  flexDirection: "column",
                }}
              >
                <View style={{ gap: 30 }}>
                  <ApproverDropdown />
                  <SignatureArea
                    hasSigned={hasSigned}
                    hasSignature={hasSignature}
                    signatureName={signatureName}
                    setSignatureName={setSignatureName}
                    styles={styles}
                    colors={colors}
                    Button={Button}
                    addSignature={addSignature}
                    sign={sign}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      gap: "2%",
                    }}
                  >
                    <Button
                      onClick={viewBlankLIKForm}
                      label="View Blank"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                    <Button
                      onClick={() => {
                        createLIKForm(
                          lodgingDate,
                          boolQs,
                          homeDistance,
                          homeChecked,
                          hasSigned
                        );
                      }}
                      label="View Filled"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                    <Button
                      label="Submit"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                      onClick={submit}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );

  function Button({
    label,
    bgColor = "#fdebc5",
    txtColor = "#000000",
    disabled = false,
    onClick = () => {},
  }) {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: bgColor,
          borderRadius: 5,
          borderColor: colors.border_color,
          borderWidth: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: 10,
          paddingVertical: 5,
          opacity: disabled ? 0.35 : 1,
        }}
        onPress={disabled ? () => {} : onClick}
      >
        <Text
          style={{
            color: txtColor,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  }

  async function captureTextAsImage() {
    try {
      const fullname =
        global.user_data.first_name.toLowerCase() +
        " " +
        global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: "png",
          quality: 0.8,
        });

        let ret = await create_new_user_signature(
          { user_id: global.user_data.user_id },
          global.token
        );
        if (ret.status === 200) {
          ret = ret.data;

          await put_image_s3(
            "signatures/" + ret.body.signature_id,
            uri,
            "image/png",
            global.token
          );

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem("signature", global.signature_uri);
          storeJsonData("user_data", global.user_data);

          setHasSignature(true);
        } else {
          showSnackbar(
            "error",
            "Application Error: Failed to create your signature"
          );
        }
      } else {
        showSnackbar(
          "error",
          "Input Error: The name inputted does not match your name we have on record!"
        );
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  }

  function addSignature() {
    // Add signature to s3
    captureTextAsImage();
  }

  function BoolRow({ label, onChange, checked, value_color = colors.bow }) {
    return (
      <TableRow
        sx={{
          boxShadow: "none",
          backgroundColor: colors.textfield_background,
          "&:hover": {
            backgroundColor: colors.textfield_background_hover,
          },
        }}
      >
        <TableCell>
          <CheckBox
            value={checked}
            style={{ margin: 10, marginRight: 15 }}
            onValueChange={onChange}
          />
        </TableCell>
        <TableCell>
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text style={{ color: value_color }}>{label}</Text>
          </View>
        </TableCell>
      </TableRow>
    );
  }

  function sign() {
    setHasSigned(true);
  }

  async function submit() {
    // check if lodging date is entered
    if (lodgingDate === null) {
      showSnackbar("error", "You must enter a lodging date before submitting.");
      return
    }

    // check if home distance is empty
    if (homeDistance === "") {
      showSnackbar(
        "error",
        "You must enter your residence distance before submitting."
      );
      return
    }

    // check if home distance is a number
    if (isNaN(parseInt(homeDistance))) {
      showSnackbar(
        "error",
        "Your distance from home must be a valid number."
      );
      return
    }

    // check if home distance is at least 50 miles
    if (parseInt(homeDistance) < 50) {
      showSnackbar(
        "error",
        "Your distance from home must be at least 50 miles from your unit."
      );
      return
    }

    // check if all the boxes are checked
    if (!homeChecked || boolQs.includes(false)) {
      showSnackbar("error", "You must check all boxes before submitting.");
      return
    }


    // check if approver is selected
    if (approver === null) {
      showSnackbar("error", "You must have an approver before submitting.");
      return
    }

    // check if user signed
    if (!hasSigned) {
      showSnackbar("error", "You must sign the document before submitting.");
      return
    }

    // check if user has a submitted SOU
    const hasSOU = await userHasValidSOU();
    if (!hasSOU) {
      showSnackbar(
        "error",
        "You must have an SOU form submitted and approved within this fiscal year before you can submit an LIK form"
      );
      return
    }

    uploadLIKForm();
  }

  function getRankName() {
    if (
      global.user_data.middle_name == null ||
      global.user_data.middle_name == ""
    ) {
      return (
        global.user_data.rank +
        " " +
        global.user_data.first_name +
        " " +
        global.user_data.last_name
      );
    } else {
      return (
        global.user_data.rank +
        " " +
        global.user_data.first_name +
        " " +
        global.user_data.middle_name +
        " " +
        global.user_data.last_name
      );
    }
  }

  async function uploadLIKForm() {
    console.log("uploading")

    const formData = {
      name_and_rank: getRankName(),
      soldier_signature_id: global.user_data.signature_id,
      date: getTodaysDate(),
      creator_id: global.user_data.user_id,
      reviewer_id: approver.user_id,
      reviewer_signature_id: approver.user_id,
      request_is_approved: false,
      request_is_approved_date: null,
      unit_name: global.user_data.unit_name,
      command_name: global.user_data.unit_name,
      distance: parseInt(homeDistance),
      creator_id: global.user_data.user_id,
      creator_id: global.user_data.user_id,
      comment: "",
      form_name: getRankName(),
      form_type: "LIK",
      request_type: "LIK",
      reviewer_ids: [approver.user_id],
      recommender_id: approver.user_id,
      status: 1,
      unit_id: global.user_data.unit_id,
      sex: "",
      lik_ba_date: lodgingDate.format("MM/DD/YYYY"),
      manager_signature_id: null,
      manager_comment: "",
      address: global.user_data.address_line_1,
      city: global.user_data.city,
      state: global.user_data.state,
      zip: global.user_data.zipcode,
      phone: null,
      email: global.user_data.email,
    };

    console.log(formData);

    setIsLoading(true);
    if (amendFile) {
      await update_file({...formData, form_id: draftFormID, attachments: null, form_type: "LIK"}, global.token).then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.body === "File successfully updated") {
          showSnackbar("success", "LIK Form updated successfully");
          sendNotifToApprover(approver.user_id, draftFormID);
          resetState();
          navigation.navigate("Home");
        } else {
          showSnackbar("error", "Failed to update LIK.");
          return false;
        }
      });
    }
    else {
      await upload_file(formData, global.token).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          showSnackbar("success", "LIK Form submitted successfully");
          sendNotifToApprover(approver.user_id, res.data.body.form_id);
          resetState();
          navigation.navigate("Home");
        } else {
          showSnackbar("error", "Failed to create LIK.");
          return false;
        }
      });
    }
  }

  async function userHasValidSOU() {
    setIsLoading(true);
    const res = await get_all_user_files(
      global.user_data.user_id,
      global.token
    );
    setIsLoading(false);

    if (res.status === 200) {
      // Get Latest SOU Form
      const souForms = res.data.body.filter(
        (f) =>
          f["form_type"] == "SOU" && // Is SOU
          new Date(f["timestamp"][0]).getFullYear() ==
            new Date().getFullYear() && // This year
          f["status"] == 4 // Is approved
      );

      if (souForms.length === 0) return false;
      return true;
    } else {
      showSnackbar(
        "error",
        "Application Error: Failed to fetch SOU information. Please try again later."
      );

      return false;
    }
  }

  function ApproverDropdown() {
    return (
      <FormControl
        fullWidth
        style={styles.dropDownStyle}
        sx={{
          ...styles.textInputProps,
          justifyContent: "center",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.form_border, // Default border color
            },
            "&:hover fieldset": {
              borderColor: colors.form_border, // Border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.form_border, // Border color when focused
            },
          },
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            color: colors.bow, // Label color
            "&.Mui-focused": {
              color: colors.bow, // Label color when focused
            },
          }}
        >
          Select An Approver
        </InputLabel>
        <Select
          inputProps={styles.selectProps}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={approver || ""}
          label="Select An Approver"
          onChange={(e) => {
            setApprover(e.target.value)
          }}
          sx={{
            color: colors.bow, // Text color inside the Select
            "& .MuiSelect-icon": {
              color: colors.bow, // Dropdown arrow color
            },
          }}
        >
          {officers.map((o) => (
            <MenuItem
              key={o.user_id}
              value={o}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: colors.textfield_background_hover, // Background color of selected item
                  color: "#000000", // Text color of selected item
                },
                "&:hover": {
                  backgroundColor: colors.textfield_background_hover, // Background color on hover
                  color: "#000000", // Text color on hover
                },
              }}
            >
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  async function sendNotifToApprover(approver_id, form_id) {
    const data = {
      title: "LIK Form",
      body: "You have one new LIK form to review",
      receiver_id: approver_id,
      sender_id: global.user_data.user_id,
      notification_type: "file",
      object_type: "review LIK form",
      form_events_id: form_id,
    };

    setIsLoading(true);

    const res = await create_notification(data, global.token);

    if (res.status !== 200) {
      showSnackbar(
        "success",
        "Your Reviewer has been notified",
        "top",
        "right",
        6000,
        400
      );
    }

    setIsLoading(false);
  }

  function resetState() {
    setIsLoading(false);
    setHasSigned(false);
    setSignatureName("");
    setHasSignature(false);
    setBoolQs(Array(boolQuestions.length).fill(false));
    setOfficers([]);
    setApprover(null);
    setHomeChecked(false);
    setHomeDistance("");
    setLodgingDate(null);
  }
}

const boolQuestions = [
  "I understand this Program is for me use while in an IDT status. I may not use this program if I am on any type of active duty orders.",
  "I understand that I will be held liable and agree to collection from my military pay for this cost of lodging if I fail to honor my reservation without proper notification to my unit. It is my responsibility to ensure that a reservation is cancelled NLT 96 hours prior to the reservation date if I do not plan to use it. Failure to cancel my reservation will result in a 6-month loss of use of the program. A second failure to cancel a reservation within a 12 month period after reinstatement will result in my forfeiting this program. Only the Unit commander can reinstate the program once it had been forfeited.",
  "I understand this Program will pay for pre-approved government LIK only. Travel costs are not reimbursable under this program.",
  "I understand that the exercise of double occupancy is enforceable for each room.",
  "I understand that I am required to pay for any additional cost, i.e. incidental, accidental, or consequential cost, that I may accrue.",
  "I understand that upgrades that incur additional cost to the government are not authorized. If I upgrade and increase the cost of the room, I will assume the entire room charge without any financial reimbursement from the command.",
  "I am responsible for any charges other than the cost of lodging that are accrued while residing in quarters. This includes, but is not limited to, local and long distance telephone calls, refreshments, movies and pay-per-view premium television channels. These additional costs will be settled with the lodging facility personally and at no cost to the government. Failure to settle additional costs will result in a 6-month loss of this program.",
];

async function viewBlankLIKForm() {
  const pdf = await PDFDocument.load(LIKForm.pdf());
  const form = pdf.getForm();

  form.flatten();
  const bytes = await pdf.saveAsBase64();
  pdfIn(bytes);
}

async function createLIKForm(
  lodgingDate,
  boolQs,
  distance,
  homeChecked,
  hasSigned
) {
  const pdf = await PDFDocument.load(LIKForm.pdf());
  const page = pdf.getPages()[0];
  const page2 = pdf.getPages()[1];
  const form = pdf.getForm();
  const fields = form.getFields();

  const initials =
    global.user_data.first_name[0] + global.user_data.last_name[0];

  if (lodgingDate)
    lodgingDate = `${lodgingDate.$M + 1}/${lodgingDate.$D}/${lodgingDate.$y}`;

  const displayCityStateZip = () => {
		if (global.user_data.city === "" && global.user_data.state === "" && global.user_data.zipcode === "") {
			return "";
		} else {
			return (global.user_data.city + ", " + global.user_data.state + " " + global.user_data.zipcode);
		}
	};

  const formData = {
    "1 DATE RRQUESTED FOR LODGING": lodgingDate,
    "2 UNIT": global.user_data.unit_name,
    "3 NAME": global.user_data.first_name + " " + global.user_data.last_name,
    "4 RANK": global.user_data.rank,
    "5 ADDRESS": global.user_data.address_line_1,
    "6 CITYSTATEZIP CODE": displayCityStateZip(),
    "7 HOMEPHONE": global.user_data.phone_number,
    "8 WORKPHONE": global.user_data.phone_number,
    "Signature of Soldier": "",
    Date: getTodaysDate(),
    "Commanders Signature": "",
    Date_2: "",
    "normal commuting distance of 50 miles to the unit": homeChecked
      ? initials
      : "",
    "miles from my unit This is outside the LIK programs": distance,
    "any type of active duty orders": boolQs[0] ? initials : "",
    "lodging if I fail to honor my reservation without proper notification to my unit It is my responsibility to ensure":
      boolQs[1] ? initials : "",
    "reimbursable under this program": boolQs[2] ? initials : "",
    "I understand that the exercise of double occupancy is enforceable for each room":
      boolQs[3] ? initials : "",
    "consequential cost that I may accrue": boolQs[4] ? initials : "",
    "and increase the cost of the room I will assume the entire room charge without any financial reimbursement":
      boolQs[5] ? initials : "",
    "quarters This includes but is not limited to local and long distance telephone calls refreshments movies and":
      boolQs[6] ? initials : "",
    "Commanders Signature I Date": "",
    "Printed Name I Grade of Soldier":
      global.user_data.first_name + " " + global.user_data.last_name,
    "Printed Name I Grade of Commander": "",
    Name: global.user_data.first_name + " " + global.user_data.last_name,
  };

  fields.forEach((field) => {
    const type = field.constructor.name;
    const name = field.getName();

    if (formData[name]) {
      console.log(type, name, formData[name]);

      field.setText(formData[name]);
    }
  });

  if (hasSigned) {
    const img = await pdf.embedPng(global.signature_uri);

    page.drawImage(img, {
      x: 135,
      y: 300,
      width: 100,
      height: 25,
    });

    page2.drawImage(img, {
      x: 30,
      y: 160,
      width: 100,
      height: 25,
    });

    const textboxStyle = {
      textColor: rgb(0, 0, 0),
      backgroundColor: rgb(1, 1, 1),
      borderColor: rgb(1, 1, 1),
    };

    let field = form.createTextField("applicant_signature_date1");
    field.setText(getTodaysDate());
    field.addToPage(page2, {
      x: 150,
      y: 160,
      width: 100,
      height: 15,
      ...textboxStyle,
    });
  }

  form.flatten();
  const bytes = await pdf.saveAsBase64();
  pdfIn(bytes);
}

function getTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}

const SignatureArea = React.memo(function SignatureArea({
  hasSigned,
  hasSignature,
  signatureName,
  styles,
  colors,
  Button,
  addSignature,
  setSignatureName,
  sign,
}) {
  return (
    <View>
      {hasSignature ? (
        hasSigned ? (
          <Image
            resizeMode={"contain"}
            style={{
              width: 200,
              height: 100,
            }}
            source={{ uri: global.signature_uri }}
          />
        ) : (
          <></>
        )
      ) : (
        // Add Signature
        <View
          style={{
            flexDirection: "column",
            gap: 30,
          }}
        >
          {signatureName == "" ? (
            <Text
              style={{
                opacity: 0.7,
                width: 500,
                fontStyle: "italic",
                color: "red",
              }}
            >
              No signature found. Please add one below.
            </Text>
          ) : (
            <ViewShot
              ref={viewRef}
              style={{
                padding: 10,
                marginVertical: 10,
                backgroundColor: "white",
              }}
              options={{ format: "png", quality: 1 }}
            >
              <Text style={{ color: "black", fontSize: scale(30) }}>
                {signatureName}
              </Text>
            </ViewShot>
          )}

          <TextInput
            style={styles.commentInput}
            theme={{
              colors: {
                text: colors.inputted_text,
                accent: colors.textfield_background,
                primary: colors.inputted_text,
                placeholder: colors.placeholder_text,
              },
            }}
            selectionColor={"black"}
            placeholder="e.g. John Doe"
            label={`First and Last Name`}
            activeOutlineColor={"black"}
            value={signatureName}
            onChangeText={(data) => setSignatureName(data)}
          />

          <View style={{ width: 128 }}>
            <Button
              label="Add Signature"
              bgColor={colors.gobl}
              txtColor={colors.bow}
              onClick={addSignature}
            />
          </View>
        </View>
      )}

      {/* Buttons */}
      <View style={{ width: 128, marginBottom: 30 }}>
        <Button
          label="Sign Document"
          bgColor={colors.gobl}
          txtColor={colors.bow}
          disabled={!hasSignature}
          onClick={sign}
        />
      </View>
      <Text
        style={{
          opacity: 0.7,
          width: 500,
          fontStyle: "italic",
          color: colors.bow,
        }}
      >
        By clicking on the "Submit" button below, you consent to your digital
        signature being affixed to the document. Your signature can be viewed
        below after clicking the "Sign Document" button. If you don't have a
        signature, please add one below.
      </Text>
    </View>
  );
});

export default CreateNewLIK;

CreateNewLIK.propTypes = {
  navigation: PropTypes.object.isRequired,
};
