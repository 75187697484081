// import { url, headers } from './api_settings';
import { unitsAPI } from './api_settings';
import axios from 'axios';

/**
 * This endpoint fetches all the different unit names stored in the database
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): Will be passed over with a value of 0 for a specific sql query
 * - get_top_unit (boolean): Will be over as true for specific sql querying
 * - get_path (boolean): Will be over as true for specific sql querying
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_all_unit_names(data, global.token);
 */
export const get_all_unit_names = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_all_unit_names';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
        return error.response;
    } else {
        return {
            statusCode: 500,
            body: 'An unexpected error occurred',
        };
    }
  }
};

/**
 * This endpoint fetches all the different users associated within a 
 * unit a user is an admin for
 *
 * @param {Object} data - Data contains following information:
 * - unit_ids (Array<int>): an array of all the units a user manages
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_all_unit_users({ unit_ids: global.user_data.admin_unit_ids }, global.token);
 */
export const get_all_unit_users = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_all_unit_users';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * This endpoint fetches all the logs stored in the S3 for each unit that
 * a user manages (admin)
 *
 * @param {Object} data - Data contains following information:
 * - unit_ids (Array<int>): An array of all the units a user manages
 * - date (Date): The date the user is interested in
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_all_unit_logs({ unit_ids: global.user_data.admin_unit_ids, date: formatYearMonthDay(date) }, global.token);
 */
export const get_all_unit_logs = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_all_unit_logs';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
      console.error(error.response);
      if (error.response) {
          return error.response;
      } else {
          return {
              statusCode: 500,
              body: 'An unexpected error occurred',
          };
      }
  }
};

/**
 * This endpoint fetches all the unit documents that pertain to which unit the user
 * is apart of
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The unit the user is apart of
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_all_unit_documents({ 'unit_id': unit_id }, global.token);
 */
export const get_all_unit_documents = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_all_unit_documents';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * This endpoint allows a user with Unit Document permission to add
 * a Unit Document for their unit
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The unit the user is apart of
 * - unit_document_name (string): What we are calling the unit document
 * - permissions (Array<int>): All the users permissions but we are only looking for code 37
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await add_unit_document({ unit_id: unit_id, unit_document_name: finalName.trim(), permissions: global.user_data.permissions }, token);
 */
export const add_unit_document = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/add_unit_document';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
        return error.response;
    } else {
        return {
            statusCode: 500,
            body: 'An unexpected error occurred',
        };
    }
  }
};

/**
 * This endpoint allows a user with Unit Document permission to delete
 * a Unit Document for their unit. There aren't permissions necessarly
 * attached but we are hiding the delete button if the user doesn't
 * have permission code 37
 *
 * @param {Object} data - Data contains following information:
 * - unit_document_id (int): The identifier for the document
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await delete_unit_document({ unit_document_id: unitDocumentID }, global.token);
 */
export const delete_unit_document = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/delete_unit_document';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.delete(url_endpoint, {
			headers: header,
			data: data
		});
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Allows a user with Admin Console permission to view all 
 * Points of Contacts for their unit
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The identifier for the unit the user is apart of
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_all_unit_poc({ unit_id: global.user_data.unit_id }, global.idToken);
 */
export const get_all_unit_poc = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_all_unit_poc';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
        return error.response;
    } else {
        return {
            statusCode: 500,
            body: 'An unexpected error occurred',
        };
    }
  }
};

/**
 * Allows a user with Admin Console permission to add a new POC for their unit.
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The identifier for the unit the user is apart of
 * - poc_role (string): The role that the POC is attributed to have
 * - poc_name (string): The full name of the POC
 * - poc_phone_number (string): The phone number of the POC
 * - poc_email (string): The email for the POC
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await add_unit_poc(data, global.token);
 */
export const add_unit_poc = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/add_unit_poc';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console permission to update a POC for their unit.
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The identifier for the unit the user is apart of
 * - poc_role (string): The role that the POC is attributed to have
 * - poc_name (string): The full name of the POC
 * - poc_phone_number (string): The phone number of the POC
 * - poc_email (string): The email for the POC
 * - poc_id (int): The identifier for the pre-existing POC
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await update_unit_poc(data, global.token);
 */
export const update_unit_poc = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/update_unit_poc';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console permission to update a POC for their unit.
 *
 * @param {Object} data - Data contains following information:
 * - poc_id (int): The identifier for the pre-existing POC
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await delete_unit_poc({poc_id: POCID}, global.token);
 */
export const delete_unit_poc = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/delete_unit_poc';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.delete(url_endpoint, {
			headers: header,
			data: data
		});
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * This endpoint fetches all users that are in a unit that have approval/recommendation permissions for 
 * that particular form. 
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The identifier for the unit the user is apart of
 * - permission (int): The permission code to determine what role the user currently has
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_all_users_in_single_unit({ unit_id: global.user_data.unit_id, permission: 3001 }, global.idToken);
 */
export const get_all_users_in_single_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_all_users_in_single_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console permission to edit the form fields
 * that will appear for any forms submitted by the soldiers within 
 * their unit
 *
 * @param {Object} data - Data contains following information:
 *  - from_type (string): Either an 'RST' or a '1380'
 *  - unit_id (int): The unit this user is apart of
 *  - header (string): Formatted string only for RST specific forms
 *  - memorandum (string): Formatted string only for RST specific forms
 *  - commanding_officer (string): Formatted string for this units commander only for RST specific forms
 *  - field_memo (string): Formatted string only for RST specific forms
 *  - unit_1380_to (string): Address for where the 1380 form is being sent only for 1380 specific forms
 *  - unit_1380_from (string): Address for where the 1380 form is being sent from only for 1380 specific forms
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await edit_unit_info_in_forms(data, global.token);
 */
export const edit_unit_info_in_forms = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/edit_unit_info_in_forms';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Gets the unit info for form header purposes. Gets things like the unit
 * header, memorandum, and the units commanding officer
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): The identifier for the unit the user is apart of
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_forms_related_info_of_a_unit({ unit_id: global.user_data.unit_id }, global.idToken);
 */
export const get_forms_related_info_of_a_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_forms_related_info_of_a_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Gets all units found in project mercury, user must have Admin Console Permission
 *
 * @param {Object} data - Data contains following information:
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_units({}, global.idToken);
 */
export const get_units = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_units';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves the unit name based on the unit_id passed, 
 * user must have Admin Console Permission
 * 
 * @param {Object} data - Data contains following information:
 *  - unit_id (int): The identifier for the unit the user wishes to fetch
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_unit_id_name({ unit_id }, global.token)
 */
export const get_unit_id_name = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_unit_id_name';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves the entire unit information based on the unit_id, 
 * user must have Admin Console Permission
 *
 * @param {Object} data - Data contains following information:
 *  - unit_id (int): The identifier for the unit the user wishes to fetch
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_unit_id_name({ unit_id }, global.token)
 */
export const get_table_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_table_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves the all unique battalion names for the process of adding
 * a new unit, user must have Admin Console Permission
 *
 * @param {Object} data - Data contains following information:
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_unique_battalions({}, global.token);
 */
export const get_unique_battalions = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_unique_battalions';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves the all unique brigade names for the process of adding
 * a new unit, user must have Admin Console Permission
 *
 * @param {Object} data - Data contains following information:
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_unique_brigades({}, global.token);
 */
export const get_unique_brigades = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_unique_brigades';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves the all unique command names for the process of adding
 * a new unit, user must have Admin Console Permission
 *
 * @param {Object} data - Data contains following information:
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_unique_commands({}, global.token);
 */
export const get_unique_commands = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_unique_commands';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves all values for 1380 to and from columns
 *
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 */

export const get_1380_to_and_from = async (token) => {
  const url_endpoint = unitsAPI + "units/get_unit_1380_to_and_from";

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, null, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
}

/**
 * Allows a user with Admin Console to add a unit to Project Mercury
 *
 * @param {Object} data - Data contains following information:
 *  - admin_id (int): The identifier for the admin adding the unit
 *  - UIC (string): The identifier for the unit 
 *  - unit_name (string): The name of the new unit
 *  - unit_role (string): The role of the new unit
 *  - superior_unit_id (int): Identifier for the superior unit id
 *  - is_hhc (string): Determines if this new unit is a Headquartes and Headquartes Company
 *  - battalion_name (string): Determines what battalion this new unit is
 *  - brigade_name (string): Determines what brigade this new unit is
 *  - command_name (string): Determines what command this new unit is
 *  - has_brigade (boolean): Always passed as true
 *  - unit_address_line_1 (string): The address of the unit
 *  - unit_address_line_2 (string): The address of the unit
 *  - unit_city (string): The city the new unit is located in
 *  - unit_state (string): The state the new unit is located in
 *  - unit_zipcode (string): The zipcode of the new unit
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await add_unit({ data }, global.token);
 */
export const add_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/add_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console to add a unit to Project Mercury
 *
 * @param {Object} data - Data contains following information:
 *  - unit_name (string): The name of the new unit
 *  - UIC (string): The military code identifier for the unit
 *  - unit_id (int): The backend identifier for the unit
 *  - admin_id (int): The identifier for the admin of the unit
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await update_unit({ unit_name: unitName, unit_uic: unitUIC, unit_id: unitID, admin_id: global.user_data.user_id }, global.token);
 */
export const update_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/update_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Deletes the unit for the admin, Admin Console is required to view the page
 * but there aren't any backend checks for the user permission
 *
 * @param {Object} data - Data contains following information:
 *  - unit_id (int): The identifier for the unit
 *  - admin_id (int): The identifier for the admin
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await delete_unit({ unit_id: unitID, admin_id: global.user_data.user_id }, global.token);
 */
export const delete_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/delete_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .delete(url_endpoint, { headers: header, data: data })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  console.log(res);

  return res;

  // try {
	// 	const response = await axios.delete(url_endpoint, {
	// 		headers: header,
	// 		data: data
	// 	});
	// 	return response;
	// } catch (error) {
	// 	console.log(error.response)
	// 	if (error.response) {
	// 		return error.response;
	// 	} else {
	// 		return {
	// 			statusCode: 500,
	// 			body: 'An unexpected error occurred',
	// 		};
	// 	}
	// }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Date: 3/13/2024
 * Author: Reynaldo Martinez
 */
export const update_user_unit = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/update_user_unit';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .put(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

export const get_units_for_1380 = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_units_for_1380';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Returns a list of Reviewers in accordance to a specified permission
 *
 * @param {Object} data - Data contains following information:
 *  - permission (int): The permission required of the Reviewer
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_reviewers_by_permission({ permission: permission }, global.token);
 */
export const get_reviewers_by_permission = async (data, token) => {
  const url_endpoint = unitsAPI + 'units/get_reviewers_by_permission';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};