import { eventsAPI } from "./api_settings";
import axios from "axios";

export const get_all_ebdl_courses = async (token) => {
  const url_endpoint = eventsAPI + 'events/get_all_ebdl_courses';
  const data = {};

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    //console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
}