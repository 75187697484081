import React, {useState, useEffect, useCallback} from "react";
import { View } from "react-native";
import Chart from "./Chart";
import BottomChart from "./BottomChart";
import Bump from "../Bump";
import { get_budget_charts, get_target_lines } from "../../api_handler/budget";
import { getFiscalYear } from "../../utils/monthInfo";
import { monthDict } from "../../utils/monthInfo";
import * as Progress from 'react-native-progress'
import { useColorContext } from "../ColorContext";

const BudgetChart = React.memo(({activeChartButton, budgetGroupButton, token, unitId, pieSelection, pieColorSelection, setEditTargetLine, currentFy, selectedFy}) =>{
    const [dataKeys, setDataKeys] = useState(["Budget", "Requested", "Approved", "Paid", "Target"]);
    const [prevBudgetChartData, setPrevBudgetChartData] = useState([]);
    const [budgetChartData, setBudgetChartData] = useState([]);
    const [upcomingBudgetChartData, setUpcomingBudgetChartData] = useState([]);
    const selectedBudgetChartData = selectedFy === currentFy ? budgetChartData : selectedFy < currentFy ? prevBudgetChartData : upcomingBudgetChartData;
    const [budgetLoaded, setBudgetLoaded] = useState(false);
    const [budgetEdited, setBudgetEdited] = useState(false);
    const { colors } = useColorContext()

    const [targetLine, setTargetLine] = useState([]);
    const [targetLoaded, setTargetLoaded] = useState([]);

    const [range, setRange] = useState('FY')

    const chartLineColors = {
        Paid: '#c0392b',
        Approved: '#f1c40f',
        Requested: '#3498db',
        Target: '#218c74',
        Budget: "#ffffff"
    };

    const truncateData = (data, range) => {
        const currentMonth = new Date().toLocaleDateString('default', { month: 'long' }).slice(0, 3);
        const currentMonthIndex = monthDict[currentMonth];

        // Given the index, return the past range months data.
        let truncatedData = []
        for (let i = currentMonthIndex; i >= 0 && range > 0; i--) {
            truncatedData.push(data?.[i]);
            range--;
        }

        return truncatedData.reverse();
    }

    // Run getAllTargetLines on first render.
    useEffect( () => {
        async function fetchData() {
            const prevData = await getBudgetChartLines(currentFy - 1);
            const currentData = await getBudgetChartLines(currentFy);
            const upcomingData = await getBudgetChartLines(currentFy + 1);

            setPrevBudgetChartData(prevData);
            setBudgetChartData(currentData);
            setUpcomingBudgetChartData(upcomingData);
        }

        async function getBudgetChartLines(fy) {
            setBudgetLoaded(false)
            const data = {
                "unit_id": global.user_data.unit_id,
                "pot": budgetGroupButton,
                "subtype": activeChartButton,
                "fiscal_year": fy,
            }

                const results = await get_budget_charts(data, global.token)
                const fullData = results?.data?.body;

                // Alert.alert({ fullData })
                // Create JSON object with 'FY', '6Mo', '3Mo' as keys and the values as the data for the chart.
                const chartData = {
                    'FY': fullData,
                    '6Mo': truncateData(fullData, 6),
                    '3Mo': truncateData(fullData, 3),
                }

                // console.log({ chartData, data })

                if (results?.status === 200) {
                    setRange('FY');
                    setBudgetLoaded(true);
                    return chartData;
                }
                else setBudgetChartData([]);
        }

        async function getTargetLine() {
            setTargetLoaded(false)
            const data = {
                "unit_id": global.user_data.unit_id,
                "pot": budgetGroupButton,
                "subtype": activeChartButton,
                "fiscal_year": getFiscalYear(),
            }

            const results = await get_target_lines(data, global.token)
            // console.log(JSON.stringify(results, null, 2));

            if (results?.status === 200) {
                setTargetLine(results?.data?.body?.[activeChartButton.toUpperCase()])
                setTargetLoaded(true)
            }
            else
                setTargetLine([])
        }

        fetchData();
        getTargetLine();
    }, [budgetGroupButton, activeChartButton, budgetEdited])

    const handleBudgetEditOrDelete = () => {
        setBudgetEdited(prevState => !prevState);
    }

    const handleDataKeyToggle = useCallback((value) => {
        setDataKeys(prevKeys => {
            let newKeys;

            if (!prevKeys.includes(value)) {
                newKeys = [...prevKeys, value];
            } else if (prevKeys.includes(value)) {
                newKeys = prevKeys.filter(k => k !== value);
            } else {
                newKeys = prevKeys;
            }

            newKeys.sort((a, b) => {
                const order = ["Budget", "Requested", "Approved", "Paid", "Target"];
                return order.indexOf(a) - order.indexOf(b);
            })

            return newKeys
        });
    }, []);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        animation: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
            paddingVertical: 20
        }
    }

    if (budgetGroupButton === 'RST') return null;

    if (!budgetLoaded) return (
        <View style={styles.animation}>
            <Progress.CircleSnail
                color={colors.loading_circle}
                indeterminate={true}
                size={275}
                thickness={8}
            />
        </View>
    )

    return (
        <View style={styles.container}>
            <Chart 
            pieSelection={pieSelection} 
            pieColorSelection={pieColorSelection} 
            budgetChartData={selectedBudgetChartData} 
            dataKeys={dataKeys} 
            chartLineColors={chartLineColors} 
            range={range} 
            subtype={activeChartButton}
            selectedFy={selectedFy}
            />
            <Bump size={20} />
            {targetLoaded && <BottomChart dataKeys={dataKeys} activeChart={activeChartButton} targetLine={targetLine} openPopup={() => setEditTargetLine(true)} range={range} setRange={setRange} handleDataKeyToggle={handleDataKeyToggle} unitId={unitId} budgetGroupButton={budgetGroupButton} token={token} handleBudgetEditOrDelete={handleBudgetEditOrDelete}/>}
        </View>
    );
}) 

export default BudgetChart;