/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable object-curly-spacing */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState, useRef } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { CheckBox } from 'react-native-web';
import { useScale } from '../../components/scale';

import NavSideBar from '../../components/NavSideBar';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, styled, useTheme, ThemeProvider } from '@mui/material/styles';
import { TextInput } from 'react-native-paper';
import { get_single_file, review_file } from '../../api_handler/files';
import { generate_ebdl, generate_ata, generate_mdr, generate_rma } from '../generatePDF.js';
import { useColorContext } from '../../components/ColorContext';
import Scrollbars from "react-custom-scrollbars";
// eslint-disable-next-line quotes
import { create_notification } from "../../api_handler/notifications.js";

import TextField from '@mui/material/TextField';
import ViewShot from 'react-native-view-shot';
import { captureRef } from 'react-native-view-shot';
import { create_new_user_signature } from "../../api_handler/users.js";
import { put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from '../../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSnackbar } from '../../components/SnackbarContext';
import * as Progress from 'react-native-progress';

function ReviewEBDL({ route, navigation }) {
  const scale = useScale();
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const styles = ({
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    main: {
      width: '75%',
      flex: 10,
      alignItems: 'center',
    },
    header: {
      marginTop: '0%',
      height: 110,
      backgroundColor: colors.screen_background,
    },
    rowContainer: {
      flexDirection: 'row',
      marginTop: 15,
      marginBottom: 10,
      justifyContent: 'center',
    },
    title: {
      fontSize: scale(50),
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    signText: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow
    },
    description: {
      fontSize: scale(18),
      marginLeft: '5%',
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.bow,
      width: '50%',
      fontFamily: 'Trebuchet MS',
      marginBottom: '2%'
    },
    button: {
      width: "10vw",
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.action_button,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: "center",
    },
    cancelButton: {
      width: '10vw',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    contentContainer: {
      height: '50vh',
      width: '100%',
      marginTop: 10,
      margin: 20,
      flexDirection: 'row',
      justifyContent: 'center',
      flex: 1,
    },
    tableContainer: {
      backgroundColor: colors.screen_background,
      flex: 1,
      width: "35%",
    },
    tableDiv: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
    },
    textStyle: {
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    commentInput: {
      borderColor: colors.border_color,
      minHeight: 64,
      height: commentInputHeight,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
      width: "85%",
      marginTop: '5%'
    },
    entryBox: {
      backgroundColor: colors.screen_background,
      justifyContent: "center",
      alignItems: "center",
      padding: '3%',
      marginBottom: '2%',
      width: '85%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    approvalModalTitle: {
      fontFamily: 'Trebuchet MS',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: '3%'
    },
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
    checkboxLabel: {
      marginLeft: 8,
      fontSize: 15,
      color: colors.text,
    },
  });

  const [loaded, setLoaded] = useState(false);
  const [viewSig, setViewSig] = useState(false);
  const [author, setAuthor] = useState('');
  const [isApproved, setisApproved] = useState(false);
  const [isDisapproved, setisDisapproved] = useState(false);

  // FILE FIELDS
  const [requestName, setRequestName] = useState('');
  const [date, setDate] = useState(new Date());
  const [training, setTraining] = useState('');
  const [dates, setDates] = useState('');
  const [comment, setComment] = useState('');
  // const [submitted, setSubmitted] = useState(false);
  const [reqDate, setReqDate] = useState('');
  const [rankName, setRankName] = useState('');
  const [rerender, setRerender] = useState(false);
  const [form, setForm] = useState({});
  const [approverComment, setApproverComment] = useState('');
  const [approverEval, setApproverEval] = useState('');
  const [CheckBox7, setCheckBox7] = useState(false);
  const [CheckBox8, setCheckBox8] = useState(false);
  const [events, setEvents] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState(new Array(events.length).fill(false));
  const [commentInputHeight, setCommentInputHeight] = useState(64);
  const [FormData, setFormData] = useState();

  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [didUserSignDocument, setDidUserSignDocument] = useState(false);

  const viewRef = useRef();

  useEffect(() => {
    if (comment === '') {
      setCommentInputHeight(64);
    }
  }, [comment]);

  useEffect(() => {
    let formtype = route.params.form_type;
    async function loadFile() {
      const res = await get_single_file({ 'form_id': route.params.fileID, 'form_type': formtype }, global.token);
      if (res !== undefined) {
        preLoadInfo(res.body);
        setFormData(res.body);
        setLoaded(true);
        setRerender(!rerender);
      } else {
        showSnackbar("error", "Application Error: Failed to retrieve the review form")
        navigation.navigate("AwaitingAction");
      }
    }

    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === '') {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }

    setDidUserSignDocument(false);
    checkSignature();
    loadFile();
  }, []);

  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + " " + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data;
          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);

          setDoesUserHaveSignature(true);
        } else {
          showSnackbar("error", "Application Error: Failed to create your signature");
        }
        
      } else {
        showSnackbar('error', 'The name inputted does not match your name we have on record!');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  const viewFilled = async () => {
    setLoaded(false)
    if(route.params.form_type === 'ATA') {
      await generate_ata(route.params.fileID, FormData, navigation, false, false);
    }
    if(route.params.form_type === 'RMA') {
      await generate_rma(route.params.fileID, FormData, navigation, false, false);
    }
    if(route.params.form_type === 'EBDL') {
      await generate_ebdl(route.params.fileID, FormData, navigation, false, false);
    }
    if(route.params.form_type === 'MDR') {
      await generate_mdr(route.params.fileID, FormData, navigation, false, false);
    }
    setLoaded(true)
  };

  const preLoadInfo = async (form) => {
    setRequestName(form.name_and_rank);
    setAuthor(form.name_and_rank);
    setRankName(form.name_and_rank);
    setReqDate(form.date);
    if (
      form.date === undefined ||
      form.date === ''
    ) {
      setDate(new Date());
    } else {
      setDate(new Date(form.date));
    }

    const dates = form.dates_of_training.split(", ");
    const training = form.training_to_be_completed.split(", ");
    const events = dates.map((date, index) => ({
      date,
      training: training[index] || "",
    }));

    setEvents(events);

    // setTraining(form.training_to_be_completed);
    // setDates(form.dates_of_training);

    if (form.request_is_approved == null || form.request_is_approved == undefined) {
      setApproverEval('Not Yet Evaluated');
    } else if (form.request_is_approved == true) {
      setApproverEval('Approved');
    } else {
      setApproverEval('Disapproved');
    }

    setForm(form);
    setLoaded(true);
  };

  // Building form data table
  function createTable() {
    const data = [
      { label: 'Requestor Name', data: author },
      { label: 'Date Filed', data: reqDate },
    ];

    return data;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${TableCell.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
      backgroundColor: colors.textfield_background_hover

    },

    '& td, & th': {
      borderColor: colors.border_color,
    },
  }));

  const closeAllAccordions = () => {
    setExpandedAccordions(new Array(events.length).fill(false));
  };

  const changeAccept = (value) => {
    setisApproved(value);

    if (value) {
      setisDisapproved(false);
    }
  };

  const changeDecline = (value) => {
    setisDisapproved(value);

    if (value) {
      setisApproved(false);
    }
  };

  const handleSignature = () => {
    closeAllAccordions();
    setViewSig(true);
    setDidUserSignDocument(true);
  }

  const onSubmit = async () => {
    setLoaded(false);
    // make sure user has signature and approved/disapproved
    const sigFile = global.signature_uri;
    if (sigFile == null || sigFile === '') {
      showSnackbar('error', 'You do not have a signature...');
    } else if (isApproved === false && isDisapproved === false) {
      showSnackbar('error', 'Please either approve or disapprove this request.');
      setLoaded(true);
    } else {

      if(!didUserSignDocument) {
        showSnackbar("error", "Please Sign Document");
        setLoaded(true);
      } else {

        // Get approve/deny checkboxes from form
        if (isApproved) {
          const data = {
            user_id: global.user_data.user_id,
            form_id: route.params.fileID,
            form_type: route.params.form_type,
            reviewer_comment: comment,
            status: 4,
            reviewer_signature_id: global.user_data.signature_id,
            request_is_approved: true,
            permissions: global.user_data.permissions
          };

          let res =  await review_file(data, global.token);
          if (res.status === 200) {
            await sendNotifToSoldier(route.params.creator_id, 'approved');
            // showSnackbar("success", `${route.params.form_type} form approved successfully`);
          } else {
            showSnackbar("error", `Application Error: Failed to add your review to the ${route.params.form_type}`);
            navigation.navigate("AwaitingAction");
            return;
          }
          //will not let the reviewer continue if the file is dissaproved with an empty comment
        }else if (comment === '') {
          showSnackbar('error', 'Please enter a comment');
          setLoaded(true);
          return;
          
        } else if (isDisapproved) {
          const data = {
            user_id: global.user_data.user_id,
            form_id: route.params.fileID,
            form_type: route.params.form_type,
            reviewer_comment: comment,
            status: 5,
            reviewer_signature_id: global.user_data.signature_id,
            request_is_approved: false,
            permissions: global.user_data.permissions
          };

          let res = await review_file(data, global.token);
          if (res.status === 200) {
            await sendNotifToSoldier(route.params.creator_id, 'disapproved');
            // showSnackbar("success", `${route.params.form_type} form disapproved successfully`);
          } else {
            showSnackbar("error", `Application Error: Failed to add your review to the ${route.params.form_type}`);
            navigation.navigate("AwaitingAction");
            return;
          }
          
        }

        setLoaded(true);
        // showSnackbar('success', `${route.params.form_type} Form reviewed successfully.`);
        navigation.navigate('AwaitingAction');
      }
    }

    /* ------------------------------------------*/
    // email to supervising chief
    // send email to res.sectionChiefEmail
  };
  /* -----------------------------------------------*/

	const sendNotifToSoldier = async (soldier_id, status) => {
		const data = {
			title: route.params.form_type + " " + status,
			body: comment,
			receiver_id: soldier_id,
			sender_id: global.user_data.user_id,
			notification_type: 'file',
			object_type: route.params.form_type + status,
			form_events_id: route.params.fileID,
		};
		let res = await create_notification(data, global.token);

    if (res.status !== 200) {
      showSnackbar(
        "success", 
        isApproved ? route.params.form_type + " form approved successfully." :  route.params.form_type + " form disapproved successfully.",
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar("error", `Application Error: Failed to send notification to ${requestName}`)
        }
      )
    } else {
      showSnackbar("success", isApproved ? route.params.form_type + " form approved successfully." : route.params.form_type + " form disapproved successfully.");
    }
	};

  const onCancel = () => {
    navigation.navigate('AwaitingAction');
  };

  const theme = useTheme()
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  };



  return (
    <ThemeProvider theme={reviewTheme}>
      <View style={styles.container}>
        {
          loaded ? (
            <Scrollbars style={styles.main}>
              <View style={{ flexDirection: 'column' }}>
                <Text style={styles.pageTitle}>Review </Text>
                <Text style={styles.description}>
                  Review user's {route.params.form_type} information and approve/deny the request
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: '1%', width: '95%', paddingLeft: '5%', paddingRight: '5%' }}>
                <View style={[styles.tableContainer, {flex: 1, backgroundColor: colors.screen_background}]}>
                  <View style={styles.tableDiv}>
                    <TableContainer component={Paper} style={{ height: 'fit-content', width: '100%', paddingTop: '1%', backgroundColor: colors.textfield_background }}>
                      <Table>
                        <TableHead style={{borderColor: colors.border_color}}>
                          <TableRow>
                            <StyledTableCell style={{ color: colors.text, fontSize: '1.25rem', borderColor: colors.border_color, width: '35%' }}>Label</StyledTableCell>
                            <StyledTableCell style={{ color: colors.text, fontSize: '1.25rem', borderColor: colors.border_color }}>Data</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {createTable().map((row) => (
                          <StyledTableRow key={row.label}>
                            <StyledTableCell style={{ color: colors.text, fontSize: '1rem', width: '35%' }}>{row.label}</StyledTableCell>
                            <StyledTableCell style={{ color: colors.text, fontSize: '1rem' }}>{row.data}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                        {events.map((event, index) => (
                          <React.Fragment key={event.id}>
                            <StyledTableRow>
                              <StyledTableCell colSpan={2} style={{ color: colors.text, fontWeight: 'bold', padding: '0px', borderWidth: index === events.length - 1 ? '0px' : '1px'}}>
                                <Accordion
                                  sx={{
                                    boxShadow: 'none',
                                    backgroundColor: colors.textfield_background,
                                    paddingTop: '5px',
                                    paddingTop: '5px',
                                    '&:hover': {
                                      backgroundColor: colors.textfield_background_hover
                                    },
                                  }}
                                  slotProps={{
                                    transition: {
                                      unmountOnExit: true,
                                    },
                                  }}
                                  defaultExpanded={index === 0}
                                  expanded={expandedAccordions[index]}
                                  onChange={(event, isExpanded) => {
                                    setExpandedAccordions((prevState) => {
                                      const updatedState = [...prevState];
                                      updatedState[index] = isExpanded;
                                      return updatedState;
                                    });
                                  }}
                                >
                                  <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colors.text}} />}>
                                    <Typography style={{ color: colors.text, fontWeight: 'bold' }}>
                                      Submitters Entry {index + 1}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails style={{ padding: 0 }}>
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <StyledTableCell style={{ color: colors.text, width: '35%' }}>Date of Duty</StyledTableCell>
                                          <StyledTableCell style={{ color: colors.text }}>{event.date}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                          <StyledTableCell style={{ color: colors.text, borderWidth: 0, width: '35%' }}>Duty To Be Performed</StyledTableCell>
                                          <StyledTableCell style={{ color: colors.text, borderWidth: 0 }}>{event.training}</StyledTableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </AccordionDetails>
                                </Accordion>
                              </StyledTableCell>
                            </StyledTableRow>
                          </React.Fragment>
                        ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </View>
                </View>
                
                <View style={{ flexDirection: "column", alignItems: "center", flex: 1 }}>
                  <View style={styles.entryBox}>
                    <Text style={styles.approvalModalTitle}>Reviewer Actions</Text>
                    <View style={styles.checkboxContainer}>
                      <CheckBox
                        disabled={false}
                        value={isApproved}
                        onValueChange={(newValue) => {
                          changeAccept(newValue);
                        }}
                      />
                      <Text style={styles.checkboxLabel}>Approve</Text>
                      <CheckBox
                        disabled={false}
                        value={isDisapproved}
                        onValueChange={(newValue) => {
                          changeDecline(newValue);
                        }}
                        style={{ marginLeft: 20, }}
                      />
                      <Text style={styles.checkboxLabel}>Disapprove</Text>
                    </View>

                    <TextInput
                      style={styles.commentInput}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      outlineColor={colors.textfield_background}
                      activeUnderlineColor={colors.bow}
                      placeholderTextColor={colors.text}
                      selectionColor={colors.text}
                      activeOutlineColor={colors.text}
                      InputProps={{ style: { color: colors.text } }}
                      label="Comment"
                      placeholder='Explain why you approved/denied the request (Optional)'
                      inputStyle={{ color: colors.bow }}
                      multiline={true}
                      autoSize={true}
                      value={comment}
                      onChangeText={(text) => {
                        setComment(text);
                        if (text.length === 0) {
                          setCommentInputHeight(64)
                        } 
                      }}
                      onContentSizeChange={({nativeEvent: {contentSize: {width, height}}}) => {
                        if (height > 64 && height < 96) {
                          setCommentInputHeight(96);
                        } else if (height > 96 && height < 128){
                          setCommentInputHeight(128);
                        } else if (height > 128 && height < 160) {
                          setCommentInputHeight(160);
                        } else if (height > 160){
                          setCommentInputHeight(192);
                        }
                      }}
                    />
                  </View>

                  <Text style={[styles.signText, {width: '85%', marginTop: ''}]}>
                    By clicking on the "Submit" button below, you consent to your
                    digital signature being affixed to the document. Your signature can
                    be viewed below after clicking the "Sign Document" button. If you
                    don't have a signature, please add one below.
                  </Text>

                  <View style={{ width: '100%', alignItems: 'center', maringTop: '1%' }}>
                    {doesUserHaveSignature == true ? (
                      <>
                        <TouchableOpacity
                          style={[styles.button, {backgroundColor: colors.action_button}]}
                          onPress={handleSignature}
                        >
                          <Text style={{ justifyContent: "center", fontSize: 15, color: colors.text, fontWeight: 'bold' }}>
                            {viewSig ? "Document Signed" : "Sign Document"}
                          </Text>
                        </TouchableOpacity>
                        {viewSig && (
                          <>
                            <Image
                              resizeMode={"contain"}
                              style={{ width: 200, height: 100, backgroundColor: 'white' }}
                              source={{ uri: global.signature_uri }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <View style={{ width: '50%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.textStyle, { padding: 15 }]}>No signature found</Text>

                        <TextInput
                          style={styles.commentInput}
                          theme={{
                            colors: {
                              text: colors.inputted_text,
                              accent: colors.textfield_background,
                              primary: colors.inputted_text,
                              placeholder: colors.placeholder_text,
                            },
                          }}
                          label="Enter your first and last name"
                          placeholder=""
                          value={signatureName}
                          onChange={(data) => setSignatureName(data.target.value)}
                        />

                        <ViewShot ref={viewRef} style={{ padding: 10, marginVertical: 10, backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                          <Text style={{ color: 'black' }}>{signatureName}</Text>
                        </ViewShot>

                        <TouchableOpacity style={styles.button} onPress={captureTextAsImage}>
                          <Text style={styles.textStyle}>Create Signature</Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              <View style={[styles.rowContainer, { marginTop: 50 }]}>
                <TouchableOpacity style={[styles.cancelButton, {backgroundColor: colors.button_cancel}]} onPress={onCancel}>
                  <Text style={styles.textStyle}>Cancel</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.cancelButton, {marginLeft: '5%', marginRight: '5%'}]} onPress={viewFilled}>
                  <Text style={styles.textStyle}>View Form</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.button, {backgroundColor: colors.button_submit}]} onPress={onSubmit}>
                  <Text style={styles.textStyle}>Submit</Text>
                </TouchableOpacity>
              </View>
            </Scrollbars>
          ) : (
            <View style={loadingStyles.animation}>
              <Progress.CircleSnail
                color={colors.loading_circle}
                indeterminate={true}
                size={275}
                thickness={8}
              />
            </View>
          )
        }
        
      </View>
    </ThemeProvider>
  );
};

export default ReviewEBDL;
