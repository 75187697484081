import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "../../components/SnackbarContext";
import * as Progress from "react-native-progress";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { CheckBox } from "react-native-web";
import { TextInput } from "react-native-paper";

import { en, registerTranslation } from "react-native-paper-dates";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { useScale } from "../../components/scale";
import { useColorContext } from "../../components/ColorContext";
import { pdfIn } from "../generatePDF.js";
import { PDFDocument, rgb } from "pdf-lib";
import Scrollbars from "react-custom-scrollbars";
import * as SOUForm from "../../PDFs/SOU_form_raw";
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import { create_new_user_signature } from "../../api_handler/users.js";
import { put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from "../../api_handler/asyncstorage.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PropTypes from "prop-types";
import { get_all_users_in_single_unit } from "../../api_handler/units";
import { upload_file, update_file, get_single_file, get_all_user_files } from "../../api_handler/files";
import { create_notification } from "../../api_handler/notifications";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useRoute } from "@react-navigation/native";

registerTranslation("en", en);

function CreateNewSOU({ navigation }) {
  const { colors } = useColorContext();
  const scale = useScale();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const viewRef = useRef();
  const route = useRoute();
  const { draftFormID = "NONE", updatingSubmission } = route.params || {};
  const [amendFile, setAmendFile] = useState(false);

  const styles = {
    container: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      backgroundColor: colors.screen_background,
    },
    scrollView: {
      height: "100%",
      width: "100%",
      marginTop: "2%",
    },
    mainWrapper: {
      flexDirection: "column",
      alignSelf: "center",
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: "Trebuchet MS",
      flex: 1,
      color: colors.text,
      paddingLeft: "5%",
      paddingTop: 25,
    },
    commentInput: {
      minHeight: "fit-content",
      borderWidth: 1,
      borderRadius: 5,
      borderColor: colors.border_color,
      backgroundColor: colors.textfield_background,
      width: 500,
    },
    selectProps: {
      bordercolor: colors.border_color,
      MenuProps: {
        sx: {
          "& .MuiMenu-paper": {
            background: colors.textfield_background,
            color: colors.inputted_text,
            bordercolor: colors.border_color,
          },
        },
      },
    },
    dropDownStyle: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      width: "95%",
      backgroundColor: colors.textfield_background,
    },
    textInputProps: {
      "& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input": {
        color: colors.inputted_text,
      },
      ".MuiSvgIcon-root": {
        fill: colors.inputted_text,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: colors.border_color,
        },
        "&:hover fieldset": {
          borderColor: colors.border_color,
        },
        "&.Mui-focused fieldset": {
          borderColor: colors.border_color,
        },
      },
      "& .MuiFormHelperText-root": {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
  };

  const loadingStyles = {
    animation: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  const requestTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [hasSignature, setHasSignature] = useState(false);
  const [boolQs, setBoolQs] = useState(Array(boolQuestions.length).fill(false));
  const [officers, setOfficers] = useState([]);
  const [approver, setApprover] = useState(null);

  useEffect(() => {
    // Check if user has a signature
    setHasSignature(global.signature_uri != null && global.signature_uri != "");
    fetchOfficers();
  }, []);

  async function fetchOfficers() {
    const SOUOfficers = await get_all_users_in_single_unit(
      { unit_id: global.user_data.unit_id, permission: 30 },
      global.token
    );

    if (SOUOfficers.status === 200) {
      setOfficers(
        SOUOfficers.data.body.filter(
          (user) => user.user_id != global.user_data.user_id
        )
      );
    } else {
      showSnackbar("error", "Failed to fetch SOU officers");
    }
  }

  async function userHasSOU() {
    setIsLoading(true);
    const res = await get_all_user_files(
      global.user_data.user_id,
      global.token
    );
    setIsLoading(false);

    if (res.status === 200) {
      // Get Latest SOU Form
      const souForms = res.data.body.filter((f) => f["form_type"] == "SOU");
      const currentYear = new Date().getFullYear();

      for (let i = 0; i < souForms.length; i++) {
        const y = new Date(souForms[i]["timestamp"][0]).getFullYear();
        const status = souForms[i]["status"];

        if (y == currentYear && status == 4) {
          return true;
        }
      }
      return false;
    } else {
      showSnackbar(
        "error",
        "Application Error: Failed to fetch SOU information. Please try again later."
      );

      return false;
    }
  }

  useEffect(() => {
    if (checkProcessedVariables()) {
      loadDraftForm();
    }
  }, [draftFormID, officers]);

  const checkProcessedVariables = () => {
    return (
        draftFormID != "NONE" &&
        draftFormID !== undefined
    );
  };

  async function loadDraftForm() {
    setIsLoading(true);
    const formIDandType = { form_id: draftFormID, form_type: "SOU" };
    try {
      // Check all the checkmarks
      setBoolQs(Array(boolQuestions.length).fill(true));
      
      // Find approver
      const fileData = await get_single_file(formIDandType, global.token);
      const approverId = fileData.body.reviewer_ids[0];
      const newApprover = officers.find((o) => o.user_id == approverId);
      console.log(fileData);
      if (newApprover) setApprover(newApprover);

      setAmendFile(true);
      console.log(fileData);
    } catch (error) {
        console.error("Error loading draft form:", error);
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={requestTheme}>
      <View style={[styles.container, { cursor: isLoading ? "wait" : "auto" }]}>
        <Scrollbars style={styles.scrollView}>
          <View style={styles.mainWrapper}>
            <Text style={styles.pageTitle}>New SOU</Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                flexWrap: "wrap",
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "2%",
                paddingBottom: "7%",
                gap: "5%",
              }}
            >
              {/* ----------------------- Left Column ---------------------------- */}
              <View
                style={{
                  flexDirection: "column",
                  maxWidth: 800,
                  minWidth: 300,
                  marginBottom: "5%",
                }}
              >
                <Text
                  style={{
                    fontWeight: 550,
                    fontSize: 18,
                    marginBottom: "2%",
                    color: colors.bow,
                  }}
                >
                  I understand that I am being directed to:
                </Text>
                <View
                  style={{
                    flexDirection: "column",
                    maxWidth: 900,
                    minWidth: 800,
                    height: "fit-content",
                    borderColor: colors.border_color,
                    borderWidth: 1,
                    borderRadius: 5,
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.25,
                    shadowRadius: 4,
                    backgroundColor: colors.modal_background,
                    overflow: "hidden",
                  }}
                >
                  <Table>
                    <TableBody>
                      {boolQuestions.map((q, i) => (
                        <BoolRow
                          key={i}
                          label={q}
                          onChange={() => {
                            const newBoolQs = [...boolQs];
                            newBoolQs[i] = !newBoolQs[i];
                            setBoolQs(newBoolQs);
                          }}
                          checked={boolQs[i]}
                          colors={colors}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </View>
              </View>

              {/* ----------------------- Right Column --------------------------- */}
              <View
                style={{
                  minWidth: 300,
                  flexDirection: "column",
                  marginTop: "2%",
                }}
              >
                <View style={{ gap: 30 }}>
                  <ApproverDropdown />
                  <SignatureArea
                    hasSigned={hasSigned}
                    hasSignature={hasSignature}
                    signatureName={signatureName}
                    setSignatureName={setSignatureName}
                    styles={styles}
                    colors={colors}
                    Button={Button}
                    addSignature={addSignature}
                    sign={sign}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      gap: "2%",
                    }}
                  >
                    <Button
                      onClick={viewBlankSOUForm}
                      label="View Blank"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                    <Button
                      onClick={() => {
                        createSOUForm(boolQs, hasSigned);
                      }}
                      label="View Filled"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                    <Button
                      label="Submit"
                      onClick={submit}
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );

  function Button({
    label,
    bgColor = colors.gobl_for_checkbox,
    txtColor = colors.bow,
    disabled = false,
    onClick = () => {},
  }) {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: bgColor,
          borderRadius: 5,
          borderColor: colors.border_color,
          borderWidth: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: 10,
          paddingVertical: 5,
          borderColor: colors.border_color,
          color: colors.text,
          opacity: disabled ? 0.35 : 1,
        }}
        onPress={disabled ? () => {} : onClick}
      >
        <Text
          style={{
            color: txtColor,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  }

  function ApproverDropdown() {
    return (
      <FormControl
        fullWidth
        style={styles.dropDownStyle}
        sx={{ ...styles.textInputProps, justifyContent: "center" }}
      >
        <InputLabel id="demo-simple-select-label">
          Select An Approver
        </InputLabel>
        <Select
          inputProps={styles.selectProps}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={approver || ""}
          label="Select An Approver"
          onChange={(e) => setApprover(e.target.value)}
        >
          {officers.map((o) => (
            <MenuItem key={o.user_id} value={o}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  async function captureTextAsImage() {
    try {
      const fullname =
        global.user_data.first_name.toLowerCase() +
        " " +
        global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: "png",
          quality: 0.8,
        });

        let ret = await create_new_user_signature(
          { user_id: global.user_data.user_id },
          global.token
        );
        if (ret.status === 200) {
          ret = ret.data;

          await put_image_s3(
            "signatures/" + ret.body.signature_id,
            uri,
            "image/png",
            global.token
          );

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem("signature", global.signature_uri);
          storeJsonData("user_data", global.user_data);

          setHasSignature(true);
        } else {
          showSnackbar(
            "error",
            "Application Error: Failed to create your signature"
          );
        }
      } else {
        showSnackbar(
          "error",
          "Input Error: The name inputted does not match your name we have on record!"
        );
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  }

  function addSignature() {
    // Add signature to s3
    captureTextAsImage();
  }

  function sign() {
    setHasSigned(true);
  }

  async function submit() {
    // Not Signed
    if (!hasSigned) {
      showSnackbar("error", "Please sign the document before submitting.");
      return;
    }

    // Not all checked
    for (const q in boolQs) {
      if (!boolQs[q]) {
        showSnackbar(
          "error",
          "All boxes must be checked in order to submit an SOU."
        );
        return;
      }
    }

    // No approver
    if (!approver) {
      showSnackbar("error", "Please select an approver.");
      return;
    }

    // Check for existing SOU in the past year
    if (await userHasSOU()) {
      showSnackbar("error", "You already have an SOU in this fiscal year");
      return;
    }

    const formData = {
      name_and_rank: getRankName(),
      soldier_signature_id: global.user_data.signature_id,
      date: getTodaysDate(),
      creator_id: global.user_data.user_id,
      reviewer_id: approver.user_id,
      reviewer_signature_id: approver.user_id,
      request_is_approved: false,
      request_is_approved_date: null,
      unit_name: global.user_data.unit_name,
      command_name: null,
      distance: null,
      creator_id: global.user_data.user_id,
      comment: "",
      form_name: getRankName(),
      form_type: "SOU",
      request_type: "SOU",
      reviewer_ids: [approver.user_id],
      recommender_id: approver.user_id,
      status: 1,
      unit_id: global.user_data.unit_id,
    };

    setIsLoading(true);
    if (amendFile) {
      update_file({...formData, form_id: draftFormID, attachments: null, form_type: "SOU"}, global.token).then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.body === "File successfully updated") {
          showSnackbar("success", "SOU Form updated successfully");
          sendNotifToApprover(approver.user_id, draftFormID);
          resetState();
          navigation.navigate("Home");
        } else {
          showSnackbar("error", "Failed to update SOU.");
          return false;
        }
      });
    }
    else {
      upload_file(formData, global.token).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          showSnackbar("success", "SOU Form submitted successfully");
          sendNotifToApprover(approver.user_id, res.data.form_id);
          resetState();
          navigation.navigate("Home");
        } else {
          showSnackbar("error", "Failed to create SOU. Please try again later");
        }
      });
    }
  }

  async function sendNotifToApprover(approver_id, form_id) {
    const data = {
      title: "SOU Form",
      body: "You have one new SOU form to review",
      receiver_id: approver_id,
      sender_id: global.user_data.user_id,
      notification_type: "file",
      object_type: "review SOU form",
      form_events_id: form_id,
    };

    setIsLoading(true);

    const res = await create_notification(data, global.token);
    if (res.status !== 200) {
      showSnackbar(
        "success",
        "Your Reviewer has been notified",
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar(
            "error",
            "Application Error: Failed to send notification"
          );
        }
      );
    }

    setIsLoading(false);
  }

  function BoolRow({ label, onChange, checked, value_color = colors.bow }) {
    return (
      <TableRow
        sx={{
          boxShadow: "none",
          backgroundColor: colors.textfield_background,
          "&:hover": {
            backgroundColor: colors.textfield_background_hover,
          },
        }}
      >
        <TableCell>
          <CheckBox
            value={checked}
            style={{ margin: 10, marginRight: 15 }}
            onValueChange={onChange}
          />
        </TableCell>
        <TableCell>
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text style={{ color: value_color }}>{label}</Text>
          </View>
        </TableCell>
      </TableRow>
    );
  }

  function resetState() {
    setIsLoading(false);
    setHasSigned(false);
    setSignatureName("");
    setHasSignature(false);
    setBoolQs(Array(boolQuestions.length).fill(false));
    setOfficers([]);
    setApprover(null);
  }
}

CreateNewSOU.propTypes = {
  navigation: PropTypes.object.isRequired,
};

const boolQuestions = [
  "Abide by all rules and regulations with respect to the card.",
  "Use the card only for official travel validated by travel orders or authorized IDT airfare.",
  "Pay all my charges so that payment is received by the contractor within 30 days of the closing date indicated on the monthly billing statement.",
  "Contact my program coordinator immediately if, due to disbursing office process delays, I have not received payment by the time the card contractor's bill is due.",
  "Notify my program coordinator of any problems with respect to my use of the card.",
  "Notify the Card contractor and my program coordinator if my card or travelers checks are lost or stolen.",
];

const SignatureArea = React.memo(function SignatureArea({
  hasSigned,
  hasSignature,
  signatureName,
  styles,
  colors,
  Button,
  addSignature,
  setSignatureName,
  sign,
}) {
  return (
    <View>
      {hasSignature ? (
        hasSigned ? (
          <Image
            resizeMode={"contain"}
            style={{
              width: 200,
              height: 100,
            }}
            source={{ uri: global.signature_uri }}
          />
        ) : (
          <></>
        )
      ) : (
        // Add Signature
        <View
          style={{
            flexDirection: "column",
            gap: 30,
          }}
        >
          {signatureName == "" ? (
            <Text
              style={{
                opacity: 0.7,
                width: 500,
                fontStyle: "italic",
                color: "red",
              }}
            >
              No signature found. Please add one below.
            </Text>
          ) : (
            <ViewShot
              ref={viewRef}
              style={{
                padding: 10,
                marginVertical: 10,
                backgroundColor: "white",
              }}
              options={{ format: "png", quality: 1 }}
            >
              <Text style={{ color: "black", fontSize: scale(30) }}>
                {signatureName}
              </Text>
            </ViewShot>
          )}

          <TextInput
            style={styles.commentInput}
            theme={{
              colors: {
                text: colors.inputted_text,
                accent: colors.textfield_background,
                primary: colors.inputted_text,
                placeholder: colors.placeholder_text,
              },
            }}
            selectionColor={"black"}
            placeholder="e.g. John Doe"
            label={`First and Last Name`}
            activeOutlineColor={"black"}
            value={signatureName}
            onChangeText={(data) => setSignatureName(data)}
          />

          <View style={{ width: 128 }}>
            <Button label="Add Signature" onClick={addSignature} />
          </View>
        </View>
      )}

      {/* Buttons */}
      <View style={{ width: 128, marginBottom: 30 }}>
        <Button
          label="Sign Document"
          disabled={!hasSignature}
          onClick={sign}
          bgColor={colors.gobl}
          txtColor={colors.bow}
        />
      </View>
      <Text
        style={{
          opacity: 0.7,
          width: 500,
          fontStyle: "italic",
          color: colors.bow,
        }}
      >
        By clicking on the "Submit" button below, you consent to your digital
        signature being affixed to the document. Your signature can be viewed
        below after clicking the "Sign Document" button. If you don't have a
        signature, please add one below.
      </Text>
    </View>
  );
});

async function viewBlankSOUForm() {
  const pdf = await PDFDocument.load(SOUForm.pdf());
  const form = pdf.getForm();

  form.flatten();
  const bytes = await pdf.saveAsBase64();
  pdfIn(bytes);
}

async function createSOUForm(checked, hasSigned) {
  const pdf = await PDFDocument.load(SOUForm.pdf());
  const form = pdf.getForm();
  const page = pdf.getPages()[0];

  let field;

  // width: 612, height: 792

  const textboxStyle = {
    textColor: rgb(0, 0, 0),
    backgroundColor: rgb(1, 1, 1),
    borderColor: rgb(1, 1, 1),
  };

  // Rank and full name
  field = form.createTextField("rank_full_name");
  field.setText(
    global.user_data.rank +
      " " +
      global.user_data.first_name +
      " " +
      global.user_data.last_name
  );
  field.addToPage(page, {
    x: 210,
    y: 510,
    width: 85,
    height: 15,
    ...textboxStyle,
  });

  // Initials
  const initials =
    global.user_data.first_name[0] + global.user_data.last_name[0];
  const yLevels = [475, 450, 425, 386, 350, 322];

  yLevels.forEach((yLevel, index) => {
    if (!checked[index]) return;
    field = form.createTextField("initial" + index);
    field.setText(initials);
    field.addToPage(page, {
      x: 533,
      y: yLevel,
      width: 20,
      height: 15,
      ...textboxStyle,
    });
  });

  // Applicant's signature and date
  if (global.signature_uri != null && hasSigned) {
    const img = await pdf.embedPng(global.signature_uri);

    page.drawImage(img, {
      x: 120,
      y: 165,
      width: 100,
      height: 25,
    });

    field = form.createTextField("applicant_signature_date1");
    field.setText(getTodaysDate());
    field.addToPage(page, {
      x: 220,
      y: 170,
      width: 100,
      height: 15,
      ...textboxStyle,
    });
  }

  // Applicant's printed name and date
  field = form.createTextField("applicant_printed_name");
  field.setText(global.user_data.first_name + " " + global.user_data.last_name);
  field.addToPage(page, {
    x: 120,
    y: 120,
    width: 100,
    height: 25,
    ...textboxStyle,
  });

  form.flatten();
  const bytes = await pdf.saveAsBase64();
  pdfIn(bytes);
}

function getTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}

function getRankName() {
  if (
    global.user_data.middle_name == null ||
    global.user_data.middle_name == ""
  ) {
    return (
      global.user_data.rank +
      " " +
      global.user_data.first_name +
      " " +
      global.user_data.last_name
    );
  } else {
    return (
      global.user_data.rank +
      " " +
      global.user_data.first_name +
      " " +
      global.user_data.middle_name +
      " " +
      global.user_data.last_name
    );
  }
};

export default CreateNewSOU;
