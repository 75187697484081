/* eslint-disable brace-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable padded-blocks */
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
/* eslint-disable object-curly-spacing */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Touchable,
  Dimensions
} from 'react-native';
import { Scrollbars } from 'react-custom-scrollbars';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import { FaCheckCircle, FaCircle, FaTimesCircle } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa'; 
import { useScale } from '../../components/scale';
import { subordinateFiles } from './LeaderAdminFiles';
import Menu from '@mui/material/Menu';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { generatePDF } from '../generatePDF';
import { useColorContext } from '../../components/ColorContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NavSideBar from '../../components/NavSideBar';
import IconButton from '@mui/material/IconButton';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { MaterialReactTable, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, MRT_FullScreenToggleButton } from 'material-react-table';
import { useTheme, createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ModeContext from '../../components/ModeContext';
import CustomRadioTabs from '../../components/CustomRadioTabs';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { Hidden, Tooltip as MUITooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useIsFocused } from '@react-navigation/native';
import Checkbox from '@mui/material/Checkbox'
import { useSnackbar } from '../../components/SnackbarContext';
import * as Progress from 'react-native-progress'

export default function LeaderAdminOv({ navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const isFocused = useIsFocused();
  const { mode } = React.useContext(ModeContext);
  const [docData, setdocData] = useState(1);
  const isLight = (mode === 'light');
  const [loaded, setLoaded] = useState(false);
  const tableInstanceRef = useRef(null);
  const [fy, setFy] = useState(-1);
  const [selectedFy, setSelectedFy] = useState("");
  const [selectedFyForms, setSelectedFyForms] = useState([]);
  const [currentFyForms, setCurrentFyForms] = useState([]);
  const [upcomingFyForms, setUpcomingFyForms] = useState([]);
  const [prevFyForms, setPrevFyForms] = useState([]);

  const [showTimeline, setShowTimeline] = useState(false);

  const [unitSelection, setUnitSelection] = useState([]);
  const [selectedId, setSelectedId] = React.useState(null);

  // Renaming the state variable to avoid conflict
  const [documents, setDocuments] = useState([]);
  const [medianProcessingTime, setMedianProcessingTime] = useState(0);
  const [maxProcessingTime, setMaxProcessingTime] = useState(0);
  const [percentagePendingApproval, setPercentagePendingApproval] = useState(0);
  const [percentagePendingPay, setPercentagePendingPay] = useState(0);
  const [percentageAwaitingCorrections, setPercentageAwaitingCorrections] = useState(0);



// Helper function to extract the date from a formatted string like "Submitted: 08/18/24 17:39 EDT"
function extractDateFromString(statusType, timestampString) {
  const regexMap = {
    'Submitted': /Submitted:\s([0-9\/]+\s[0-9:]+\s(?:AM|PM|EDT|PDT|CST|EST|UTC|GMT)+)/,
    'Recommended': /Recommended:\s([0-9\/]+\s[0-9:]+\s(?:AM|PM|EDT|PDT|CST|EST|UTC|GMT)+)/,
    'Approved': /Approved:\s([0-9\/]+\s[0-9:]+\s(?:AM|PM|EDT|PDT|CST|EST|UTC|GMT)+)/,
    'Paid': /Paid:\s([0-9\/]+\s[0-9:]+\s(?:AM|PM|EDT|PDT|CST|EST|UTC|GMT)+)/,
    'Denied': /Denied:\s([0-9\/]+\s[0-9:]+\s(?:AM|PM|EDT|PDT|CST|EST|UTC|GMT)+)/
  };

  const regex = regexMap[statusType];
  const match = regex ? timestampString.match(regex) : null;

  if (match && match[1]) {
    return new Date(match[1]);  // Return the extracted date string as a Date object
  } else {
    // console.log(`No match found for ${statusType} in ${timestampString}`);
    return null;
  }
}

function calculateTimeDifference(startDate, endDate) {
  if (!startDate || !endDate) return 'N/A';  // Ensure we don't crash if dates are invalid

  const differenceInMilliseconds = new Date(endDate) - new Date(startDate);
  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
  return `${differenceInDays} days`;
}

// Helper function to calculate the difference between two dates in milliseconds
function getTimeDifferenceInMillis(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Validate if both dates are valid
  if (isNaN(startDate) || isNaN(endDate)) {
     // console.log("Invalid date:", { start, end });  // Log the invalid date values
    return null;  // Return null if either date is invalid
  }

  return endDate - startDate;  // Return difference in milliseconds
}

// Function to calculate percentage of documents that were denied (awaiting corrections)
function calculatePercentageAwaitingCorrections(documents) {
  const totalDocuments = documents.length;  // Total number of documents
  const deniedCount = documents.filter(doc => {
    // Check if the document contains the 'Denied' status in its timestamp string
    const deniedDate = extractDateFromString('Denied', doc.timestamp);
    return deniedDate !== null;  // Only count documents that have been denied
  }).length;

  // Return the percentage of denied documents
  return totalDocuments > 0 ? (deniedCount / totalDocuments) * 100 : 0;
}

function millisToDays(milliseconds) {
  return milliseconds / (1000 * 60 * 60 * 24);  // Convert milliseconds to days
}

function calculateMedianProcessingTime(documents) {
  const processingTimes = documents
    .filter(doc => typeof doc.timestamp === 'string')  // Ensure timestamp is a string
    .map(doc => {
       // console.log("Document Timestamps: ", doc.timestamp);  // Log the timestamp string to check if it's being read properly
      
      // Extract the "Submitted", "Approved", and "Denied" dates
      const submittedDate = extractDateFromString('Submitted', doc.timestamp);
      const approvedOrDeniedDate = extractDateFromString('Approved', doc.timestamp) || extractDateFromString('Denied', doc.timestamp);

      if (!submittedDate || !approvedOrDeniedDate) {
        return null;  // Return null if either date is not found to exclude from processing
      }

      const timeDiff = getTimeDifferenceInMillis(submittedDate, approvedOrDeniedDate);
      return timeDiff !== null ? millisToDays(timeDiff) : null;
    })
    .filter(time => time !== null);  // Filter out null values

  if (processingTimes.length === 0) return 0;

  processingTimes.sort((a, b) => a - b);

  const mid = Math.floor(processingTimes.length / 2);
  return processingTimes.length % 2 !== 0 ? processingTimes[mid] : (processingTimes[mid - 1] + processingTimes[mid]) / 2;
}

function calculateMaxProcessingTime(documents) {
  const processingTimes = documents
    .filter(doc => typeof doc.timestamp === 'string')  // Ensure timestamp is a string
    .map(doc => {
      const submittedDate = extractDateFromString('Submitted', doc.timestamp);
      const approvedOrDeniedDate = extractDateFromString('Approved', doc.timestamp) || extractDateFromString('Denied', doc.timestamp);

      if (!submittedDate || !approvedOrDeniedDate) {
        return null;  // Return null if either date is not found
      }

      const timeDiff = getTimeDifferenceInMillis(submittedDate, approvedOrDeniedDate);
      return timeDiff !== null ? millisToDays(timeDiff) : null;
    })
    .filter(time => time !== null);  // Filter out null values

  return processingTimes.length > 0 ? Math.max(...processingTimes) : 0;
}

  // Function to calculate percentage of pending approval
function calculatePercentagePendingApproval(documents) {
  const totalDocuments = documents.length;
  const pendingApprovalCount = documents.filter(doc => doc.status === 4).length;
  return totalDocuments > 0 ? (pendingApprovalCount / totalDocuments) * 100 : 0;
}

// Function to calculate percentage pending pay
function calculatePercentagePendingPay(documents) {
  const totalDocuments = documents.length;
  const pendingPayCount = documents.filter(doc => doc.status === 6).length;
  return totalDocuments > 0 ? (pendingPayCount / totalDocuments) * 100 : 0;
}

// Function to calculate percentage awaiting connections (assumed to be status 1)
function calculatePercentageAwaitingConnections(documents) {
  const totalDocuments = documents.length;
  const awaitingConnectionsCount = documents.filter(doc => doc.status === 1).length;
  return totalDocuments > 0 ? (awaitingConnectionsCount / totalDocuments) * 100 : 0;
}

useEffect(() => {
  if (rows.length > 0) {
    // Calculate the values using rows
    const medianProcessingTime = calculateMedianProcessingTime(rows).toFixed(2);
    const maxProcessingTime = calculateMaxProcessingTime(rows).toFixed(2);
    const percentagePendingApproval = calculatePercentagePendingApproval(rows).toFixed(2);
    const percentagePendingPay = calculatePercentagePendingPay(rows).toFixed(2);
    const percentageAwaitingCorrections = calculatePercentageAwaitingCorrections(rows).toFixed(2);

    /*
    console.log("Median Processing Time: ", medianProcessingTime);
    console.log("Max Processing Time: ", maxProcessingTime);
    console.log("Percentage Pending Approval: ", percentagePendingApproval);
    console.log("Percentage Pending Pay: ", percentagePendingPay);
    console.log("Percentage Awaiting Corrections: ", percentageAwaitingCorrections);
    */

    // Set these values in your state if you want to display them in your UI
    setMedianProcessingTime(medianProcessingTime);  // Set this to your state
    setMaxProcessingTime(maxProcessingTime);        // Set this to your state
    setPercentagePendingApproval(percentagePendingApproval);  // Set this to your state
    setPercentagePendingPay(percentagePendingPay);  // Set this to your state
    setPercentageAwaitingCorrections(percentageAwaitingCorrections);  // Set this to your state
  }
}, [rows]);



  // Search Bar
  // const [unitType, setUnitType] = useState('All Units');
  const [unitType, setUnitType] = useState(['All Units']);
  const [formType, setFormType] = useState(['All Form Types']);
  const [statusType, setStatusType] = useState(['All Statuses']);
  const [prevDateType, setPrevDateType] = useState('30 Days');
  const [dateType, setDateType] = useState('30 Days');

  // original form type counts with no filters and All Time date range, this is so we can bounce between filtered and unfiltered counts
  const [masterFileSummary, setMasterFileSummary] = useState([]);
  const [masterCountRST, setMasterCountRST] = useState([]);
  const [masterCount1380, setMasterCount1380] = useState([]);
  const [masterCountEBDL, setMasterCountEBDL] = useState([]);
  const [masterCountATA, setMasterCountATA] = useState([]);
  const [masterCountMDR, setMasterCountMDR] = useState([]);

  // These are what we display, again the above is what we default to without filters
  const [fileSummary, setFileSummary] = useState([]);
  const [countRST, setCountRST] = useState([]);
  const [count1380, setCount1380] = useState([]);
  const [countEBDL, setCountEBDL] = useState([]);
  const [countATA, setCountATA] = useState([]);
  const [countMDR, setCountMDR] = useState([]);
  const [legendContainerWidth, setLegendContainerWidth] = useState(null);
  const [outermostLegendContainerHeight, setOutmostLegendContainerHeight] = useState(null);
  const legendContainerRef = useRef(null);

  const handleLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setOutmostLegendContainerHeight(height);
  }

  const DocTypes = [
    {
      index: 0,
      label: 'All Form Types'
    },
    {
      index: 1,
      label: 'RST'
    },
    {
      index: 2,
      label: '1380 AFTP'
    },
    {
      index: 3,
      label: '1380'
    },
    {
      index: 4,
      label: 'ATA'
    },
    {
      index: 5,
      label: 'EBDL'
    },
    {
      index: 6,
      label: 'MDR'
    },
    {
      index: 7,
      label: 'Dental'
    },
  ];

  const DateTypes = [
    {
      index: 0,
      label: '30 Days'
    },
    {
      index: 1,
      label: '60 Days'
    },
    {
      index: 2,
      label: '90 Days'
    },
    {
      index: 3,
      label: 'All Time'
    },
  ]

  const StatusTypes = [
    {
      index: 0,
      label: 'All Statuses'
    },
    {
      index: 1,
      label: 'Submitted'
    },
    {
      index: 2,
      label: 'Recommendation Approved'
    },
    {
      index: 3,
      label: 'Recommendation Denied'
    },
    {
      index: 4,
      label: 'Approved'
    },
    {
      index: 5,
      label: 'Denied'
    },
    {
      index: 6,
      label: 'Paid'
    },
  ];


  const scale = useScale();
  const styles = StyleSheet.create({
    container: {
      width: '87%',
      backgroundColor: colors.screen_background,
      flex: 10,
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    pageTopSection: {
      flexDirection: 'row',
      width: '90%',
      alignSelf: 'center',
      marginLeft: '5%',
      marginTop: '2.5%',
    },
    pageInfoText: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'left',
      paddingTop: 25,
      color: colors.text
    },
    centeredPageInfoText: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'center',
      paddingTop: 25,
      color: colors.text
    },
    tableContainer: {
      justifyContent: 'center',
      marginVertical: '2%',
      alignSelf: 'center',
      marginHorizontal: '5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      paddingTop: 25,
      fontWeight: 'bold',
      marginHorizontal: 5,
      textAlign: 'Left',
      color: colors.screen_title,
    },
    filtersContainer: {
      marginTop: '5%',
      paddingHorizontal: '2%'
    },
    filtersRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '2%',
    },
    unitsFilter: {
      flex: 1,
      margin: '2%',
    },
    textbox: {
      width: '100%',
      backgroundColor: colors.textfield_background,
    },
    listItemTextTypography: {
      fontSize: '16px',
    },
    chartSelector: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-evenly',
      textAlign: 'center',
      borderBottomWidth: 2,
      borderColor: '#D9D9D9',
      margin: 25,
    },
    pieButton: {
      flex: 0.25,
    },
    pieButtonPressed: {
      borderBottomWidth: 4,
      borderColor: '#A98C42',
    },
    doc: {
      margin: 15,
      width: 40,
      height: 40,
    },
    legendContainer: {
      backgroundColor: colors.leader_dash_legend_container,
      borderRadius: 20,
      flex: 3,
      alignItems: 'center',
      marginTop: '2.5%',
      fontFamily: 'Trebuchet MS',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    chartButtons: {
      width: '99%',
      alignContent: 'center'
    },
    legend: {
      color: colors.bow,
      alignItems: 'left',
    },
    metricsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: '3%',
      paddingHorizontal: '2%',
    },
    metricBox: {
      flex: 1,
      alignItems: 'center',
      padding: 10,
      backgroundColor: colors.card_background, // Ensure colors.card_background is defined
      borderRadius: 10,
      marginHorizontal: '1%',
      borderColor: colors.border_color, // Ensure colors.border_color is defined
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    metricTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: colors.text, // Ensure colors.text is defined
    },
    metricValue: {
      fontSize: 24,
      fontWeight: 'bold',
      color: colors.text, // Ensure colors.highlight is defined
      marginTop: 5,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10,
      borderBottomWidth: 1,
      borderColor: '#ccc',
    },
    timelineContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',  // Ensures circles and lines align vertically
      paddingHorizontal: 20,
      width: '100%',
      position: 'relative',  // To allow absolute positioning of the line
    },
    timelineStepContainer: {
      flex: 1,  // Ensure each step takes equal space
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',  // So the line stays on the same axis
    },
    timelineStep: {
      alignItems: 'center',
      zIndex: 1,  // Ensure the circles appear above the line
    },
    circle: {
      width: 24,
      height: 24,
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderWidth: 2,
      zIndex: 1,  // Make sure circles stay above the line
    },
    completedCircle: {
      borderColor: 'green',
    },
    incompleteCircle: {
      borderColor: 'gray',
    },
    label: {
      textAlign: 'center', // Ensure the text is centered beneath each circle
      width: 100,
      marginTop: 12, // Adjusted margin to ensure spacing between circle and label
      fontSize: 14,
      color: colors.text,
      textAlign: 'center',
    },
    timelineLineContainer: {
      position: 'absolute',
      left: '5%', // Start closer to the first circle
      right: '5%', // End closer to the last circle
      top: 11, // Adjust to align with the circles
      height: 2,
      zIndex: 0, // Keep the line behind the circles
    },
    timelineLine: {
      width: '100%',
      height: 2,
      backgroundColor: 'green',  // Line color
    },
    completedLine: {
      backgroundColor: 'green',
    },
    incompleteLine: {
      backgroundColor: 'gray',
    },
    timeDifferenceContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    timeDifferenceOval: {
      backgroundColor: 'green',
      borderRadius: 20,
      paddingVertical: 4,
      paddingHorizontal: 10,
    },
    timeDifferenceText: {
      color: 'white',
      fontSize: 12,
      fontWeight: 'bold',
    },
  });

  const [pieSelection, setpieSelection] = useState(rstData);
  const [pieColorSelection, setpieColors] = useState(palette2);
  const [activeChartButton, setActiveChartButton] = useState(0);

  // If we aren't showing the Recommend statuses, this makes the other two columns on Totals tab wider
  const columns1And3Width = (formType.includes('RST') || formType.includes('All Form Types')) ? '30.5%' : '45%';
  const columns1And3Align = (formType.includes('RST') || formType.includes('All Form Types')) ? 'flex-start' : 'flex-center';
  const totalsAreaWidth = (formType.includes('RST') || formType.includes('All Form Types')) ? '100%' : '65%';

// Rendering Timeline 
function renderTimeline(steps, doc) {
  const [hoveredStep, setHoveredStep] = useState(null);

  // Function to calculate the style for each circle based on its state and hover status
  function getCircleStyle(step, isHovered) {
    let borderColor;
    let backgroundColor = 'white';

    if (step.title === 'Denied') {
      borderColor = 'red';  // Red border for Denied
    } else if (step.completed) {
      borderColor = 'green';  // Green border for completed
    } else {
      borderColor = 'gray';  // Gray border for incomplete
    }

    return {
      transition: 'all 0.3s ease-in-out',  // Smooth transition for hover effect
      transform: isHovered ? 'scale(1.2)' : 'scale(1)',  // Scale up on hover
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      borderWidth: 2,
      borderRadius: 12,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }

  // Render each event step (circle with label and hover functionality)
  const renderEventStep = (step, index) => {
    const eventTimestamp = extractDateFromString(step.title, doc.timestamp);
    const isHovered = hoveredStep === index;

    return (
      <View
        key={index}
        style={styles.timelineStepContainer}
        onMouseEnter={() => setHoveredStep(index)}  // Set the hovered step
        onMouseLeave={() => setHoveredStep(null)}   // Clear the hovered step
      >
        <MUITooltip 
          title={eventTimestamp ? `${step.title}: ${eventTimestamp.toLocaleString()}` : 'No timestamp available'} 
          arrow
        >
          <View style={styles.timelineStep}>
            <View style={getCircleStyle(step, isHovered)}>
              {step.completed && !(step.title === 'Denied') && <FaCheck size={12} color="green" />}  {/* Checkmark for completed */}
              {step.completed && step.title === 'Denied' && <FaTimes size={12} color="red" />}  {/* Cross for denied */}
            </View>
            <Text style={styles.label}>{step.title}</Text>
          </View>
        </MUITooltip>
      </View>
    );
  };

  // Render time difference between two events and color-code based on the number of days
  const renderTimeDifference = (startStep, endStep) => {
    const timeDifferenceInDays = calculateTimeDifference(
      extractDateFromString(startStep.title, doc.timestamp),
      extractDateFromString(endStep.title, doc.timestamp)
    );

    // Color coding based on the number of days
    let backgroundColor = 'green';  // Default to green
    if (timeDifferenceInDays !== 'N/A') {
      const days = parseInt(timeDifferenceInDays.split(' ')[0]);  // Extract the number of days

      if (days >= 4) {
        backgroundColor = 'red';  // Red for 4 or more days
      } else if (days >= 2) {
        backgroundColor = 'orange';  // Orange for 2 to 3 days
      }
    }

    return (
      <View style={styles.timeDifferenceContainer}>
        <View style={[styles.timeDifferenceOval, { backgroundColor }]}>
          <Text style={styles.timeDifferenceText}>{timeDifferenceInDays}</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.timelineContainer}>
      {/* Line connecting the circles */}
      <View style={[
        styles.timelineLineContainer,
        {
          left: `${100 / (steps.length * 2)}%`,  // Start closer to the first event
          right: `${100 / (steps.length * 2)}%`, // End closer to the last event
        }
      ]}>
        <View style={styles.timelineLine} />  {/* Connecting line */}
      </View>

      {/* Render event steps and time differences */}
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {renderEventStep(step, index)}
          
          {/* Render the time difference oval between two consecutive steps */}
          {index < steps.length - 1 && renderTimeDifference(steps[index], steps[index + 1])}
        </React.Fragment>
      ))}
    </View>
  );
}

  
  
  
  
  
  // Get all events for the timeline
  function getTimelineSteps(document) {
    const steps = [];
  
    // Check if the "Submitted" step exists
    const submittedDate = extractDateFromString("Submitted", document.timestamp);
    if (submittedDate) {
      steps.push({
        title: 'Submitted',
        completed: true,  // Event occurred
      });
    }
  
    // Check if the "Recommended" step exists
    const recommendedDate = extractDateFromString("Recommended", document.timestamp);
    if (recommendedDate) {
      steps.push({
        title: 'Recommended',
        completed: true,  // Event occurred
      });
    }
  
    // Check if the "Denied" step exists
    const deniedDate = extractDateFromString("Denied", document.timestamp);
    if (deniedDate) {
      steps.push({
        title: 'Denied',
        completed: true,  // Event occurred
      });
    }
  
    // Check if the "Approved" step exists, only add if submitted has occurred
    const approvedDate = extractDateFromString("Approved", document.timestamp);
    if (approvedDate && submittedDate) {
      steps.push({
        title: 'Approved',
        completed: true,  // Event occurred
      });
    }
  
    // Check if the "Paid" step exists, only add if approved has occurred
    const paidDate = extractDateFromString("Paid", document.timestamp);
    if (paidDate && approvedDate) {
      steps.push({
        title: 'Paid',
        completed: true,  // Event occurred
      });
    }
  
    return steps;  // Return only the steps that have occurred
  }
  
  
  
  
  // Renders a list of documents
  const DocumentList = ({ documents }) => {
    return (
      <ScrollView>
        {documents.map((doc, index) => {
          const steps = getTimelineSteps(doc);
          return (
            <View key={index} style={styles.row}>
              <Text>{doc.fileName}</Text>
              <Text>{doc.unitName}</Text>
              <Text>{doc.status}</Text>
              {renderTimeline(steps, doc)} {/* Render the timeline */}
            </View>
          );
        })}
      </ScrollView>
    );
  };  
  
  // button to select legend item
  const ChartSelectorButtons = () => {
    const buttonData = [
      { label: 'Totals', index: 0 },
      { label: 'RST', data: rstData, palette: palette2, index: 1 },
      { label: '1380', data: data1380, palette: palette2, index: 2 },
      { label: 'EBDL', data: dataEBDL, palette: palette2, index: 3 },
      { label: 'ATA', data: dataATA, palette: palette2, index: 4 },
      { label: 'MDR', data: dataMDR, palette: palette2, index: 5 },
    ];

    return (
      <View style={styles.chartSelector}>
        {buttonData.map((button) => ( 
          (formType.includes('All Form Types') || button.label === 'Totals' || (formType.includes('1380 AFTP') && button.label === '1380') || formType.includes(button.label)) && <TouchableOpacity
            key={button.index}
            style={[
              styles.pieButton,
              activeChartButton === button.index && styles.pieButtonPressed,
            ]}
            onPress={() => {
              pieSelector(button.data, button.palette, button.index);
            }}
          >
            <Text
              style={{
                color: colors.bow,
              }}
            >
              {button.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  const LegendItem = () => {
    if (activeChartButton === 0) {
      return (
        <View style={{ width: totalsAreaWidth, flexDirection: 'row', marginTop: '5%', justifyContent: 'center'}}>
          {/* Column 1 - Pending Review & Denied */}
          <View style={{ alignItems: columns1And3Align, width: columns1And3Width}}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginRight: '1%' }}>
              <Image style={styles.doc} defaultSource={require('../../assets/doc_yellow.png')} />
              <Text style={[styles.legend, { fontSize: 17 }]}>Pending Review ({fileSummary[0]})</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image style={styles.doc} defaultSource={require('../../assets/doc_red.png')} />
              <Text style={[styles.legend, { fontSize: 17 }]}>Denied ({fileSummary[4]})</Text>
            </View>
          </View>

          {/* Column 2 - Recommendation Approved & Recommendation Denied */}
          {(formType.includes('RST') || formType.includes('All Form Types')) && (
          <View style={{ alignItems: 'flex-start', width: '30.5%'}}>
            
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginHorizontal: '1%' }}>
              <Image style={styles.doc} defaultSource={require('../../assets/approved_rec_doc.png')} />
              <Text style={[styles.legend, { fontSize: 17 }]}>Rec. Approved ({fileSummary[1]})</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image style={styles.doc} defaultSource={require('../../assets/disapproved_rec_doc.png')} />
              <Text style={[styles.legend, { fontSize: 17 }]}>Rec. Denied ({fileSummary[2]})</Text>
            </View>            
          </View>
          )}

          {/* Column 3 - Approved & Paid */}
          <View style={{ alignItems: columns1And3Align, width: columns1And3Width}}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginLeft: '1%' }}>
              <Image style={styles.doc} defaultSource={require('../../assets/doc_green.png')} />
              <Text style={[styles.legend, { fontSize: 17}]}>Approved ({fileSummary[3]})</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image style={styles.doc} defaultSource={require('../../assets/doc_paid.png')} />
              <Text style={[styles.legend, { fontSize: 17 }]}>Paid ({fileSummary[5]})</Text>
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <ResponsiveContainer width="80%">
          {pieSelection.every((item) => item.value == 0) ? (
          <PieChart>
            <Pie
              data={[{ name: 'No Data', value: 1 }]}
              outerRadius={'80%'}
              dataKey="value"
              nameKey="name"
              innerRadius={'35%'}
            >
              <Cell stroke="#000" strokeWidth={1} fill='none' />
            </Pie>
            <Legend layout="vertical" formatter={renderNoDataLegendText} verticalAlign="middle" align="right" style={{ fontFamily: 'Trebuchet MS' }} />
          </PieChart> ): (
          <PieChart>
          <Tooltip />
          <Pie
            data={pieSelection}
            outerRadius={'80%'}
            dataKey="value"
            nameKey="name"
            innerRadius={'35%'}
          >
            {Object.values(pieSelection).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={pieColorSelection[index]} />
            ))}
          </Pie>
          <Legend layout="vertical" formatter={renderNonColoredLegendText} verticalAlign="middle" align="right" style={{ fontFamily: 'Trebuchet MS' }} />
        </PieChart>)
        }
        </ResponsiveContainer>
      );
    }
  };

  const rstData = [
    {
      name: `Submitted/Pending Review (${countRST[0]})`,
      value: countRST[0],
    },
    {
      name: `Approved (${countRST[1]})`,
      value: countRST[1],
    },
    {
      name: `Denied (${countRST[2]})`,
      value: countRST[2],
    },
    {
      name: `Recommendation Denied (${countRST[3]})`,
      value: countRST[3],
    },
    {
      name: `Recommendation Approved (${countRST[4]})`,
      value: countRST[4],
    },

  ];
  const data1380 = [
    {
      name: `Submitted/Pending Review (${count1380[0]})`,
      value: count1380[0],
    },
    {
      name: `Approved (${count1380[1]})`,
      value: count1380[1],
    },
    {
      name: `Denied (${count1380[2]})`,
      value: count1380[2],
    },
    {
      name: `Paid (${count1380[3]})`,
      value: count1380[3],
    },
  ];

  const dataEBDL = [
    {
      name: `Submitted/Pending Review (${countEBDL[0]})`,
      value: countEBDL[0],
    },
    {
      name: `Approved (${countEBDL[1]})`,
      value: countEBDL[1],
    },
    {
      name: `Denied (${countEBDL[2]})`,
      value: countEBDL[2],
    },
  ];

  const dataATA = [
    {
      name: `Submitted/Pending Review (${countATA[0]})`,
      value: countATA[0],
    },
    {
      name: `Approved (${countATA[1]})`,
      value: countATA[1],
    },
    {
      name: `Denied (${countATA[2]})`,
      value: countATA[2],
    },
  ];

  const dataMDR = [
    {
      name: `Submitted/Pending Review (${countMDR[0]})`,
      value: countMDR[0],
    },
    {
      name: `Approved (${countMDR[1]})`,
      value: countMDR[1],
    },
    {
      name: `Denied (${countMDR[2]})`,
      value: countMDR[2] },
  ];


  const palette2 = [
    '#FFBF00', // submitted
    '#238823', // paid
    '#D2222D', // denied
    '#F05E16', // rec denied
    '#9fdb37', // rec approved
  ];

  // get data
  useEffect(() => {
    const getAdminFileData = async () => {
      try {
        const response = await subordinateFiles();
        if (response === null) {
          showSnackbar('error', 'Application Error: Failed to retrieve unit soldiers information')
          return;
        }
        console.log(response);
        setdocData(response[0]);
        setUnitSelection(response[1]);
        setMasterFileSummary(response[2]);
        setFileSummary(response[2]);
        const combinedCounts = {
          submitted: (response[3].form_types['1380']?.submitted || 0) +
            (response[3].form_types['1380 aftp']?.submitted || 0),
          approved: (response[3].form_types['1380']?.approved || 0) +
            (response[3].form_types['1380 aftp']?.approved || 0),
          denied: (response[3].form_types['1380']?.denied || 0) +
            (response[3].form_types['1380 aftp']?.denied || 0),
          paid: (response[3].form_types['1380']?.paid || 0) +
            (response[3].form_types['1380 aftp']?.paid || 0),
        };
        setMasterCount1380(Object.values(combinedCounts));
        setMasterCountRST([response[3].form_types['RST'][4], response[3].form_types['RST'][0], response[3].form_types['RST'][2], response[3].form_types['RST'][3], response[3].form_types['RST'][1]]);
        setMasterCountEBDL([response[3].form_types['EBDL'][0], response[3].form_types['EBDL'][3], response[3].form_types['EBDL'][4]]);
        setMasterCountATA([response[3].form_types['ATA'][0], response[3].form_types['ATA'][3], response[3].form_types['ATA'][4]]);
        setMasterCountMDR([response[3].form_types['MDR'][0], response[3].form_types['MDR'][3], response[3].form_types['MDR'][4]]);
        
        // Since this function is the first time we are getting the files, we also set the non-master Count states.
        setCount1380(Object.values(combinedCounts));
        setCountRST(Object.values(response[3].form_types['RST']));
        setCountRST([response[3].form_types['RST'][4], response[3].form_types['RST'][0], response[3].form_types['RST'][2], response[3].form_types['RST'][3], response[3].form_types['RST'][1]]);
        setCountEBDL([response[3].form_types['EBDL'][0], response[3].form_types['EBDL'][3], response[3].form_types['EBDL'][4]]);
        setCountATA([response[3].form_types['ATA'][0], response[3].form_types['ATA'][3], response[3].form_types['ATA'][4]]);
        setCountMDR([response[3].form_types['MDR'][0], response[3].form_types['MDR'][3], response[3].form_types['MDR'][4]]);

        // Set fiscal year
        const getCurrentFiscalYear = () => {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth(); 
          if (currentMonth >= 9) {
            return currentYear + 1;
          } else {
            return currentYear; 
          }
        }
        // Set arrays of forms based on fiscal year
        const getFyForms = (data, fiscal) => {
          let current = [];
          let previous = [];
          let upcoming = [];
          for (let i = 0; i < data.length; i++) {
            let form = data[i];
            let fiscalYear = parseInt(form.fiscal_year);
            if (fiscalYear === fiscal) {current.push(form)}
            else if (fiscalYear === fiscal - 1) {previous.push(form)}
            else if (fiscalYear === fiscal + 1) {upcoming.push(form)}
          }
          setCurrentFyForms(current);
          setPrevFyForms(previous);
          setUpcomingFyForms(upcoming);
          return current;
        }
        // Retrieve forms pertaining to current fiscal year
        const fiscal = getCurrentFiscalYear();
        setFy(fiscal);
        setSelectedFy(`FY ${fiscal}`);
        const selectedFyForms = getFyForms(response[0], fiscal);
        setSelectedFyForms(selectedFyForms);
        applyFilters(selectedFyForms);
        setLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    getAdminFileData();
  }, [isFocused]);
  
  function pieSelector(data, color, index) {
    setpieSelection(data);
    setpieColors(color);
    setActiveChartButton(index);
  }

  const handleStatusFilter = (selectedValues) => {
    const allStatusIndex = selectedValues.indexOf('All Statuses');
    const previousAllStatusIndex = statusType.indexOf('All Statuses');

    let updatedValues = [...selectedValues];

    if (selectedValues.length === 0) {
      updatedValues = ['All Statuses'];
    }
    else if (allStatusIndex !== -1 && previousAllStatusIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['All Statuses'];
    }
    else if (previousAllStatusIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter((value) => value !== 'All Statuses');
    } else if (selectedValues.length === 6) updatedValues = ['All Statuses']; // If all values are selected the long way
    
    setStatusType(updatedValues);
    applyFilters(selectedFyForms, dateType, formType, unitType, updatedValues, (dateType === 'All Time' && unitType.includes('All Units') && formType.includes('All Form Types') && updatedValues.includes('All Statuses')));
  };

  const handleUnitFilter = (selectedValues) => {
    const allUnitsIndex = selectedValues.indexOf('All Units');
    const previousAllUnitsIndex = unitType.indexOf('All Units');

    let updatedValues = [...selectedValues];

    if (selectedValues.length === 0) {
        updatedValues = ['All Units'];
    } else if (allUnitsIndex !== -1 && previousAllUnitsIndex === -1 && selectedValues.length > 1) {
        updatedValues = ['All Units'];
    } else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
        updatedValues = selectedValues.filter((value) => value !== 'All Units');
    } else if (selectedValues.length === 3) updatedValues = ['All Units']; // If all units are selected the long way

    setUnitType(updatedValues);
    applyFilters(selectedFyForms, dateType, formType, updatedValues, statusType, (dateType === 'All Time' && updatedValues.includes('All Units') && formType.includes('All Form Types') && statusType.includes('All Statuses')));
  };

  const handleDocFilter = (selectedValues) => {
    const allFormsIndex = selectedValues.indexOf('All Form Types');
    const previousAllFormsIndex = formType.indexOf('All Form Types');

    let updatedValues = [...selectedValues];
    let updatedStatusType = [...statusType];

    if (selectedValues.length === 0) {
      updatedValues = ['All Form Types'];
    } else if (allFormsIndex !== -1 && previousAllFormsIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['All Form Types'];
    } 
    else if (previousAllFormsIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter((value) => value !== 'All Form Types');
    } else if (selectedValues.length === 7) updatedValues = ['All Form Types']; // If all values are selected the long way

    // If updatedValues does not include "RST" or "All Form Types" but statusType includes "Recommendation Approved" and/or "Recommendation Denied", remove "Recommendation Approved" and/or "Recommendation Denied" from statusType
    if (!updatedValues.includes('RST') && !updatedValues.includes('All Form Types')) {
      updatedStatusType = statusType.filter(
          (status) => status !== 'Recommendation Approved' && status !== 'Recommendation Denied',
      );
      if (updatedStatusType.length == 0) updatedStatusType = ['All Statuses'];
      // If they only had the Recommendation statuses selected, default back to All
      setStatusType(updatedStatusType);
    }

    if (!updatedValues.includes('All Form Types')) {
      if (activeChartButton == 1 && !updatedValues.includes('RST')) setActiveChartButton(0);
      else if (activeChartButton == 2 && !updatedValues.includes('1380') && !updatedValues.includes('1380 AFTP')) setActiveChartButton(0);
      else if (activeChartButton == 3 && !updatedValues.includes('EBDL')) setActiveChartButton(0);
      else if (activeChartButton == 4 && !updatedValues.includes('ATA')) setActiveChartButton(0);
      else if (activeChartButton == 5 && !updatedValues.includes('MDR')) setActiveChartButton(0);
    }
    

    setFormType(updatedValues);
    applyFilters(selectedFyForms, dateType, updatedValues, unitType, updatedStatusType, (dateType === 'All Time' && unitType.includes('All Units') && updatedValues.includes('All Form Types') && updatedStatusType.includes('All Statuses')));
  };

  const handleDateFilter = (text) => {
    const oldDateType = dateType;
    setPrevDateType(dateType);
    setDateType(text);
    // Needs to be != in this function, do NOT use !==
    applyFilters(selectedFyForms, text, formType, unitType, statusType, ((oldDateType != 'All Time' && text === 'All Time') && unitType.includes('All Units') && formType.includes('All Form Types') && statusType.includes('All Statuses')));
  };

  const statusLabelToCode = {
    'Submitted': 1,
    'Recommendation Approved': 2,
    'Recommendation Denied': 3,
    'Approved': 4,
    'Denied': 5,
    'Paid': 6,
  };

  const formatDateForPDF = (timestamp) => {
    const dateComponents = timestamp.match(/(\d{2})\/(\d{2})\/(\d{2})/);
    const monthIndex = parseInt(dateComponents[1]) - 1;
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const year = '20' + dateComponents[3];
    const day = dateComponents[2];
    const month = monthNames[monthIndex];
    return `${day}_${month}_${year}`;
};
  
  const handleExportData = async () => {
    setLoaded(false);
    const zip = new JSZip();
    
    // Iterate over rows and generate PDFs
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];

      if (!row?.attachments?.some(attachment => attachment.toLowerCase().includes('undefined'))) {
        // Extract necessary components
        const formName = row.form_name.replace(/\s+/g, '_');
        const formType = row.form_type.replace(/\s+/g, '_');
        const timestamp = formatDateForPDF(row.timestamp);

        const generatedData = await generatePDF(row.form_id, row.form_type, navigation, true, false, row.attachments, row.creator_id);
  
        // Decode base64-encoded PDF data
        const decodedPDFData = atob(generatedData);
        // Convert binary PDF data to Uint8Array
        const uint8Array = new Uint8Array(decodedPDFData.length);
        for (let j = 0; j < decodedPDFData.length; j++) {
          uint8Array[j] = decodedPDFData.charCodeAt(j);
        }
        // Check if the file already exists in the zip
        let filename = `${formName}_${formType}_${timestamp}.pdf`;
        let count = 1;
        while (zip.file(filename)) {
          filename = `${formName}_${formType}_${timestamp} (${count}).pdf`;
          count++;
        }
        // Add the generated PDF to the zip file
        zip.file(filename, uint8Array, { binary: true });
      }
      
    }
  
    // Generate the zip file
    const blob = await zip.generateAsync({ type: 'blob' });
  
    // Trigger download with file-saver
    saveAs(blob, 'documents.zip');
    setLoaded(true);
}; 


  const parseDateFromLastEvent = (timestamp) => {
    if (typeof timestamp !== 'string') {
      return null;
    }

    const lastTimestampSegment = timestamp.split(', ').pop();
    const datePart = lastTimestampSegment.substring(lastTimestampSegment.indexOf(': ') + 2);
    const dateParts = datePart.split(' ')[0].split('/');
    const year = parseInt(dateParts[2], 10);
    const fullYear = year < 50 ? 2000 + year : 1900 + year;
    const eventDate = new Date(fullYear, dateParts[0] - 1, dateParts[1]);
    
    return eventDate;
  };

  const getStartDate = (dateType) => {
    const currentDate = new Date();
  
    switch (dateType) {
      case '30 Days':
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
      case '60 Days':
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 60);
      case '90 Days':
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 90);
      default:
        return new Date(0);
    }
  };

  const renderNonColoredLegendText = (value) => {
    return <span style={{color: colors.text }}>{value}</span>;
  };

  const renderNoDataLegendText = (value) => {
    return <span style={{color: colors.text, fontSize: '40px' }}>{value}</span>;
  };

  const applyFilters = (data, dateType='30 Days', formType=['All Form Types'], unitType=['All Units'], statusType=['All Statuses'], returningToAll=false, newFy=false) => {
    let filtered = data;
    const filtersApplied = (newFy || dateType != 'All Time' || !formType.includes('All Form Types') || !unitType.includes('All Units') || !statusType.includes('All Statuses')) || returningToAll;
    if (dateType != 'All Time') {
      const startDate = getStartDate(dateType);
      filtered = filtered.filter((item) => {
        const submittedDate = parseDateFromLastEvent(item.timestamp);
        return submittedDate && submittedDate.getTime() >= startDate.getTime();
      });
    }

    if (filtersApplied && legendContainerRef.current) setLegendContainerWidth(legendContainerRef.current.offsetWidth);

    if (!formType.includes('All Form Types')) {
      filtered = filtered.filter((item) => formType.some((form) => item.form_type.toLowerCase().includes(form.toLowerCase())));
    }

    if (!unitType.includes('All Units')) {
      filtered = filtered.filter((item) => unitType.some((unit) => item.unit_name.toLowerCase().includes(unit.toLowerCase())));
    }

    if (!statusType.includes('All Statuses')) {
      filtered = filtered.filter((item) => {
        const itemStatusCode = item.status;
        const selectedStatusCodes = statusType.map((label) => statusLabelToCode[label]);
        return selectedStatusCodes.includes(itemStatusCode);
      });
    }

    const statusMapping = {
      1: 'Submitted/Pending Review',
      2: 'Recommendation Approved',
      3: 'Recommendation Denied',
      4: 'Approved',
      5: 'Denied',
      6: 'Paid', 
    };

    if (filtersApplied) {
      setFileSummary([
        filtered.filter((item) => item.status === 1).length,
        filtered.filter((item) => item.status === 2).length,
        filtered.filter((item) => item.status === 3).length,
        filtered.filter((item) => item.status === 4).length,
        filtered.filter((item) => item.status === 5).length,
        filtered.filter((item) => item.status === 6).length,
      ]);
      if (formType.includes('RST') || formType.includes('All Form Types')) {        
        const newRSTCount = [
          filtered.filter((item) => item.form_type === 'RST' && item.status === 1).length, // Status 1 is submitted
          filtered.filter((item) => item.form_type === 'RST' && item.status === 4).length, // Status 4 is approved
          filtered.filter((item) => item.form_type === 'RST' && item.status === 5).length, // Status 5 is denied
          filtered.filter((item) => item.form_type === 'RST' && item.status === 3).length, // Status 3 is recommend denied
          filtered.filter((item) => item.form_type === 'RST' && item.status === 2).length, // Status 2 is recommend approved
          // Date in the same order as the labels shown on the pie chart legend.
        ];
        
        const pieData = [
          { name: `${statusMapping[1]} (${newRSTCount[0]})`, value: newRSTCount[0] }, // Submitted
          { name: `${statusMapping[4]} (${newRSTCount[1]})`, value: newRSTCount[1] }, // Approved
          { name: `${statusMapping[5]} (${newRSTCount[2]})`, value: newRSTCount[2] }, // Denied
          { name: `${statusMapping[3]} (${newRSTCount[3]})`, value: newRSTCount[3] }, // Rec Denied
          { name: `${statusMapping[2]} (${newRSTCount[4]})`, value: newRSTCount[4] }, // Rec Approved
        ];
        
        setCountRST(newRSTCount);
        if (activeChartButton === 1) pieSelector(pieData, palette2, 1);
      }    
      else setCountRST(masterCountRST);

      if (formType.includes('1380')|| formType.includes('1380 AFTP') || formType.includes('All Form Types')) {
        const new1380Count = [
          filtered.filter((item) => ((item.form_type === '1380' || item.form_type === '1380 aftp') && item.status === 1)).length,
          filtered.filter((item) => ((item.form_type === '1380' || item.form_type === '1380 aftp') && item.status === 4)).length,
          filtered.filter((item) => ((item.form_type === '1380' || item.form_type === '1380 aftp') && item.status === 5)).length,
          filtered.filter((item) => ((item.form_type === '1380' || item.form_type === '1380 aftp') && item.status === 6)).length,
        ];

        
        const pieData = [
          { name: `${statusMapping[1]} (${new1380Count[0]})`, value: new1380Count[0] },
          { name: `${statusMapping[4]} (${new1380Count[1]})`, value: new1380Count[1] },
          { name: `${statusMapping[5]} (${new1380Count[2]})`, value: new1380Count[2] },
          { name: `${statusMapping[6]} (${new1380Count[3]})`, value: new1380Count[3] },
        ];

        setCount1380(new1380Count);
        if (activeChartButton === 2) pieSelector(pieData, palette2, 2);
      }
      else setCount1380(masterCount1380);

      if (formType.includes('ATA') || formType.includes('All Form Types')) {
        const newATACount = [
          filtered.filter((item) => (item.form_type === 'ATA' && item.status === 1)).length,
          filtered.filter((item) => (item.form_type === 'ATA' && item.status === 4)).length,
          filtered.filter((item) => (item.form_type === 'ATA' && item.status === 5)).length,
        ];
        const pieData = [
          { name: `${statusMapping[1]} (${newATACount[0]})`, value: newATACount[0] },
          { name: `${statusMapping[4]} (${newATACount[1]})`, value: newATACount[1] },
          { name: `${statusMapping[5]} (${newATACount[2]})`, value: newATACount[2] },
        ];

        setCountATA(newATACount);
        if (activeChartButton === 4) pieSelector(pieData, palette2, 4);
      }
      else setCountATA(masterCountATA);

      if (formType.includes('EBDL') || formType.includes('All Form Types')) {
        const newEBDLCount = [
          filtered.filter((item) => (item.form_type === 'EBDL' && item.status === 1)).length,
          filtered.filter((item) => (item.form_type === 'EBDL' && item.status === 4)).length,
          filtered.filter((item) => (item.form_type === 'EBDL' && item.status === 5)).length,
        ];
        const pieData = [
          { name: `${statusMapping[1]} (${newEBDLCount[0]})`, value: newEBDLCount[0] },
          { name: `${statusMapping[4]} (${newEBDLCount[1]})`, value: newEBDLCount[1] },
          { name: `${statusMapping[5]} (${newEBDLCount[2]})`, value: newEBDLCount[2] },
        ];

        setCountEBDL(newEBDLCount);
        if (activeChartButton === 3) pieSelector(pieData, palette2, 3);
      }
      else setCountEBDL(masterCountEBDL);

      if (formType.includes('MDR') || formType.includes('All Form Types')) {
        const newMDRCount = [
          filtered.filter((item) => (item.form_type === 'MDR' && item.status === 1)).length,
          filtered.filter((item) => (item.form_type === 'MDR' && item.status === 4)).length,
          filtered.filter((item) => (item.form_type === 'MDR' && item.status === 5)).length,
        ];
        const pieData = [
          { name: `${statusMapping[1]} (${newMDRCount[0]})`, value: newMDRCount[0] },
          { name: `${statusMapping[4]} (${newMDRCount[1]})`, value: newMDRCount[1] },
          { name: `${statusMapping[5]} (${newMDRCount[2]})`, value: newMDRCount[2] },
        ];

        setCountMDR(newMDRCount);
        if (activeChartButton === 5) pieSelector(pieData, palette2, 5);
      }
      else setCountMDR(masterCountMDR);
    }
    else {
      setFileSummary(masterFileSummary);
      setCount1380(masterCount1380);
      setCountATA(masterCountATA);
      setCountRST(masterCountRST);
      setCountEBDL(masterCountEBDL);
      setCountMDR(masterCountMDR);
    }
    // If we're filtering by anything at all, update the Totals tab. Else default to what the totals originally were when we first retrieved subordinate files.
    // This same logic is used above for the specific form types.

    setRows(filtered);
  };



  const columns = useMemo(
    () => [
      {
        accessorKey: 'file',
        header: 'File',
        size: 1,
        accessorFn: (row) => {
          switch (row.status) {
            case 1:
              return 'Submitted';
            case 2:
              return 'Awaiting Review';
            case 3:
              return 'Must Resubmit';
            case 4:
              return 'Approved';
            case 5:
              return 'Denied';
            case 6:
              return 'Paid';
          }
        },
        Cell: ({ row }) => {
          const getImageSource = () => {
            switch (row.original.status) {
              case 1:
                return require('../../assets/doc_yellow.png');
              case 2:
                return require('../../assets/approved_rec_doc.png');
              case 3:
                return require('../../assets/disapproved_rec_doc.png');
              case 4:
                return require('../../assets/doc_green.png');
              case 5:
                return require('../../assets/doc_red.png');
              case 6:
                return require('../../assets/doc_paid.png');
            }
          };

          return (
            <TouchableOpacity style={{ width: 40, height: 40 }} onPress={() => {
              setLoaded(false);
              generatePDF(row.original.form_id, row.original.form_type, navigation, false, false, row.original.attachments, row.original.creator_id);
              setLoaded(true);
            }}>
              <img src={getImageSource()} />
            </TouchableOpacity>
          );
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: 'unit_name',
        header: 'Unit',
        size: 1,
      },
      {
        accessorFn: (row) => `${row.form_name} (${row.form_id})`,
        header: 'Form',
        size: 1,
      },
      {
        accessorKey: 'form_type',
        header: 'Type',
        size: 1,
      },
      {
        accessorKey: 'statusName',
        header: 'Status',
        size: 1,
      },
      {
        accessorKey: showTimeline ? 'timeline' : 'timestamp',
        header: showTimeline ? 'Timeline' : 'Timestamp',
        size: 1,
        Cell: ({ row }) => {
          if (showTimeline) {
            const steps = getTimelineSteps(row.original); // Generate timeline steps
            return renderTimeline(steps, row.original); // Render the timeline view
          } else {
            return <Text style={[styles.legend, { fontSize: 17 }]}>{row.original.timestamp}</Text>; // Render the timestamp
          }
        },
      },
    ],
    [rows, showTimeline],
  );

  const globalTheme = useTheme();

  const generalTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colors.mode,
          primary: globalTheme.palette.primary,
        },
      }),
    [globalTheme, isFocused],
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colors.mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
        typography: { fontSize: 17 },
      }),
    [globalTheme, isFocused],
  );

  useEffect(() => {
    if (selectedId != null) {
      generatePDF(selectedId.form_id, selectedId.form_type, navigation, false, false, selectedId.attachments, selectedId.creator_id);
    }
  }, [selectedId]);

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setLegendContainerWidth(null);
    };

    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    <ThemeProvider theme={generalTheme}>
    <View style={{ height: '100%', width: '100%', flexDirection: 'row' }}>
      {/* <NavSideBar /> */}
      <Scrollbars style={styles.container}>
        <View style={styles.pageTopSection}>
          <View style={{ alignText: 'center', width: '40%' }}>

            <View style={styles.titleDiv}>
              <Text style={styles.title}>
                <Text style={{ fontWeight: 'bold' }}>Admin Document Overview</Text>
              </Text>
            </View>

            <Text style={styles.pageInfoText} >
              Track administrative statistics such as status of submitted RSTs,
              1380s, ATAs, EBDLs, MDRs, and Dentals.
            </Text>


            <View style={styles.filtersContainer}>
              <View style={styles.filtersRow}> 
                <View style={styles.unitsFilter}>
                  <FormControl style={styles.textbox}>
                    <InputLabel id="tables-units-label">Units Filter</InputLabel>
                    <Select
                      multiple
                      value={unitType}
                      input={
                        <OutlinedInput 
                          label="Units Filter" 
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '& .MuiSelect-icon': {
                              color: colors.inputted_text,
                            },
                            '& .MuiInputBase-input': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormHelperText-root': {
                              backgroundColor: colors.screen_background,
                              margin: 0,
                              paddingLeft: 1,
                            },
                          }}
                        />
                        }
                      onChange={(event) => {
                        handleUnitFilter(event.target.value);
                      }}
                      MenuProps={{ style: { zIndex: 1500 }, }}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {unitSelection.map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.label} value={option.label}>
                          <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </View>
                <View style={styles.unitsFilter}>
                  <FormControl style={styles.textbox}>
                    <InputLabel id="tables-units-label">Date Range Filter</InputLabel>
                    <Select
                      value={dateType}
                      input={
                        <OutlinedInput 
                          label="Date Range Filter"
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '& .MuiSelect-icon': {
                              color: colors.inputted_text,
                            },
                            '& .MuiInputBase-input': {
                              color: colors.inputted_text,
                              height: 0
                            },
                            '& .MuiFormLabel-root': {
                              color: colors.inputted_text
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormHelperText-root': {
                              backgroundColor: colors.screen_background,
                              margin: 0,
                              paddingLeft: 1,
                            },
                          }} 
                        />
                        }
                      onChange={(value) => {
                        handleDateFilter(value.target.value);
                      }}
                      MenuProps={{
                        style: { zIndex: 1500 },
                      }}
                    >
                      {DateTypes.map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.label} value={option.label}>
                          <ListItemText
                            primaryTypographyProps={{ style: styles.listItemTextTypography }}
                            primary={option.label}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </View>
              </View>
              <View style={styles.filtersRow}>
                <View style={styles.unitsFilter}>
                  <FormControl style={styles.textbox}>
                    <InputLabel id="tables-units-label">Document Filter</InputLabel>
                    <Select
                      multiple
                      value={formType}
                      input={
                        <OutlinedInput 
                          label="Document Filter" 
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '& .MuiSelect-icon': {
                              color: colors.inputted_text,
                            },
                            '& .MuiInputBase-input': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormHelperText-root': {
                              backgroundColor: colors.screen_background,
                              margin: 0,
                              paddingLeft: 1,
                            },
                          }}
                        />
                        }
                      onChange={(value) => {
                        handleDocFilter(value.target.value);
                      }}
                      MenuProps={{style: { zIndex: 1500 },}}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {DocTypes.map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.label} value={option.label}>
                          <ListItemText
                            primaryTypographyProps={{ style: styles.listItemTextTypography }}
                            primary={option.label}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </View>
                <View style={styles.unitsFilter}>
                  <FormControl style={styles.textbox}>
                    <InputLabel id="tables-units-label">Status Filter</InputLabel>
                    <Select
                      multiple
                      value={statusType}
                      input={
                        <OutlinedInput 
                          label="Status Filter" 
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '& .MuiSelect-icon': {
                              color: colors.inputted_text,
                            },
                            '& .MuiInputBase-input': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormHelperText-root': {
                              backgroundColor: colors.screen_background,
                              margin: 0,
                              paddingLeft: 1,
                            },
                          }}
                        />
                      }
                      onChange={(value) => {
                        handleStatusFilter(value.target.value);
                      }}
                      MenuProps={{style: { zIndex: 1500 }}}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {StatusTypes.filter((option) => {
                        if (option.label === 'Recommendation Approved' || option.label === 'Recommendation Denied') {
                          return formType.includes('RST') || formType.includes('All Form Types');
                        }
                        return true;
                      }).map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.label} value={option.label}>
                          <ListItemText
                            primaryTypographyProps={{ style: styles.listItemTextTypography }}
                            primary={option.label}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </View>
              </View>
            </View>
          </View>

          <View style={{ flexDirection: 'row', flex: .10 }}>
            <View />
          </View>
  
          <View 
            style={[
              { flex: 4 }, 
              outermostLegendContainerHeight && { height: outermostLegendContainerHeight },
            ]}
            onLayout={handleLayout}
          >
            <CustomRadioTabs 
              fy={fy}
              selectedFy={selectedFy}
              setSelectedFy={setSelectedFy}
              setSelectedFyForms={setSelectedFyForms}
              currentFyForms={currentFyForms}
              prevFyForms={prevFyForms}
              upcomingFyForms={upcomingFyForms}
              applyFilters={applyFilters}
              unitType={unitType}
              dateType={dateType}
              formType={formType}
              statusType={statusType}
              colors={colors}
            />
            <View 
              style={[
                styles.legendContainer,
                legendContainerWidth && { width: legendContainerWidth},
              ]}
              ref={legendContainerRef}
            >
              <View style={styles.chartButtons}>
                <ChartSelectorButtons />
              </View>
              
              <LegendItem />
             
            </View>
          </View>
        </View>

        {/* Metrics */}
        <View style={styles.metricsContainer}>
          <View style={styles.metricBox}>
            <Text style={styles.metricTitle}>Median Processing</Text>
            <Text style={styles.metricValue}>{medianProcessingTime} Days</Text>
          </View>
          <View style={styles.metricBox}>
            <Text style={styles.metricTitle}>Max Processing</Text>
            <Text style={styles.metricValue}>{maxProcessingTime} Days</Text>
          </View>
          <View style={styles.metricBox}>
            <Text style={styles.metricTitle}>% Pending Approval</Text>
            <Text style={styles.metricValue}>{percentagePendingApproval}%</Text>
          </View>
          <View style={styles.metricBox}>
            <Text style={styles.metricTitle}>% Pending Pay</Text>
            <Text style={styles.metricValue}>{percentagePendingPay}%</Text>
          </View>
          <View style={styles.metricBox}>
            <Text style={styles.metricTitle}>% Awaiting Corrections</Text>
            <Text style={styles.metricValue}>{percentageAwaitingCorrections}%</Text>
          </View>
        </View>

        <View style={styles.tableContainer}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={rows}
              initialState={{density: 'compact'}}
              tableInstanceRef={tableInstanceRef}
              renderToolbarInternalActions={() => (
                <>
                  <Box>
                    <MUITooltip arrow title="Change timestamp/timeline view">
                      <IconButton onClick={() => setShowTimeline((prev) => !prev)}>
                        {showTimeline ? <AccessTimeIcon /> : <TimelineIcon />}
                      </IconButton>
                    </MUITooltip>
                    <MUITooltip arrow title="Download forms">
                      <IconButton onClick={handleExportData}>
                        <DownloadIcon/>
                      </IconButton>
                    </MUITooltip>
                    <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />
                    <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                    <MRT_FullScreenToggleButton table={tableInstanceRef.current} />
                  </Box>
                </>
              )}
            />
          </ThemeProvider>
        </View>

      </Scrollbars>
    </View>
    </ThemeProvider>
  );
}