import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "../../components/SnackbarContext";
import * as Progress from "react-native-progress";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { CheckBox } from "react-native-web";
import { TextInput } from "react-native-paper";

import { en, registerTranslation } from "react-native-paper-dates";
import * as SOUForm from "../../PDFs/SOU_form_raw";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { useScale } from "../../components/scale";
import { useColorContext } from "../../components/ColorContext";
import { pdfIn } from "../generatePDF.js";
import { PDFDocument, rgb } from "pdf-lib";
import Scrollbars from "react-custom-scrollbars";
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import { create_new_user_signature } from "../../api_handler/users.js";
import { get_review_files, put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from "../../api_handler/asyncstorage.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { create_notification } from "../../api_handler/notifications";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import {
  get_single_file,
  review_file,
  get_image_s3,
} from "../../api_handler/files";
import { get_single_user } from "../../api_handler/users.js";
import { decodeBase64 } from "../generatePDF.js";
registerTranslation("en", en);

function ReviewSOU({ route, navigation }) {
  const { colors } = useColorContext();
  const scale = useScale();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const viewRef = useRef();

  const styles = {
    container: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      backgroundColor: colors.screen_background,
    },
    scrollView: {
      height: "100%",
      width: "100%",
      marginTop: "2%",
    },
    mainWrapper: {
      flexDirection: "column",
      alignSelf: "center",
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: "Trebuchet MS",
      flex: 1,
      color: colors.text,
      paddingLeft: "5%",
      paddingTop: 25,
    },
    commentInput: {
      minHeight: "fit-content",
      borderWidth: 1,
      borderRadius: 5,
      borderColor: colors.border_color,
      backgroundColor: colors.textfield_background,
      width: 500,
    },
  };

  const loadingStyles = {
    animation: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  const requestTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [hasSignature, setHasSignature] = useState(false);
  const [comment, setComment] = useState("");
  const [approve, setApprove] = useState(false);
  const [soilderName, setSoldierName] = useState("");
  const [dateSubmitted, setDateSubmitted] = useState("");
  const [unitName, setUnitName] = useState("");
  const [SOUData, setSOUData] = useState({});
  const [creator, setCreator] = useState({});

  useEffect(() => {
    // Get LIK form
    getSOUForm(route.params.fileID);
    getCreator(route.params.fileID);

    // Check if user has a signature
    setHasSignature(global.signature_uri != null && global.signature_uri != "");
  }, []);

  if (isLoading) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={requestTheme}>
      <View style={[styles.container, { cursor: isLoading ? "wait" : "auto" }]}>
        {/* <NavSideBar /> */}
        <Scrollbars style={styles.scrollView}>
          <View style={styles.mainWrapper}>
            <Text style={styles.pageTitle}>Review SOU</Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap", // Allows the content to wrap when there is overflow
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "2%",
                paddingBottom: "7%",
                gap: "5%",
                marginRight: "2%",
              }}
            >
              {/* Table */}
              <View
                style={{
                  flexDirection: "column",
                  maxWidth: 800,
                  minWidth: 800,
                  height: "fit-content",
                  borderColor: colors.border_color,
                  borderWidth: 1,
                  borderRadius: 5,
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 4,
                  backgroundColor: colors.modal_background,
                  overflow: "hidden",
                  marginBottom: "3%",
                }}
              >
                <Table>
                  <TableBody>
                    <FormRow label={"Soilder"} value={soilderName} />
                    <FormRow label={"Unit"} value={unitName} />
                    <FormRow label={"Date Submitted"} value={dateSubmitted} />
                    <FormRow label={"Abide by all rules and regulations with respect to the card."} value={"Understood"}  />
                    <FormRow label={"Use the card only for official travel validated by travel orders or authorized IDT airfare."} value={"Understood"}  />
                    <FormRow label={"Pay all my charges so that payment is received by the contractor within 30 days of the closing date indicated on the monthly billing statement."} value={"Understood"}  />
                    <FormRow label={"Contact my program coordinator immediately if, due to disbursing office process delays, I have not received payment by the time the card contractor's bill is due."} value={"Understood"}  />
                    <FormRow label={"Notify my program coordinator of any problems with respect to my use of the card."} value={"Understood"}  />
                    <FormRow label={"Notify the Card contractor and my program coordinator if my card or travelers checks are lost or stolen."} value={"Understood"}  />
                  </TableBody>
                </Table>
              </View>

              <View
                style={{
                  minWidth: 300,
                  flexDirection: "column",
                }}
              >
                {/* Approval */}
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: "2%",
                    color: colors.bow,
                  }}
                >
                  Reviewer Actions
                </Text>
                <View
                  style={{ marginBottom: "2%", gap: 30, flexDirection: "row" }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CheckBox
                      onValueChange={() => setApprove(!approve)}
                      style={{ margin: 10, marginRight: 15 }}
                      value={approve}
                    />
                    <Text style={{ color: colors.bow }}>Approve</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CheckBox
                      onValueChange={() => setApprove(!approve)}
                      style={{ margin: 10, marginRight: 15 }}
                      value={!approve}
                    />
                    <Text style={{ color: colors.bow }}>Disapprove</Text>
                  </View>
                </View>
                {/* Comments */}
                <TextInput
                  style={{
                    ...styles.commentInput,
                    marginBottom: "4%",
                    marginTop: "2%",
                  }}
                  theme={{
                    colors: {
                      text: colors.inputted_text,
                      accent: colors.textfield_background,
                      primary: colors.inputted_text,
                      placeholder: colors.placeholder_text,
                    },
                  }}
                  selectionColor="black"
                  label="Comments"
                  activeOutlineColor="black"
                  value={comment}
                  onChangeText={(data) => setComment(data)}
                />
                {/* Signature */}
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: "2%",
                    color: colors.bow,
                  }}
                >
                  Signature
                </Text>
                <View style={{ gap: 30 }}>
                  {hasSignature ? (
                    hasSigned ? (
                      <Image
                        resizeMode={"contain"}
                        style={{
                          width: 200,
                          height: 100,
                        }}
                        source={{ uri: global.signature_uri }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    // Add Signature
                    <View
                      style={{
                        flexDirection: "column",
                        gap: 30,
                      }}
                    >
                      {signatureName == "" ? (
                        <Text
                          style={{
                            opacity: 0.7,
                            width: 500,
                            fontStyle: "italic",
                            color: "red",
                          }}
                        >
                          No signature found. Please add one below.
                        </Text>
                      ) : (
                        <ViewShot
                          ref={viewRef}
                          style={{
                            padding: 10,
                            marginVertical: 10,
                            backgroundColor: "white",
                          }}
                          options={{ format: "png", quality: 1 }}
                        >
                          <Text style={{ color: "black", fontSize: scale(30) }}>
                            {signatureName}
                          </Text>
                        </ViewShot>
                      )}

                      <TextInput
                        style={styles.commentInput}
                        theme={{
                          colors: {
                            text: colors.inputted_text,
                            accent: colors.textfield_background,
                            primary: colors.inputted_text,
                            placeholder: colors.placeholder_text,
                          },
                        }}
                        selectionColor={"black"}
                        placeholder="e.g. John Doe"
                        label={`First and Last Name`}
                        activeOutlineColor={"black"}
                        value={signatureName}
                        onChangeText={(data) => setSignatureName(data)}
                      />

                      <View style={{ width: 128 }}>
                        <Button label="Add Signature" onClick={addSignature} />
                      </View>
                    </View>
                  )}

                  {/* Buttons */}
                  <View style={{ width: 128 }}>
                    <Button
                      label="Sign Document"
                      disabled={!hasSignature}
                      onClick={sign}
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                  </View>
                  <Text
                    style={{
                      opacity: 0.7,
                      width: 500,
                      fontStyle: "italic",
                      color: colors.bow,
                    }}
                  >
                    By clicking on the "Submit" button below, you consent to
                    your digital signature being affixed to the document. Your
                    signature can be viewed below after clicking the "Sign
                    Document" button. If you don't have a signature, please add
                    one below.
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      gap: "2%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        createSOUForm(SOUData);
                      }}
                      label="View Filled"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                    />
                    <Button
                      label="Submit"
                      bgColor={colors.gobl}
                      txtColor={colors.bow}
                      onClick={submit}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );

  function FormRow({ label, value, value_color = colors.bow }) {
    return (
      <TableRow
        sx={{
          boxShadow: "none",
          backgroundColor: colors.textfield_background,
          paddingTop: "5px",
          paddingTop: "5px",
          "&:hover": {
            backgroundColor: colors.textfield_background_hover,
          },
        }}
      >
        <TableCell>
          <Text style={{ color: colors.bow }}>{label}</Text>
        </TableCell>
        <TableCell>
          <Text style={{ color: value_color }}>{value}</Text>
        </TableCell>
      </TableRow>
    );
  }

  function Button({
    label,
    bgColor = "#fdebc5",
    txtColor = "#000000",
    disabled = false,
    onClick = () => {},
  }) {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: bgColor,
          borderRadius: 5,
          borderColor: colors.border_color,
          borderWidth: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: 10,
          paddingVertical: 5,
          opacity: disabled ? 0.35 : 1,
        }}
        onPress={disabled ? () => {} : onClick}
      >
        <Text
          style={{
            color: txtColor,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  }

  async function captureTextAsImage() {
    try {
      const fullname =
        global.user_data.first_name.toLowerCase() +
        " " +
        global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: "png",
          quality: 0.8,
        });

        let ret = await create_new_user_signature(
          { user_id: global.user_data.user_id },
          global.token
        );
        if (ret.status === 200) {
          ret = ret.data;

          await put_image_s3(
            "signatures/" + ret.body.signature_id,
            uri,
            "image/png",
            global.token
          );

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem("signature", global.signature_uri);
          storeJsonData("user_data", global.user_data);

          setHasSignature(true);
        } else {
          showSnackbar(
            "error",
            "Application Error: Failed to create your signature"
          );
        }
      } else {
        showSnackbar(
          "error",
          "Input Error: The name inputted does not match your name we have on record!"
        );
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  }

  function addSignature() {
    // Add signature to s3
    captureTextAsImage();
  }

  function sign() {
    setHasSigned(true);
  }

  async function getSOUForm(formId) {
    // Get the form form from the database via form id

    setIsLoading(true);
    const res = await get_single_file(
      { form_id: formId, form_type: "SOU" },
      global.token
    );
    setIsLoading(false);

    if (res.body) {
      setSOUData(res.body);
      setUnitName(res.body.unit_name);
      setDateSubmitted(res.body.date);
      setSoldierName(res.body.name_and_rank);
      return;
    } else {
      showSnackbar("error", "Failed to retrieve SOU.");
    }
  }

  async function createSOUForm(data) {
    const pdf = await PDFDocument.load(SOUForm.pdf());
    const form = pdf.getForm();
    const page = pdf.getPages()[0];

    let field;

    // width: 612, height: 792

    const textboxStyle = {
      textColor: rgb(0, 0, 0),
      backgroundColor: rgb(1, 1, 1),
      borderColor: rgb(1, 1, 1),
    };

    // Rank and full name
    field = form.createTextField("rank_full_name");
    field.setText(
      global.user_data.rank +
        " " +
        global.user_data.first_name +
        " " +
        global.user_data.last_name
    );
    field.addToPage(page, {
      x: 210,
      y: 510,
      width: 85,
      height: 15,
      ...textboxStyle,
    });

    const initials = creator.first_name[0] + creator.last_name[0];

    // Initials
    const yLevels = [475, 450, 425, 386, 350, 322];

    yLevels.forEach((yLevel, index) => {
      field = form.createTextField("initial" + index);
      field.setText(initials);
      field.addToPage(page, {
        x: 533,
        y: yLevel,
        width: 20,
        height: 15,
        ...textboxStyle,
      });
    });
          
    // Applicant's signature and date
    const soldier_signature_uri = decodeBase64(await get_image_s3('signatures/' + data.soldier_signature_id, 'image/png', global.token)).split(',').pop();
    const img2 = await pdf.embedPng(soldier_signature_uri);

    page.drawImage(img2, {
      x: 120,
      y: 165,
      width: 100,
      height: 25,
    });

    field = form.createTextField("applicant_signature_date1");
    field.setText(data.date);
    field.addToPage(page, {
      x: 220,
      y: 170,
      width: 100,
      height: 15,
      ...textboxStyle,
    });

    // Applicant's printed name and date
    field = form.createTextField("applicant_printed_name");
    field.setText(creator.first_name + " " + creator.last_name);
    field.addToPage(page, {
      x: 120,
      y: 120,
      width: 100,
      height: 25,
      ...textboxStyle,
    });

    field = form.createTextField("applicant_signature_date2");
    field.setText(data.date);
    field.addToPage(page, {
      x: 220,
      y: 120,
      width: 100,
      height: 15,
      ...textboxStyle,
    });

    if (hasSigned) {
      // Supervisor's signature and date
      const img = await pdf.embedPng(global.signature_uri);

      page.drawImage(img, {
        x: 340,
        y: 165,
        width: 100,
        height: 25,
      });

      field = form.createTextField("reviewer_signature_date1");
      field.setText(getTodaysDate());
      field.addToPage(page, {
        x: 440,
        y: 168,
        width: 100,
        height: 15,
        ...textboxStyle,
      });
    }

    // Supervisor's printed name and date
    field = form.createTextField("Series_Grade_Title");
    field.setText(
      data.reviewer_name + " " + data.reviewer_grade + " " + data.reviewer_rank
    );
    field.addToPage(page, {
      x: 340,
      y: 120,
      width: 220,
      height: 18,
      ...textboxStyle,
    });

    form.flatten();
    const bytes = await pdf.saveAsBase64();
    pdfIn(bytes);
  }

  function submit() {
    if (!hasSigned && approve) {
      showSnackbar("error", "Please sign in order to approve.");
      return;
    }

    setIsLoading(true);
    review_file(
      {
        user_id: global.user_data.user_id,
        form_id: parseInt(route.params.fileID),
        form_type: "SOU",
        reviewer_comment: comment,
        status: approve ? 4 : 5,
        request_is_approved: approve,
        reviewer_signature_id: global.user_data.signature_id,
        permissions: global.user_data.permissions,
      },
      global.token
    ).then((res) => {
      setIsLoading(false);

      if (res.status === 200) {
        showSnackbar("success", "SOU review submitted successfully");
        sendNotifToSoldier(creator.user_id, approve ? "approved" : "disapproved");
        resetState();
        navigation.navigate("Home");
      } else {
        showSnackbar("error", "An error has occured submitting your review. Please try again later");
      }
    });
  }

  async function getCreator(formId) {
    setIsLoading(true);
    get_review_files(
      {
        role: global.user_data.role,
        user_id: global.user_data.user_id,
      },
      global.token
    ).then((res) => {
      setIsLoading(false);

      if (res.data.body) {
        const creator = res.data.body.filter((f) => f.form_id == formId)[0];

        get_single_user({ user_id: creator.creator_id }, global.token).then(
          (res) => {
            setCreator(res.data.body);
          }
        );
      }
    });
  }

  async function sendNotifToSoldier(soldier_id, status) {
    const data = {
      title: route.params.form_type + " " + status,
      body: comment,
      receiver_id: soldier_id,
      sender_id: global.user_data.user_id,
      notification_type: "file",
      object_type: route.params.form_type + status,
      form_events_id: route.params.fileID,
    };
    let res = await create_notification(data, global.token);

    if (res.status !== 200) {
      showSnackbar(
        "success",
        "Your Soldier has been notified",
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar(
            "error",
            "Application Error: Failed to send notification"
          );
        }
      );
    }
  }

  function resetState() {
    setIsLoading(false);
    setHasSigned(false);
    setSignatureName("");
    setHasSignature(false);
    setComment("");
    setApprove(false);
    setSoldierName("");
    setDateSubmitted("");
    setUnitName("");
    setSOUData({});
    setCreator({});
  }
}

ReviewSOU.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default ReviewSOU;

function getTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}
