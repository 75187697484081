import React, { useState, createContext } from 'react';



export const CourseContext = createContext();

export const CourseProvider = ({children}) => {
  const [courses, setCourses] = useState([
    { REQ: 'AR 190-13', courseNumber: 'PY011.16', COURSE_TITLE: 'INTRODUCTION TO PHYSICAL SECURITY', PH: '', ATRRS_OR_SOURCE: 'CDSE', COURSE_HOURS: '1', EFFECTIVE_DATE: '20190627', courseId: 1, multiDelete: false },
    { REQ: 'AR 190-13', courseNumber: 'PY104.16', COURSE_TITLE: 'LOCK AND KEY SYSTEMS', PH: '', ATRRS_OR_SOURCE: 'CDSE', COURSE_HOURS: '1.5', EFFECTIVE_DATE: '20190627', courseId: 2, multiDelete: false },
    { REQ: 'AR 190-13', courseNumber: 'PY103.16', COURSE_TITLE: 'PHYSICAL SECURITY MEASURES', PH: '', ATRRS_OR_SOURCE: 'CDSE', COURSE_HOURS: '2', EFFECTIVE_DATE: '20190627', courseId: 3, multiDelete: false },
    { REQ: 'AR 190-13', courseNumber: 'PY106.16', COURSE_TITLE: 'PHYSICAL SECURITY PLANNING AND IMPLEMENTATION', PH: '2', ATRRS_OR_SOURCE: 'CDSE', COURSE_HOURS: '2', EFFECTIVE_DATE: '20190627', courseId: 4, multiDelete: false }, 
    { REQ: 'AR 350-1', courseNumber: 'JS-US009', COURSE_TITLE: 'JOINT STAFF OPERATIONS SECURITY (OPSEC) - 1H', PH: '1', ATRRS_OR_SOURCE: 'ATTRS', COURSE_HOURS: '1', EFFECTIVE_DATE: '20121119', courseId: 5, multiDelete: false }, 
    { REQ: 'AR 350-1', courseNumber: 'DHA-US001', COURSE_TITLE: 'HIPAA AND PRIVACY ACT TRAINING (1.5HRS)', PH: '1.5', ATRRS_OR_SOURCE: 'JKO', COURSE_HOURS: '1.5', EFFECTIVE_DATE: '20150430', courseId: 6, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'J3OP-US018', COURSE_TITLE: 'FUNDAMENTALS OF PERSONNEL RECOVERY (PR 102) COURSE', PH: '3', ATRRS_OR_SOURCE: 'ATTRS/JKO', COURSE_HOURS: '3', EFFECTIVE_DATE: '20150430', courseId: 7, multiDelete: false }, 
    { REQ: 'AR 350-1', courseNumber: 'JS-US007', COURSE_TITLE: 'LEVEL 1 ANTITERRORISM AWARENESS TRAINING', PH: '2', ATRRS_OR_SOURCE: 'JKO', COURSE_HOURS: '2', EFFECTIVE_DATE: '20141030', courseId: 8, multiDelete: false }, 
    { REQ: 'AR 350-1', courseNumber: 'AR', COURSE_TITLE: 'U.S. ARMY THREAT AWARENESS AND REPORTING PROGRAM (TARP) COURSE', PH: '1', ATRRS_OR_SOURCE: 'ALMS', COURSE_HOURS: '1', EFFECTIVE_DATE: '20141030', courseId: 9, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'AR', COURSE_TITLE: 'SECURITY TRAINING - ANNUAL AWARENESS; MANAGING PERSONNEL WITH', PH: '2', ATRRS_OR_SOURCE: 'ALMS', COURSE_HOURS: '2', EFFECTIVE_DATE: '20141030', courseId: 10, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'AR', COURSE_TITLE: 'RESILIENCE TRAINING GAT 2.0', PH: '2', ATRRS_OR_SOURCE: 'ARMYFIT', COURSE_HOURS: '2', EFFECTIVE_DATE: '20141030', courseId: 11, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'AR', COURSE_TITLE: 'INFORMATION ASSURANCE/DOD CYBER AWARENESS', PH: '2', ATRRS_OR_SOURCE: 'IA', COURSE_HOURS: '2', EFFECTIVE_DATE: '20141030', courseId: 12, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'AR', COURSE_TITLE: 'COMBATING TRAFFICKING IN PERSONS (CTIP) GENERAL AWARENESS COURSE', PH: '1', ATRRS_OR_SOURCE: 'JKO', COURSE_HOURS: '1', EFFECTIVE_DATE: '20141030', courseId: 13, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'JS-US014', COURSE_TITLE: 'JOINT STAFF LAW OF WAR', PH: '2.5', ATRRS_OR_SOURCE: 'JKO', COURSE_HOURS: '2.5', EFFECTIVE_DATE: '20141030', courseId: 14, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'PR101', COURSE_TITLE: 'SHARP SEXUAL HARASSMENT/ASSAULT RESPONSE PREVENTION TRAINING COURSE', PH: '3', ATRRS_OR_SOURCE: 'ALMS', COURSE_HOURS: '3', EFFECTIVE_DATE: '20141030', courseId: 15, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'PR101', COURSE_TITLE: 'CODE OF CONDUCT (ARMY PERSONNEL RECOVERY) (ARPR) 101', PH: '3', ATRRS_OR_SOURCE: 'ALMS', COURSE_HOURS: '3', EFFECTIVE_DATE: '20141030', courseId: 16, multiDelete: false },
    { REQ: 'AR 350-1', courseNumber: 'AR', COURSE_TITLE: 'FRATERNIZATION', PH: '1', ATRRS_OR_SOURCE: 'JKO', COURSE_HOURS: '1', EFFECTIVE_DATE: '20141030', courseId: 17, multiDelete: false },
    { REQ: 'AR 600-55', courseNumber: '920-F92 (DL)', COURSE_TITLE: 'LICENSE INSTRUCTOR/EXAMINER', PH: '8', ATRRS_OR_SOURCE: 'ATTRS', COURSE_HOURS: '8', EFFECTIVE_DATE: '20210117', courseId: 18, multiDelete: false },
    { REQ: 'ASA', courseNumber: 'AR', COURSE_TITLE: 'THE ARMY MICP – INTERNAL CONTROL ADMINISTRATOR (ICA) COURSE', PH: '40', ATRRS_OR_SOURCE: 'ALMS', COURSE_HOURS: '40', EFFECTIVE_DATE: '20141030', courseId: 19, multiDelete: false }

  ]);

  return (
    <CourseContext.Provider value={{courses, setCourses}}>
      {children}
    </CourseContext.Provider>

  );
};